import React, { useEffect, useState } from 'react';
import { Card, CardTitle, Table, Row, Col, Nav, NavItem, NavLink, ButtonGroup, Button } from 'reactstrap';
import { Bar, Line } from 'react-chartjs-2';
import moment from 'moment';
import { CSVLink, CSVDownload } from 'react-csv';
import { fetchWithGet } from '../../../httpHelper';
import { Loader } from '../../../components';
import Datetime from 'react-datetime';
import _ from 'lodash';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(zoomPlugin, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

export default function StatisticWidget() {
  const [isLoading, setIsLoading] = useState(false);
  const [statistic, setStatistic] = useState({});
  const [dateRange, setDateRange] = useState(['2022-10-24']);
  const [dataByDate, setDataByDate] = useState({});
  const [isRegistrationEndDateLoading, setEndDateLoading] = useState(false);
  const [registrationStartDate, setRegistrationStartDate] = useState();
  const [registrationEndDate, setRegistrationEndDate] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [isDaily, setIsDaily] = useState(true);
  const [dateRangeByWeek, setDateRangeByWeek] = useState({});
  const [statusActiveTab, setStatusActiveTab] = useState(0);

  const fetchDataByDate = async (startDate, endDate) => {
    setEndDateLoading(true);
    const result = await fetchWithGet('/report/getRegStatistic', {
      registrationStartDate: startDate?.format('YYYYMMDD') || '20221024',
      registrationEndDate: endDate?.format('YYYYMMDD') || moment().format('YYYYMMDD'),
    });
    setRegistrationStartDate(startDate || moment('20221024', 'YYYYMMDD'));
    setRegistrationEndDate(endDate || moment());
    setDataByDate(result);
    setEndDateLoading(false);
  };

  const fetchOverview = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/report/getOverview');
    setStatistic(result);
    setIsLoading(false);
    const dayDiff = moment().diff(dateRange[0], 'days');

    const dates = [
      dateRange[0],
      ...Array.from(Array(dayDiff).keys()).map((v) =>
        moment('2022-10-24', 'YYYY-MM-DD')
          .add(v + 1, 'day')
          .format('YYYY-MM-DD')
      ),
    ];
    setDateRange(dates);

    setDateRangeByWeek({
      ..._.groupBy(
        dates
          .map((v, i) => {
            return {
              date: v,
              count: result.totalRegistrationByDate.find((s) => s._id === v)?.count || 0,
            };
          })
          .filter((v) => v.date.includes('2022')),
        (dt) => `2022 Week ${moment(dt.date).week()}`
      ),
      ..._.groupBy(
        dates
          .map((v, i) => {
            return {
              date: v,
              count: result.totalRegistrationByDate.find((s) => s._id === v)?.count || 0,
            };
          })
          .filter((v) => v.date.includes('2023')),
        (dt) => `2023 Week ${moment(dt.date).week()}`
      ),
    });
  };

  useEffect(() => {
    fetchDataByDate();
    fetchOverview();
  }, []);

  const educationLevels = [
    'Master’s Degree or above',
    'Bachelor’s Degree',
    'Associate Degree',
    'Higher Diploma / Advanced Diploma',
    'HKDSE',
    'The International Baccalaureate',
    'Diploma of Foundation Studies',
    'Diploma Yi Jin',
    'Others',
  ];

  const stageList = ['Application', 'VASK', 'Video Interview', 'Group Interview', 'Final Interview', 'Offer'];
  const stageStatusList = ['Not yet started', 'In progress', 'Finished'];

  const channel = ['Google', 'Bus', 'Facebook', 'School'];

  const chartColors = {
    red: 'rgb(233, 30, 99)',
    danger: 'rgb(233, 30, 99)',
    dangerTransparent: 'rgba(233, 30, 99, .8)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 180, 0)',
    green: 'rgb(34, 182, 110)',
    blue: 'rgb(68, 159, 238)',
    primary: 'rgb(68, 159, 238)',
    primaryTransparent: 'rgba(68, 159, 238, .8)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(201, 203, 207)',

    primaryShade1: 'rgb(68, 159, 238)',
    primaryShade2: 'rgb(23, 139, 234)',
    primaryShade3: 'rgb(14, 117, 202)',
    primaryShade4: 'rgb(9, 85, 148)',
    primaryShade5: 'rgb(12, 70, 117)',
  };

  const totalOfCohort =
    statistic?.statistics && Object.values(statistic?.statistics[statusActiveTab]?.stage).reduce((c, p) => c + p);

  return (
    <div>
      <Card body>
        {isRegistrationEndDateLoading || !dataByDate ? (
          <Loader small={true} />
        ) : (
          <>
            <Row>
              <Col lg={3} md={3} sm={6} xs={6} className="mt-3">
                <CardTitle className="text-uppercase text-muted h6 mb-0">Total Registration</CardTitle>
                <div className="h2 font-weight-bold mb-0">{dataByDate.totalRegistration}</div>
              </Col>
              <Col lg={3} md={3} sm={6} xs={6} className="mt-3">
                <CardTitle className="text-uppercase text-muted h6 mb-0">Total Application</CardTitle>
                <div className="h2 font-weight-bold mb-0">{dataByDate.allApplications?.length}</div>
              </Col>
              <Col lg={3} md={3} sm={6} xs={6} className="mt-3">
                <CardTitle className="text-uppercase text-muted h6 mb-0">Start Date</CardTitle>
                <Datetime
                  value={registrationStartDate}
                  timeFormat={false}
                  onChange={(date) => {
                    fetchDataByDate(date, registrationEndDate);
                  }}
                />
              </Col>
              <Col lg={3} md={3} sm={6} xs={6} className="mt-3">
                <CardTitle className="text-uppercase text-muted h6 mb-0">End Date</CardTitle>

                <Datetime
                  value={registrationEndDate}
                  timeFormat={false}
                  onChange={(date) => {
                    fetchDataByDate(registrationStartDate, date);
                  }}
                />
              </Col>
            </Row>
            <Row>
              {dataByDate.applicationsByCohort?.map((cohort) => {
                return (
                  <Col lg={3} md={6} sm={6} xs={6} className="mt-3">
                    <CardTitle className="text-uppercase text-muted h6 mb-0">{cohort.cohortDetail.en}</CardTitle>
                    <div>{cohort.applications}</div>
                  </Col>
                );
              }) || <></>}
            </Row>
            <Row>
              {channel.map((c) => {
                return (
                  <Col xs={2} className="mt-3">
                    <CardTitle className="text-uppercase text-muted h6 mb-0">{c}</CardTitle>
                    <div>{_.find(dataByDate.channel, (v) => v._id === c)?.count || 0}</div>
                  </Col>
                );
              }) || <></>}
              <Col xs={2} className="mt-3">
                <CardTitle className="text-uppercase text-muted h6 mb-0">Others</CardTitle>
                <div>
                  {_.sumBy(
                    dataByDate.channel?.filter((v) => !channel.includes(v._id)),
                    'count'
                  )}
                </div>
              </Col>
              <Col xs={4} className="mt-3">
                <CSVLink
                  filename={`channel.csv`}
                  headers={[
                    {
                      label: 'Channel',
                      key: 'channel',
                    },
                    {
                      label: 'Count',
                      key: 'count',
                    },
                  ]}
                  data={
                    dataByDate?.channel?.map((v) => {
                      return { channel: v._id, count: v.count };
                    }) || []
                  }
                >
                  {'Download Channel Details'}
                </CSVLink>
              </Col>
            </Row>
          </>
        )}
      </Card>
      <Row>
        <Col className="mt-3">
          <Card body>
            {isLoading || !statistic?.statistics ? (
              <Loader small={true} />
            ) : (
              <>
                <div style={{ textAlign: 'right' }}>
                  <ButtonGroup block={true}>
                    <Button
                      color="primary"
                      outline={!isDaily}
                      onClick={async () => {
                        setIsDaily(true);
                      }}
                    >
                      By Daily
                    </Button>
                    <Button
                      color="primary"
                      outline={isDaily}
                      onClick={() => {
                        setIsDaily(false);
                      }}
                    >
                      By Weekly
                    </Button>
                  </ButtonGroup>
                </div>
                <Line
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: true,
                        text: 'Daily Registration',
                      },
                      // TODO: enable when it needs
                      // zoom: {
                      //   pan: {
                      //     enabled: true,
                      //     mode: 'x',
                      //   },
                      //   zoom: {
                      //     pinch: {
                      //       enabled: true, // Enable pinch zooming
                      //     },
                      //     wheel: {
                      //       enabled: true, // Enable wheel zooming
                      //     },
                      //     mode: 'x',
                      //   },
                      // },
                    },
                  }}
                  data={{
                    labels: isDaily ? dateRange : Object.keys(dateRangeByWeek),
                    datasets: [
                      {
                        label: 'Count',
                        data: isDaily
                          ? dateRange.map((v) => statistic.totalRegistrationByDate.find((s) => s._id === v)?.count || 0)
                          : Object.values(dateRangeByWeek).map((v) => v.reduce((p, c) => p + c.count, 0)),
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0)',
                      },
                    ],
                  }}
                />
              </>
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12}>
          {statistic?.statistics && (
            <Nav tabs>
              {statistic?.statistics.map((v, i) => (
                <NavItem key={i}>
                  <NavLink
                    href="#"
                    className={`${statusActiveTab === i ? 'active' : ''}`}
                    onClick={() => setStatusActiveTab(i)}
                  >
                    {v.cohortDetail.en}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          )}
          <Card body>
            {isLoading || !statistic?.statistics ? (
              <Loader small={true} />
            ) : (
              <>
                <Line
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: true,
                        text: 'Candidate Funnel',
                      },
                    },
                  }}
                  data={{
                    labels: stageList,
                    datasets: [
                      {
                        label: 'Count',
                        data: [
                          totalOfCohort,
                          ...Array.from({ length: stageList.length }).map((v, i) => {
                            return (
                              totalOfCohort -
                              _.take(stageList, i + 1)
                                .map((a) => statistic?.statistics[statusActiveTab]?.stage[a])
                                .reduce((c, p) => c + p)
                            );
                          }),
                        ],
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0)',
                      },
                    ],
                  }}
                />
              </>
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12}>
          {statistic?.statistics && (
            <Nav tabs>
              {statistic?.statistics.map((v, i) => (
                <NavItem key={i}>
                  <NavLink
                    href="#"
                    className={`${statusActiveTab === i ? 'active' : ''}`}
                    onClick={() => setStatusActiveTab(i)}
                  >
                    {v.cohortDetail.en}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          )}
          <Card body>
            {isLoading || !statistic?.statistics ? (
              <Loader small={true} />
            ) : (
              <>
                <Row>
                  <Col>
                    <div style={{ lineHeight: 0.5 }}>
                      <span style={{ fontSize: 10 }}>
                        *Click the Cohort boxes under the title to hide corresponding data. Click again to resume.
                      </span>
                    </div>
                  </Col>
                  <Col style={{ textAlign: 'right' }}>
                    <CSVLink
                      filename={`Applicant Status - ${statistic?.statistics[statusActiveTab]?.cohortDetail.en}.csv`}
                      headers={[
                        {
                          label: 'Status',
                          key: 'status',
                        },
                        ...stageStatusList.map((v) => {
                          return {
                            label: v,
                            key: v,
                          };
                        }),
                      ]}
                      data={stageList.map((v) => {
                        const obj = {
                          status: v,
                        };

                        stageStatusList.map((s) => {
                          obj[s] =
                            (statistic?.statistics[statusActiveTab]?.stageStatus[v] &&
                              statistic?.statistics[statusActiveTab]?.stageStatus[v][s]) ||
                            0;
                        });

                        return obj;
                      })}
                    >
                      {'Download'}
                    </CSVLink>
                  </Col>
                </Row>
                <Bar
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: true,
                        text: `Applicant Status - ${statistic?.statistics[statusActiveTab]?.cohortDetail.en}`,
                      },
                    },
                  }}
                  data={{
                    labels: stageList,
                    datasets: stageStatusList.map((s) => {
                      return {
                        label: s,
                        data: stageList.map((v) => {
                          const stage = statistic?.statistics[statusActiveTab]?.stageStatus[v];
                          return (stage && stage[s]) || 0;
                        }),
                        backgroundColor: [
                          chartColors.red,
                          chartColors.orange,
                          chartColors.green,
                          chartColors.blue,
                          chartColors.purple,
                          chartColors.grey,
                        ][stageStatusList.indexOf(s)],
                      };
                    }),
                  }}
                />
              </>
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12}>
          <Card body>
            {isLoading || !statistic?.statistics ? (
              <Loader small={true} />
            ) : (
              <>
                <Row>
                  <Col>
                    <div style={{ lineHeight: 0.5 }}>
                      <span style={{ fontSize: 10 }}>
                        *Click the Cohort boxes under the title to hide corresponding data. Click again to resume.
                      </span>
                    </div>
                  </Col>
                  <Col style={{ textAlign: 'right' }}>
                    <CSVLink
                      filename={'Education Background of Submitted Application.csv'}
                      headers={[
                        {
                          label: 'Cohort',
                          key: 'cohort',
                        },
                        ...educationLevels.map((v) => {
                          return {
                            label: v,
                            key: v,
                          };
                        }),
                        {
                          label: 'Total',
                          key: 'total',
                        },
                      ]}
                      data={statistic.statistics.map((v) => {
                        return {
                          cohort: v.cohortDetail.en,
                          ...v.education,
                          total: Object.values(v.education).reduce((p, c) => p + c, 0),
                        };
                      })}
                    >
                      {'Download'}
                    </CSVLink>
                  </Col>
                </Row>
                <Bar
                  options={{
                    indexAxis: 'y',
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: true,
                        text: 'Education Background of Submitted Application',
                      },
                    },
                  }}
                  data={{
                    labels: educationLevels,
                    datasets: statistic.statistics.map((s) => {
                      return {
                        label: s.cohortDetail.en,
                        data: educationLevels.map((level) => {
                          return s.education[level] || 0;
                        }),
                        backgroundColor: [
                          chartColors.red,
                          chartColors.orange,
                          chartColors.green,
                          chartColors.blue,
                          chartColors.purple,
                          chartColors.grey,
                        ][statistic.statistics.indexOf(s)],
                      };
                    }),
                  }}
                />
              </>
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12}>
          <Card body>
            {isLoading || !statistic?.statistics ? (
              <Loader small={true} />
            ) : (
              <>
                <Row>
                  <Col>
                    <div style={{ lineHeight: 0.5 }}>
                      <span style={{ fontSize: 10 }}>
                        *Click the Cohort boxes under the title to hide corresponding data. Click again to resume.
                      </span>
                    </div>
                  </Col>
                  <Col style={{ textAlign: 'right' }}>
                    <CSVLink
                      filename={'Sector.csv'}
                      headers={[
                        {
                          label: 'Cohort',
                          key: 'cohort',
                        },
                        ...statistic.streamDetail.map((v) => {
                          return {
                            label: v.en,
                            key: v.en,
                          };
                        }),
                      ]}
                      data={statistic.statistics.map((v) => {
                        return {
                          cohort: v.cohortDetail.en,
                          ...v.stream,
                        };
                      })}
                    >
                      {'Download'}
                    </CSVLink>
                  </Col>
                </Row>
                <Bar
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: true,
                        text: 'Sector',
                      },
                    },
                  }}
                  data={{
                    labels: statistic.streamDetail.map((v) => v.en),
                    datasets: statistic.statistics.map((s) => {
                      return {
                        label: s.cohortDetail.en,
                        data: statistic.streamDetail.map((cStatus) => {
                          return s.stream[cStatus.en] || 0;
                        }),
                        backgroundColor: [
                          chartColors.red,
                          chartColors.orange,
                          chartColors.green,
                          chartColors.blue,
                          chartColors.purple,
                          chartColors.grey,
                        ][statistic.statistics.indexOf(s)],
                      };
                    }),
                  }}
                />
              </>
            )}
          </Card>
        </Col>
        <Col sm={12} md={12}>
          <Card body>
            {isLoading || !statistic?.statistics ? (
              <Loader small={true} />
            ) : (
              <>
                <Row>
                  <Col>
                    <div style={{ lineHeight: 0.5 }}>
                      <span style={{ fontSize: 10 }}>
                        *Click the Cohort boxes under the title to hide corresponding data. Click again to resume.
                      </span>
                    </div>
                  </Col>
                  <Col style={{ textAlign: 'right' }}>
                    <CSVLink
                      filename={'Job Roles.csv'}
                      headers={[
                        {
                          label: 'Cohort',
                          key: 'cohort',
                        },
                        ...statistic.roleDetail.map((v) => {
                          return {
                            label: v.en,
                            key: v.en,
                          };
                        }),
                      ]}
                      data={statistic.statistics.map((v) => {
                        return {
                          cohort: v.cohortDetail.en,
                          ...v.role,
                        };
                      })}
                    >
                      {'Download'}
                    </CSVLink>
                  </Col>
                </Row>
                <Bar
                  options={{
                    indexAxis: 'y',
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: true,
                        text: 'Selected job roles by candidates',
                      },
                    },
                  }}
                  data={{
                    labels: statistic.roleDetail.map((v) => v.en),
                    datasets: statistic.statistics.map((s) => {
                      return {
                        label: s.cohortDetail.en,
                        data: statistic.roleDetail.map((cStatus) => {
                          return s.role[cStatus.en] || 0;
                        }),
                        backgroundColor: [
                          chartColors.red,
                          chartColors.orange,
                          chartColors.green,
                          chartColors.blue,
                          chartColors.purple,
                          chartColors.grey,
                        ][statistic.statistics.indexOf(s)],
                      };
                    }),
                  }}
                />
              </>
            )}
          </Card>
        </Col>
      </Row>
      {/* <Row>
        <Col className="mt-3">
          {statistic?.statistics && (
            <Nav tabs>
              {statistic?.statistics.map((v, i) => (
                <NavItem key={i}>
                  <NavLink href="#" className={`${activeTab === i ? 'active' : ''}`} onClick={() => setActiveTab(i)}>
                    {v.cohortDetail.en}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          )}
          <Card body>
            {isLoading || !statistic?.statistics ? (
              <Loader small={true} />
            ) : (
              <>
                <Row>
                  <Col>
                    <CardTitle>
                      By Job roles in each track ({statistic?.statistics[activeTab].cohortDetail.en})
                    </CardTitle>
                  </Col>
                  <Col md={2} style={{ textAlign: 'right' }}>
                    <CSVLink
                      filename={
                        'By Job roles in each track - ' + statistic?.statistics[activeTab].cohortDetail.en + '.csv'
                      }
                      headers={[
                        {
                          label: '',
                          key: 'role',
                        },
                        {
                          label: '1st',
                          key: 'st',
                        },
                        {
                          label: '2nd',
                          key: 'nd',
                        },
                        {
                          label: 'Total',
                          key: 'total',
                        },
                      ]}
                      data={statistic.roleDetail.map((v) => {
                        return {
                          role: v.en,
                          st: statistic?.statistics[activeTab].role1[v.en] || 0,
                          nd: statistic?.statistics[activeTab].role2[v.en] || 0,
                          total:
                            (statistic?.statistics[activeTab].role1[v.en] || 0) +
                            (statistic?.statistics[activeTab].role2[v.en] || 0),
                        };
                      })}
                    >
                      {'Download'}
                    </CSVLink>
                  </Col>
                </Row>
                <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>1st</th>
                      <th>2nd</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {statistic.roleDetail.map((v) => {
                      return (
                        <tr>
                          <td>{v.en}</td>
                          <td>{statistic?.statistics[activeTab].role1[v.en] || 0}</td>
                          <td>{statistic?.statistics[activeTab].role2[v.en] || 0}</td>
                          <td>
                            {(statistic?.statistics[activeTab].role1[v.en] || 0) +
                              (statistic?.statistics[activeTab].role2[v.en] || 0)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            )}
          </Card>
        </Col>
      </Row>
     */}
    </div>
  );
}
