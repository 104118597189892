import React, { useState, useEffect } from 'react';
import { Input, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { fetchWithGet, fetchWithPost, fetchWithFormData } from '../../httpHelper';
import _ from 'lodash';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';

import { Loader } from '../../components';

import { CSVLink } from 'react-csv';

export default function Rsvp(props) {
  let { path, url } = useRouteMatch();

  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <RsvpList rootPath={url} />
        </Route>
      </Switch>
    </Router>
  );
}

function RsvpList({ rootPath }) {
  const [rsvp, setRsvp] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const educationMap = {
    master: 'Master’s Degree or above',
    bachelor: 'Bachelor’s Degree',
    associate: 'Associate Degree',
    higherDiploma: 'Higher Diploma / Advanced Diploma',
    hkdse: 'HKDSE',
    baccalaureate: 'The International Baccalaureate',
    foundation: 'Diploma of Foundation Studies',
    yiJin: 'Diploma Yi Jin',
  };

  const columns = ['#', 'Event', 'Name', 'Email', 'Phone No', 'Role', 'Education', 'Organization', 'Title', 'Topics'];
  const options = {
    selectableRows: 'none',
    download: false,
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };

  const exportHeader = [
    { label: 'Event', key: 'event' },
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    { label: 'PhoneNo', key: 'phoneNo' },
    { label: 'Role', key: 'role' },
    { label: 'Education', key: 'education' },
    { label: 'Organization', key: 'organization' },
    { label: 'Title', key: 'title' },
    { label: 'Topics', key: 'topicsOfInterest' },
    { label: 'Create DateTime', key: 'createdAt' },
  ];

  const exportData = rsvp.map((v, i) => {
    return {
      event: v.eventDetails.title.en,
      firstName: v.firstName,
      lastName: v.lastName,
      email: v.email,
      phoneNo: v.phoneNo,
      role: _.startCase(v.role),
      education: educationMap[v.education],
      organization: v.organization,
      title: v.title,
      topicsOfInterest: v.topicsOfInterest
        .map((v) => _.startCase(v))
        .toString()
        .replaceAll('Others', v.otherTopic),
      createdAt: moment(v.createdAt).format('YYYY-MM-DD hh:mm'),
    };
  });

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/dashboard/getRsvp');
    if (result) {
      setRsvp(result);
      setData(
        result.map((v, i) => [
          i + 1,
          v.eventDetails.title.en,
          v.firstName + ' ' + v.lastName,
          v.email,
          v.phoneNo,
          _.startCase(v.role),
          educationMap[v.education],
          v.organization,
          v.title,
          v.topicsOfInterest
            .map((v) => _.startCase(v))
            .toString()
            .replaceAll('Others', v.otherTopic),
        ])
      );
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>RSVP</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="text-right mb-2">
        <CSVLink filename={`RSVP-Export`} data={exportData} headers={exportHeader}>
          Export
        </CSVLink>
      </div>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}
