import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  Button,
  Input,
  FormGroup,
  Label,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
} from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { fetchWithGet, fetchWithPost, fetchWithFormData } from '../../httpHelper';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Select from 'react-select';
import MUIDataTable from 'mui-datatables';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import { Loader } from '../../components';
import update from 'immutability-helper';

function MyEditor({ onChange, value, placeholder }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [initValue, setInitValue] = useState('');
  useEffect(() => {
    if (!initValue && value) {
      setInitValue(value);
      const contentBlock = htmlToDraft(value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const contentEditorState = EditorState.createWithContent(contentState);
        setEditorState(contentEditorState);
      }
    }
  }, [value]);

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };
  const uploadCallback = async (file) => {
    const result = await fetchWithFormData('/content/uploadImage', { location: 'position', image: file });
    return result;
  };

  return (
    <Editor
      toolbar={{ image: { previewImage: true, uploadCallback } }}
      editorState={editorState}
      wrapperClassName="rich-editor demo-wrapper"
      editorClassName="demo-editor"
      onEditorStateChange={handleEditorChange}
      placeholder={placeholder}
    />
  );
}

export default function Position(props) {
  let { path, url } = useRouteMatch();

  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <PositionList rootPath={url} />
        </Route>
        <Route path={`${path}/create`}>
          <PositionEdit rootPath={url} />
        </Route>
        <Route path={`${path}/:id`}>
          <PositionEdit rootPath={url} />
        </Route>
      </Switch>
    </Router>
  );
}

function PositionList({ rootPath }) {
  const history = useHistory();
  const [positions, setPositions] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = ['#', 'Stream', 'Role', 'Cohort'];
  const options = {
    selectableRows: 'none',
    download: false,
    onRowClick: (rowData, rowMeta) => {
      history.push(`${rootPath}/${positions[rowMeta.dataIndex].id}`);
    },
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };
  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/content/position');
    if (result) {
      setPositions(result);
      setData(result.map((v, i) => [i + 1, v.stream.en, v.role.en, v.cohort.map((v) => v.en).join(',')]));
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Positions</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="text-right mb-2">
        <Button color="primary" onClick={() => history.push(`${rootPath}/create`)}>
          Add
        </Button>
      </div>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}

function PositionEdit({ rootPath }) {
  let { id } = useParams();
  let { url } = useRouteMatch();
  const history = useHistory();
  const alertContext = useContext(PageAlertContext);

  const [positionId, setPositionId] = useState(id);
  const [role, setRole] = useState('');
  const [cohort, setCohort] = useState([]);
  const [thumbnail, setThumbnail] = useState('');
  const [jobDetailZh, setJobDetailZh] = useState('');
  const [jobDetailEn, setJobDetailEn] = useState('');
  const [jobDescriptionZh, setJobDescriptionZh] = useState('');
  const [jobDescriptionEn, setJobDescriptionEn] = useState('');
  const [isDseFriendly, setIsDseFriendly] = useState(false);
  const [jobDetailSession, setJobDetailSession] = useState([]);

  const [roles, setRoles] = useState([]);
  const [cohorts, setCohorts] = useState([]);
  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    const streamList = await fetchWithGet('/content/streams');
    if (streamList) {
      const map = [];
      if (streamList.length > 0) {
        for (let i = 0; i < streamList.length; i++) {
          if (streamList[i].roles.length > 0) {
            for (let y = 0; y < streamList[i].roles.length; y++) {
              map.push({
                id: `${streamList[i].id}-${streamList[i].roles[y].id}`,
                stream: streamList[i].id,
                role: streamList[i].roles[y].id,
                name: `${streamList[i].en} - ${streamList[i].roles[y].en}`,
              });
            }
          }
        }
      }
      setRoles(map);
    }

    const cohortList = await fetchWithGet('/content/cohort');
    if (cohortList) {
      setCohorts(
        cohortList.map((v) => {
          return { value: v.id, label: v.en };
        })
      );
    }

    if (id) {
      setPositionId(id);
      const result = await fetchWithGet(`/content/position/${id}`);
      if (result) {
        setRole(`${result.stream.id}-${result.role.id}`);
        setCohort(
          result.cohort.map((v) => {
            return { value: v.id, label: v.en };
          })
        );
        setJobDetailZh(result.jobDetail.zh);
        setJobDetailEn(result.jobDetail.en);
        setJobDescriptionZh(result.jobDescription.zh);
        setJobDescriptionEn(result.jobDescription.en);
        setThumbnail(result.thumbnail);
        setIsDseFriendly(result.isDseFriendly);
        setJobDetailSession(result.jobDetailSession);
      }
    }
  };
  const savePosition = async () => {
    if (role && cohort.length > 0) {
      const result = await fetchWithPost('/content/position', {
        id: positionId,
        stream: roles.find((v) => v.id === role).stream,
        role: roles.find((v) => v.id === role).role,
        cohort: cohort.map((v) => v.value),
        jobDetail: {
          zh: jobDetailZh,
          en: jobDetailEn,
        },
        jobDescription: {
          zh: jobDescriptionZh,
          en: jobDescriptionEn,
        },
        thumbnail,
        isDseFriendly,
        jobDetailSession,
      });

      if (result && result.status === 400) {
        alertContext.setAlert(result.data.message, 'danger');
      } else {
        alertContext.setAlert('Successfully updated', 'success');
        history.push(`${rootPath}`);
      }
    } else {
      alertContext.setAlert('Please fill in all the blank', 'danger');
    }
  };

  const uploadThumbnail = async (v) => {
    if (v.target.files.length > 0) {
      const result = await fetchWithFormData('/content/uploadImage', { location: 'blog', image: v.target.files[0] });
      if (result) {
        setThumbnail(result.data.link);
      }
    }
  };

  const addNewPositionSession = () => {
    setJobDetailSession([...jobDetailSession, { key: '', title: { zh: '', en: '' }, content: { zh: '', en: '' } }]);
  };

  return (
    <div>
      <div className="mb-2">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={`${rootPath}`}>Positions</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`${url}/${id}`}>{id ? 'Edit position' : 'Create position'}</Link>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>

      <Card>
        <CardBody>
          <div className="mb-2">
            <FormGroup>
              <Label>Role</Label>
              <Input type="select" name="select" value={role} onChange={(v) => setRole(v.target.value)}>
                <option value={''}>{'Please select role'}</option>
                {roles.map((v) => (
                  <option key={v.id} value={v.id}>
                    {v.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Cohort</Label>
              <Select options={cohorts} value={cohort} isMulti onChange={(v) => setCohort(v)} />
            </FormGroup>
            <FormGroup>
              <Label>Is DSE Friendly</Label>
              <Input
                type="select"
                name="select"
                value={isDseFriendly}
                onChange={(v) => {
                  setIsDseFriendly(v.target.value);
                }}
              >
                <option value={true}>{'True'}</option>
                <option value={false}>{'False'}</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Thumbnail</Label>
              <Input type="file" placeholder="Click here to upload" onChange={uploadThumbnail} />
              <Row>
                <Col sm={12} md={6}>
                  {!!thumbnail ? <img src={thumbnail} alt={'thumbnail'} /> : <div>No image uploaded</div>}
                </Col>
              </Row>
            </FormGroup>
            <Row>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label>Job Description (Chinese)</Label>
                  <MyEditor onChange={(v) => setJobDescriptionZh(v)} placeholder="中文" value={jobDescriptionZh} />
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label>Job Description (English)</Label>
                  <MyEditor onChange={(v) => setJobDescriptionEn(v)} placeholder="English" value={jobDescriptionEn} />
                </FormGroup>
              </Col>
            </Row>
            <hr />
            <Label> Job Description Session</Label>
            <Button color="primary" block onClick={addNewPositionSession}>
              Add
            </Button>
            <br />
            {jobDetailSession.map((session, i) => {
              return (
                <>
                  <div style={{ textAlign: 'right' }}>
                    <Button
                      color="danger"
                      style={{ textAlign: 'right' }}
                      onClick={() =>
                        setJobDetailSession(
                          update(jobDetailSession, {
                            $splice: [[i, 1]],
                          })
                        )
                      }
                    >
                      Remove
                    </Button>
                  </div>
                  <br />
                  <FormGroup>
                    <Label className="mb-2">Session Key</Label>
                    <Input
                      value={session.key}
                      onChange={(e) =>
                        setJobDetailSession(
                          update(jobDetailSession, {
                            [i]: {
                              key: { $set: e.target.value },
                            },
                          })
                        )
                      }
                    />
                  </FormGroup>
                  <Row>
                    <Col sm={12} md={6}>
                      <FormGroup>
                        <Label className="mb-2">Title (Chinese)</Label>
                        <Input
                          value={session.title.zh}
                          onChange={(e) =>
                            setJobDetailSession(
                              update(jobDetailSession, {
                                [i]: {
                                  title: {
                                    zh: { $set: e.target.value },
                                  },
                                },
                              })
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={12} md={6}>
                      <FormGroup>
                        <Label className="mb-2">Title (English)</Label>
                        <Input
                          value={session.title.en}
                          onChange={(e) =>
                            setJobDetailSession(
                              update(jobDetailSession, {
                                [i]: {
                                  title: {
                                    en: { $set: e.target.value },
                                  },
                                },
                              })
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <FormGroup>
                        <Label>Content (Chinese)</Label>
                        <MyEditor
                          onChange={(v) =>
                            setJobDetailSession(
                              update(jobDetailSession, {
                                [i]: {
                                  content: {
                                    zh: { $set: v },
                                  },
                                },
                              })
                            )
                          }
                          placeholder="中文"
                          value={session.content.zh}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={12} md={6}>
                      <FormGroup>
                        <Label>Content (English)</Label>
                        <MyEditor
                          onChange={(v) =>
                            setJobDetailSession(
                              update(jobDetailSession, {
                                [i]: {
                                  content: {
                                    en: { $set: v },
                                  },
                                },
                              })
                            )
                          }
                          placeholder="English"
                          value={session.content.en}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {i === jobDetailSession.length - 1 || <hr />}
                </>
              );
            })}
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <div className="mb-2">
            <h3 className="mb-2">New version will be deprecated</h3>
            <Row>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label>Job Description (Chinese)</Label>
                  <MyEditor onChange={(v) => setJobDetailZh(v)} placeholder="中文" value={jobDetailZh} />
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label>Job Description (English)</Label>
                  <MyEditor onChange={(v) => setJobDetailEn(v)} placeholder="English" value={jobDetailEn} />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>

      <Button style={{ position: 'fixed', left: 0, bottom: 0, zIndex: 1 }} color="primary" block onClick={savePosition}>
        {id ? 'Save' : 'Create'}
      </Button>
    </div>
  );
}
