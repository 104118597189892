import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Button, NavItem } from 'reactstrap';
import { Header, SidebarNav, PageContent, Avatar, PageAlert, Page, PopUp } from '../components';
import Logo from '../assets/custom/logo_white.png';
import nav from '../_nav';
import routes from '../views';
import ContextProviders from '../components/components/utilities/ContextProviders';
import handleKeyAccessibility, { handleClickAccessibility } from '../components/helpers/handleTabAccessibility';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

const MOBILE_SIZE = 992;

export default class DashboardLayout extends Component {
  constructor(props) {
    super(props);
    const menuCollapsed = window.sessionStorage.getItem('menuCollapsed');

    this.state = {
      sidebarCollapsed: menuCollapsed || false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      isLoggedIn: !!Cookies.get('token'),
    };
  }

  handleResize = () => {
    const menuCollapsed = window.sessionStorage.getItem('menuCollapsed');
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: menuCollapsed || false, isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentDidUpdate(prev) {
    if (this.state.isMobile && prev.location.pathname !== this.props.location.pathname) {
      this.toggleSideCollapse();
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', handleKeyAccessibility);
    document.addEventListener('click', handleClickAccessibility);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  toggleSideCollapse = () => {
    window.sessionStorage.setItem('menuCollapsed', !this.state.sidebarCollapsed);
    this.setState((prevState) => ({ sidebarCollapsed: !prevState.sidebarCollapsed }));
  };

  render() {
    const { sidebarCollapsed } = this.state;
    const sidebarCollapsedClass = sidebarCollapsed ? 'side-menu-collapsed' : '';

    if (!this.state.isLoggedIn) {
      return <Redirect from="/" to="/login" />;
    }
    return (
      <ContextProviders>
        <div className={`app ${sidebarCollapsedClass}`}>
          <PageAlert />
          <PopUp />
          <div className="app-body">
            <SidebarNav
              nav={nav.GetNav()}
              logo={Logo}
              // logoText="JC PROcruit C"
              isSidebarCollapsed={sidebarCollapsed}
              toggleSidebar={this.toggleSideCollapse}
              {...this.props}
            />
            <Page>
              <Header
                toggleSidebar={this.toggleSideCollapse}
                isSidebarCollapsed={sidebarCollapsed}
                routes={routes}
                {...this.props}
              >
                <HeaderNav />
              </Header>
              <PageContent>
                <Switch>
                  {routes.map((page, key) => (
                    <Route path={page.path} component={page.component} key={key} />
                  ))}
                  <Redirect from="/" to="/home" />
                </Switch>
              </PageContent>
            </Page>
          </div>
        </div>
      </ContextProviders>
    );
  }
}

function HeaderNav() {
  const history = useHistory();
  const _logout = () => {
    Cookies.remove('token');
    Cookies.remove('role');
    Cookies.remove('userId');
    Cookies.remove('username');
    history.push('/login');
  };
  return (
    <React.Fragment>
      <NavItem className="d-flex align-items-center mr-2">
        <Avatar
          initials={Cookies.get('username')[0].toUpperCase()}
          size="small"
          color="#00B9B5"
          className="mr-2 rounded-circle"
        />
        <div>{Cookies.get('username')}</div>
      </NavItem>
      <NavItem>
        <form className="form-inline" onSubmit={_logout}>
          {/* <input className="form-control mr-sm-1" type="search" placeholder="Search" aria-label="Search" /> */}
          <Button type="submit" className="d-none d-sm-block">
            <i className="fa fa-sign-out" />
            &nbsp;&nbsp; Log Out
          </Button>
        </form>
      </NavItem>
      {/* <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          New
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem>Project</DropdownItem>
          <DropdownItem>User</DropdownItem>
          <DropdownItem divider />
          <DropdownItem>
            Message <Badge color="primary">10</Badge>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav>
          <Avatar size="small" color="blue" initials="JS" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem>Option 1</DropdownItem>
          <DropdownItem>Option 2</DropdownItem>
          <DropdownItem divider />
          <DropdownItem>Reset</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown> */}
    </React.Fragment>
  );
}
