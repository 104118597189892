import React, { useState, useEffect, useContext } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardText,
  Button,
  Label,
  Breadcrumb,
  BreadcrumbItem,
  Collapse,
  CardHeader,
  Badge,
  Nav,
  NavItem,
  NavLink,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
} from 'reactstrap';
import { baseUrl, fetchWithGet, fetchWithPost, fetchWithFormData } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';
import * as Feather from 'react-feather';
import moment from 'moment';
import ReactPlayer from 'react-player/lazy';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import PopUpContext from '../../components/components/PopUp/PopUpContext';
import Cookies from 'js-cookie';
import Select from 'react-select';
import { CSVLink, CSVDownload } from 'react-csv';
import StepperCard, { getActiveStep } from './StepCard';
import { covidAnswer, levelOptions, profileHeader } from '../../components/helpers/reportHelper';
import LoadingButton from '../pages/widgets/LoadingButton';

export default function CandidateInfo({ rootPath, rootName, action }) {
  let { id } = useParams();
  let { url } = useRouteMatch();
  const [candidate, setCandidate] = useState({});
  const [applications, setApplications] = useState([]);
  const [cohorts, setCohorts] = useState([]);
  const [jobRoles, setJobRoles] = useState([]);
  const [cohort, setCohort] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  const [remark, setRemark] = useState({
    remark: '',
    specialCase: '',
    reviewed: '',
    toCohort3: '',
    cohort3Group: '',
    cohort3GroupFinal: '',
    withdrawn: '',
  });

  const [resume, setResume] = useState();

  const alertContext = useContext(PageAlertContext);
  const popUpContext = useContext(PopUpContext);

  const roles = Cookies.get('role');
  const scope = roles.split(',');

  const fetchApi = async () => {
    const result = await fetchWithGet(`/dashboard/getCandidateById/${id}`);

    if (result) {
      setCandidate(result.candidate);
      setRemark({
        remark: result.candidate.remark,
        specialCase: result.candidate.specialCase,
        reviewed: result.candidate.reviewed,
        toCohort3: result.candidate.toCohort3,
        cohort3Group: result.candidate.cohort3Group,
        cohort3GroupFinal: result.candidate.cohort3GroupFinal,
        withdrawn: result.candidate.withdrawn,
      });
      setApplications(result.application);
    }

    const cohortList = await fetchWithGet('/content/cohort');
    if (cohortList) {
      setCohorts(
        cohortList.map((v) => {
          return { value: v.id, label: v.en };
        })
      );
    }

    const jobRoleList = await fetchWithGet('/content/roles');
    if (jobRoleList) {
      setJobRoles(
        jobRoleList.map((v) => {
          return { value: v.id, label: v.en };
        })
      );
    }
  };
  useEffect(() => {
    fetchApi();
  }, []);

  const [modal, setModal] = useState(false);
  const [actionType, setActionType] = useState();
  const modalToggle = () => {
    if (modal) {
      fetchApi();
    }
    setModal(!modal);
  };

  const fixApplication = async (applicationId, cohortId, candidateId, action, appliedRoles) => {
    const result = await fetchWithPost(`/dashboard/modifyOrCreateApplicationForm`, {
      applicationId,
      cohortId,
      candidateId,
      action,
      appliedRoles,
    });

    if (result && result.status === 400) {
      alertContext.setAlert(result.data.message, 'danger');
    } else {
      alertContext.setAlert('Successfully updated', 'success');

      fetchApi();
    }
  };

  const profileConvert = (v) => {
    return {
      id: v.candidate.userId,
      cohort: v.cohort && v.cohort.en,
      givenNameEn: v.candidate.givenName.en,
      surnameEn: v.candidate.surname.en,
      givenNameZh: v.candidate.givenName.zh || '',
      surnameZh: v.candidate.surname.zh || '',
      preferredName: v.candidate.preferredName,
      gender: v.candidate.gender,
      phoneNo: v.candidate.phoneNo,
      email: v.candidate.email,
      resume: v.candidate.resume,
      remark:
        v?.candidate?.remark
          ?.replace(',', '')
          ?.replace('"', '')
          ?.replace(/(?:\\[rn])+/g, '') || '',
      portfolio: v.candidate.portfolio,
      createdAt: moment(v?.candidate?.createdAt).format('YYYY-MM-DD'),
      reviewed: v?.candidate?.reviewed,
      toCohort3: v?.candidate?.toCohort3,
      cohort3Group: v?.candidate?.cohort3Group,
      cohort3GroupFinal: v?.candidate?.cohort3GroupFinal,
      withdrawn: v?.candidate?.withdrawn,
      jobOffer1:
        v.offer &&
        v.offer[0] &&
        `${v.offer[0].finalInterview.position.role.parentId.en}/${v.offer[0].finalInterview.position.role.en}`,
      jobEmployer1: v.offer && v.offer[0] && `${v.offer[0].finalInterview.employer.givenName.en}`,
      jobStatus1: v.offer && v.offer[0] && `${_.startCase(v.offer[0].status)}ed`,
      jobOffer2:
        v.offer &&
        v.offer[1] &&
        `${v.offer[1].finalInterview.position.role.parentId.en}/${v.offer[1].finalInterview.position.role.en}`,
      jobEmployer2: v.offer && v.offer[1] && `${v.offer[1].finalInterview.employer.givenName.en}`,
      jobStatus2: v.offer && v.offer[1] && `${_.startCase(v.offer[1].status)}ed`,
      jobOffer3:
        v.offer &&
        v.offer[2] &&
        `${v.offer[2].finalInterview.position.role.parentId.en}/${v.offer[2].finalInterview.position.role.en}`,
      jobEmployer3: v.offer && v.offer[2] && `${v.offer[2].finalInterview.employer.givenName.en}`,
      jobStatus3: v.offer && v.offer[2] && `${_.startCase(v.offer[2].status)}ed`,
      jobOffer4:
        v.offer &&
        v.offer[3] &&
        `${v.offer[3].finalInterview.position.role.parentId.en}/${v.offer[3].finalInterview.position.role.en}`,
      jobEmployer4: v.offer && v.offer[3] && `${v.offer[3].finalInterview.employer.givenName.en}`,
      jobStatus4: v.offer && v.offer[3] && `${_.startCase(v.offer[3].status)}ed`,
      roleConvenor1: v.appliedRoles && v.appliedRoles[0] && v.appliedRoles[0].convenor.givenName.en,
      roleCohort1: v.appliedRoles && v.appliedRoles[0] && v.appliedRoles[0].cohort.toString(),
      role1: v.appliedRoles && v.appliedRoles[0] && `${v.appliedRoles[0].parentId.en} - ${v.appliedRoles[0].en}`,
      roleConvenor2: v.appliedRoles && v.appliedRoles[1] && v.appliedRoles[1].convenor.givenName.en,
      roleCohort2: v.appliedRoles && v.appliedRoles[1] && v.appliedRoles[1].cohort.toString(),
      level:
        v.candidate.education && v.candidate.education.level
          ? v.candidate.education.level === 'Others'
            ? v.candidate.education.otherLevel || ''
            : levelOptions[v.candidate.education.level] || ''
          : '',
      location: v.candidate.education
        ? v.candidate.education.otherLocation || (v.candidate.education.location && v.candidate.education.location.en)
        : '',
      institution: v.candidate.education
        ? v.candidate.education.otherInstitution ||
          (v.candidate.education.institution && v.candidate.education.institution.en)
        : '',
      programme: v.candidate.education && v.candidate.education.programmeName,
      areaOfStudy: v.candidate.education
        ? v.candidate.education.otherAreaOfStudy ||
          (v.candidate.education.areaOfStudy && v.candidate.education.areaOfStudy.en)
        : '',
      studyDuration:
        v.candidate.education &&
        `${v.candidate.education.startYear}/${v.candidate.education.startMonth} - ${
          _.isInteger(v.candidate.education.gradYear)
            ? `${v.candidate.education.gradYear}/${v.candidate.education.gradMonth}`
            : `${v.candidate.education.gradYear}`
        }`,
      paidExpCount: v.vask && v.vask.paidExp && v.vask.paidExp.length,
      employmentCount:
        v.vask && v.vask.paidExp && v.vask.paidExp.filter((a) => a.type && a.type.en === 'Employment').length,
      entrepreneurCount:
        v.vask && v.vask.paidExp && v.vask.paidExp.filter((a) => a.type && a.type.en === 'Entrepreneur').length,
      otherCount:
        v.vask &&
        v.vask.paidExp &&
        v.vask.paidExp.filter((a) => a.type && a.type.en !== 'Employment' && a.type.en !== 'Entrepreneur').length,
      pe1Type: v.vask && v.vask.paidExp[0] && v.vask.paidExp[0].type && v.vask.paidExp[0].type.en,
      pe1Organization: v.vask && v.vask.paidExp[0] && v.vask.paidExp[0].organization,
      pe1Role: v.vask && v.vask.paidExp[0] && v.vask.paidExp[0].role,
      pe1FromTo:
        v.vask &&
        v.vask.paidExp[0] &&
        `${moment(v.vask.paidExp[0].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[0].to).format('YYYY-MM-DD')}`,
      pe2Type: v.vask && v.vask.paidExp[1] && v.vask.paidExp[1].type && v.vask.paidExp[1].type.en,
      pe2Organization: v.vask && v.vask.paidExp[1] && v.vask.paidExp[1].organization,
      pe2Role: v.vask && v.vask.paidExp[1] && v.vask.paidExp[1].role,
      pe2FromTo:
        v.vask &&
        v.vask.paidExp[1] &&
        `${moment(v.vask.paidExp[1].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[1].to).format('YYYY-MM-DD')}`,
      pe3Type: v.vask && v.vask.paidExp[2] && v.vask.paidExp[2].type && v.vask.paidExp[2].type.en,
      pe3Organization: v.vask && v.vask.paidExp[2] && v.vask.paidExp[2].organization,
      pe3Role: v.vask && v.vask.paidExp[2] && v.vask.paidExp[2].role,
      pe3FromTo:
        v.vask &&
        v.vask.paidExp[2] &&
        `${moment(v.vask.paidExp[2].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[2].to).format('YYYY-MM-DD')}`,
      pe4Type: v.vask && v.vask.paidExp[3] && v.vask.paidExp[3].type && v.vask.paidExp[3].type.en,
      pe4Organization: v.vask && v.vask.paidExp[3] && v.vask.paidExp[3].organization,
      pe4Role: v.vask && v.vask.paidExp[3] && v.vask.paidExp[3].role,
      pe4FromTo:
        v.vask &&
        v.vask.paidExp[3] &&
        `${moment(v.vask.paidExp[3].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[3].to).format('YYYY-MM-DD')}`,
      pe5Type: v.vask && v.vask.paidExp[4] && v.vask.paidExp[4].type && v.vask.paidExp[4].type.en,
      pe5Organization: v.vask && v.vask.paidExp[4] && v.vask.paidExp[4].organization,
      pe5Role: v.vask && v.vask.paidExp[4] && v.vask.paidExp[4].role,
      pe5FromTo:
        v.vask &&
        v.vask.paidExp[4] &&
        `${moment(v.vask.paidExp[4].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[4].to).format('YYYY-MM-DD')}`,
      nonPaidExpCount: v.vask && v.vask.unpaidExp && v.vask.unpaidExp.length,
      voluntaryCount:
        v.vask && v.vask.unpaidExp && v.vask.unpaidExp.filter((a) => a.type && a.type.en === 'Voluntary').length,
      vocationalCount:
        v.vask &&
        v.vask.unpaidExp &&
        v.vask.unpaidExp.filter((a) => a.type && a.type.en === 'Vocational and Educational Training / Work Exposure')
          .length,
      seriousCount:
        v.vask &&
        v.vask.unpaidExp &&
        v.vask.unpaidExp.filter((a) => a.type && a.type.en === 'Serious Leisure or Interest').length,
      domesticCount:
        v.vask &&
        v.vask.unpaidExp &&
        v.vask.unpaidExp.filter((a) => a.type && a.type.en === 'Domestic and/or Neighborhood Provisioning').length,
      npe1Type: v.vask && v.vask.unpaidExp[0] && v.vask.unpaidExp[0].type && v.vask.unpaidExp[0].type.en,
      npe1Organization: v.vask && v.vask.unpaidExp[0] && v.vask.unpaidExp[0].organization,
      npe1Role: v.vask && v.vask.unpaidExp[0] && v.vask.unpaidExp[0].role,
      npe1FromTo:
        v.vask &&
        v.vask.unpaidExp[0] &&
        `${moment(v.vask.unpaidExp[0].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[0].to).format(
          'YYYY-MM-DD'
        )}`,
      npe2Type: v.vask && v.vask.unpaidExp[1] && v.vask.unpaidExp[1].type && v.vask.unpaidExp[1].type.en,
      npe2Organization: v.vask && v.vask.unpaidExp[1] && v.vask.unpaidExp[1].organization,
      npe2Role: v.vask && v.vask.unpaidExp[1] && v.vask.unpaidExp[1].role,
      npe2FromTo:
        v.vask &&
        v.vask.unpaidExp[1] &&
        `${moment(v.vask.unpaidExp[1].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[1].to).format(
          'YYYY-MM-DD'
        )}`,
      npe3Type: v.vask && v.vask.unpaidExp[2] && v.vask.unpaidExp[2].type && v.vask.unpaidExp[2].type.en,
      npe3Organization: v.vask && v.vask.unpaidExp[2] && v.vask.unpaidExp[2].organization,
      npe3Role: v.vask && v.vask.unpaidExp[2] && v.vask.unpaidExp[2].role,
      npe3FromTo:
        v.vask &&
        v.vask.unpaidExp[2] &&
        `${moment(v.vask.unpaidExp[2].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[2].to).format(
          'YYYY-MM-DD'
        )}`,
      npe4Type: v.vask && v.vask.unpaidExp[3] && v.vask.unpaidExp[3].type && v.vask.unpaidExp[3].type.en,
      npe4Organization: v.vask && v.vask.unpaidExp[3] && v.vask.unpaidExp[3].organization,
      npe4Role: v.vask && v.vask.unpaidExp[3] && v.vask.unpaidExp[3].role,
      npe4FromTo:
        v.vask &&
        v.vask.unpaidExp[3] &&
        `${moment(v.vask.unpaidExp[3].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[3].to).format(
          'YYYY-MM-DD'
        )}`,
      npe5Type: v.vask && v.vask.unpaidExp[4] && v.vask.unpaidExp[4].type && v.vask.unpaidExp[4].type.en,
      npe5Organization: v.vask && v.vask.unpaidExp[4] && v.vask.unpaidExp[4].organization,
      npe5Role: v.vask && v.vask.unpaidExp[4] && v.vask.unpaidExp[4].role,
      npe5FromTo:
        v.vask &&
        v.vask.unpaidExp[4] &&
        `${moment(v.vask.unpaidExp[4].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[4].to).format(
          'YYYY-MM-DD'
        )}`,
      V1: v.vask && v.vask.values[0] && v.vask.values[0].en,
      V2: v.vask && v.vask.values[1] && v.vask.values[1].en,
      V3: v.vask && v.vask.values[2] && v.vask.values[2].en,
      A1: v.vask && v.vask.attitudes[0] && v.vask.attitudes[0].en,
      A2: v.vask && v.vask.attitudes[1] && v.vask.attitudes[1].en,
      A3: v.vask && v.vask.attitudes[2] && v.vask.attitudes[2].en,
      S1: v.vask && v.vask.skills[0] && v.vask.skills[0].en,
      S2: v.vask && v.vask.skills[1] && v.vask.skills[1].en,
      S3: v.vask && v.vask.skills[2] && v.vask.skills[2].en,
      K1: v.vask && v.vask.knowledge[0] && v.vask.knowledge[0].en,
      K2: v.vask && v.vask.knowledge[1] && v.vask.knowledge[1].en,
      K3: v.vask && v.vask.knowledge[2] && v.vask.knowledge[2].en,
    };
  };

  const saveCandidateRemark = async (specialCase) => {
    const result = await fetchWithPost(`/dashboard/updateCandidateRemark`, {
      candidate: candidate.id,
      ...remark,
      specialCase,
    });

    if (result && result.status === 400) {
      alertContext.setAlert(result.data.message, 'danger');
    } else {
      alertContext.setAlert('Successfully updated', 'success');
      fetchApi();
    }
  };

  const saveCandidateReview = async (reviewed) => {
    const result = await fetchWithPost(`/dashboard/updateCandidateRemark`, {
      candidate: candidate.id,
      ...remark,
      reviewed,
    });

    if (result && result.status === 400) {
      alertContext.setAlert(result.data.message, 'danger');
    } else {
      alertContext.setAlert('Successfully updated', 'success');
      fetchApi();
    }
  };

  const saveCandidateToCohort3 = async (toCohort3) => {
    const result = await fetchWithPost(`/dashboard/updateCandidateRemark`, {
      candidate: candidate.id,
      ...remark,
      toCohort3,
    });

    if (result && result.status === 400) {
      alertContext.setAlert(result.data.message, 'danger');
    } else {
      alertContext.setAlert('Successfully updated', 'success');
      fetchApi();
    }
  };

  const saveCandidateCohort3Group = async (cohort3Group) => {
    const result = await fetchWithPost(`/dashboard/updateCandidateRemark`, {
      candidate: candidate.id,
      ...remark,
      cohort3Group,
    });

    if (result && result.status === 400) {
      alertContext.setAlert(result.data.message, 'danger');
    } else {
      alertContext.setAlert('Successfully updated', 'success');
      fetchApi();
    }
  };

  const saveCandidateCohort3GroupFinal = async (cohort3GroupFinal) => {
    const result = await fetchWithPost(`/dashboard/updateCandidateRemark`, {
      candidate: candidate.id,
      ...remark,
      cohort3GroupFinal,
    });

    if (result && result.status === 400) {
      alertContext.setAlert(result.data.message, 'danger');
    } else {
      alertContext.setAlert('Successfully updated', 'success');
      fetchApi();
    }
  };

  const saveCandidateWithdrawn = async (withdrawn) => {
    const result = await fetchWithPost(`/dashboard/updateCandidateRemark`, {
      candidate: candidate.id,
      ...remark,
      withdrawn,
    });

    if (result && result.status === 400) {
      alertContext.setAlert(result.data.message, 'danger');
    } else {
      alertContext.setAlert('Successfully updated', 'success');
      fetchApi();
    }
  };

  const sendNotification = async (applicationId) => {
    const result = await fetchWithPost('/dashboard/applicationReminder', {
      candidateId: candidate.id,
      applicationId: applicationId,
    });
    if (result && result.status === 400) {
      alertContext.setAlert(result.data.message, 'danger');
    } else {
      alertContext.setAlert('Successfully pushed', 'success');
    }
  };

  return (
    <div>
      <div className="mb-2">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={`${rootPath}`}>{rootName}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`${url}/${id}`}>
              {!_.isEmpty(candidate) ? `${candidate.givenName.en} ${candidate.surname.en}` : ''}
            </Link>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
      <Row>
        <Col xs={12}>
          {!_.isEmpty(candidate) ? (
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <div>Personal Information</div>
                <CSVLink
                  filename={`${candidate.givenName.en} ${candidate.surname.en}-profile`}
                  data={applications.map(profileConvert)}
                  headers={profileHeader}
                >
                  {'Download Full Profile'}
                </CSVLink>
              </CardHeader>
              <CardBody>
                <Table>
                  <tbody>
                    <tr>
                      <td>Candidate ID</td>
                      <td>{candidate.userId}</td>
                    </tr>
                    <tr>
                      <td>English Name</td>
                      <td>{`${candidate.givenName.en} ${candidate.surname.en}`}</td>
                    </tr>
                    <tr>
                      <td>Chinese Name</td>
                      <td>{`${candidate.surname.zh || ''}${candidate.givenName.zh || ''}`}</td>
                    </tr>
                    <tr>
                      <td>Preferred Name</td>
                      <td>{candidate.preferredName || ''}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{candidate.email}</td>
                    </tr>
                    <tr>
                      <td>Phone No</td>
                      <td>{candidate.phoneNo}</td>
                    </tr>
                    <tr>
                      <td>Channel</td>
                      <td>{candidate.channel}</td>
                    </tr>
                    <tr>
                      <td>Created At</td>
                      <td>{moment(candidate.createdAt).format('YYYY-MM-DD HH:mm')}</td>
                    </tr>
                    <tr>
                      <td>Remarks</td>
                      <td>
                        {scope.includes('admin') ? (
                          <FormGroup>
                            <Input
                              type="textarea"
                              name="remark"
                              id="remark"
                              value={remark.remark}
                              placeholder={'Remark'}
                              className="mb-2"
                              onChange={(e) => setRemark({ ...remark, remark: e.target.value })}
                            />
                            <Button onClick={() => saveCandidateRemark(remark.specialCase)} block outline>
                              Save
                            </Button>
                          </FormGroup>
                        ) : (
                          remark.remark
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Special Case</td>
                      <td>
                        {scope.includes('admin') ? (
                          <ButtonGroup block={true} style={{ width: '100%', marginBottom: 20 }}>
                            <Button
                              color="primary"
                              outline={!(remark.specialCase === 'Not Selected')}
                              onClick={async () => {
                                saveCandidateRemark('Not Selected');
                              }}
                            >
                              Not Selected
                            </Button>
                            <Button
                              color="primary"
                              outline={!(remark.specialCase === 'EM')}
                              onClick={async () => {
                                saveCandidateRemark('EM');
                              }}
                            >
                              EM (Ethnic Minority)
                            </Button>
                            <Button
                              color="primary"
                              outline={!(remark.specialCase === 'SEN')}
                              onClick={() => {
                                saveCandidateRemark('SEN');
                              }}
                            >
                              SEN (Special Educational Needs)
                            </Button>
                          </ButtonGroup>
                        ) : (
                          remark.specialCase
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Reviewed by Genhk</td>
                      <td>
                        {scope.includes('admin') ? (
                          <ButtonGroup block={true} style={{ width: '100%', marginBottom: 20 }}>
                            <Button
                              color="primary"
                              outline={!(remark.reviewed === 'Not Selected')}
                              onClick={async () => {
                                saveCandidateReview('Not Selected');
                              }}
                            >
                              Not Selected
                            </Button>
                            <Button
                              color="primary"
                              outline={!(remark.reviewed === 'Pass')}
                              onClick={() => {
                                saveCandidateReview('Pass');
                              }}
                            >
                              Pass
                            </Button>
                            <Button
                              color="primary"
                              outline={!(remark.reviewed === 'Rejected')}
                              onClick={() => {
                                saveCandidateReview('Rejected');
                              }}
                            >
                              Rejected
                            </Button>
                            <Button
                              color="primary"
                              outline={!(remark.reviewed === 'Further Checking')}
                              onClick={() => {
                                saveCandidateReview('Further Checking');
                              }}
                            >
                              Further Checking
                            </Button>
                          </ButtonGroup>
                        ) : (
                          remark.reviewed
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Withdrawn</td>
                      <td>
                        {scope.includes('admin') ? (
                          <ButtonGroup block={true} style={{ width: '100%', marginBottom: 20 }}>
                            <Button
                              color="primary"
                              outline={!(remark.withdrawn === 'Yes')}
                              onClick={() => {
                                saveCandidateWithdrawn(remark.withdrawn === 'Yes' ? '' : 'Yes');
                              }}
                            >
                              Yes
                            </Button>
                          </ButtonGroup>
                        ) : (
                          remark.withdrawn
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Shift to Cohort 3</td>
                      <td>
                        {scope.includes('admin') ? (
                          <ButtonGroup block={true} style={{ width: '100%', marginBottom: 20 }}>
                            <Button
                              color="primary"
                              outline={!(remark.toCohort3 === 'Yes')}
                              onClick={() => {
                                saveCandidateToCohort3(remark.toCohort3 === 'Yes' ? '' : 'Yes');
                              }}
                            >
                              Yes
                            </Button>
                          </ButtonGroup>
                        ) : (
                          remark.toCohort3
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Cohort 3 Group</td>
                      <td>
                        {scope.includes('admin') ? (
                          <ButtonGroup block={true} style={{ width: '100%', marginBottom: 20 }}>
                            <Button
                              color="primary"
                              outline={!(remark.cohort3Group === '3A')}
                              onClick={async () => {
                                saveCandidateCohort3Group('3A');
                              }}
                            >
                              3A
                            </Button>
                            <Button
                              color="primary"
                              outline={!(remark.cohort3Group === '3B')}
                              onClick={() => {
                                saveCandidateCohort3Group('3B');
                              }}
                            >
                              3B
                            </Button>
                            <Button
                              color="primary"
                              outline={!(remark.cohort3Group === '3C')}
                              onClick={() => {
                                saveCandidateCohort3Group('3C');
                              }}
                            >
                              3C
                            </Button>
                          </ButtonGroup>
                        ) : (
                          remark.cohort3Group
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Cohort 3 Group Final</td>
                      <td>
                        {scope.includes('admin') || scope.includes('convenor') ? (
                          <ButtonGroup block={true} style={{ width: '100%', marginBottom: 20 }}>
                            <Button
                              color="primary"
                              outline={!(remark.cohort3GroupFinal === '3A')}
                              onClick={async () => {
                                saveCandidateCohort3GroupFinal('3A');
                              }}
                            >
                              3A
                            </Button>
                            <Button
                              color="primary"
                              outline={!(remark.cohort3GroupFinal === '3B')}
                              onClick={() => {
                                saveCandidateCohort3GroupFinal('3B');
                              }}
                            >
                              3B
                            </Button>
                            <Button
                              color="primary"
                              outline={!(remark.cohort3GroupFinal === '3C')}
                              onClick={() => {
                                saveCandidateCohort3GroupFinal('3C');
                              }}
                            >
                              3C
                            </Button>
                          </ButtonGroup>
                        ) : (
                          remark.cohort3GroupFinal
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          ) : (
            <div></div>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {candidate.education ? (
            <Card>
              <CardHeader>Education Background</CardHeader>
              <CardBody>
                <Table>
                  <tbody>
                    <tr>
                      <td>Level</td>
                      <td>
                        {candidate.education.level && levelOptions[candidate.education.level]}
                        <br />
                        {candidate.education && candidate.education.level === 'Others'
                          ? candidate.education.otherLevel || ''
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td>Institution</td>
                      <td>
                        {candidate.education.institution && candidate.education.institution.en}
                        <br />
                        {candidate.education.otherInstitution || ''}
                      </td>
                    </tr>
                    <tr>
                      <td>Programme Name</td>
                      <td>{candidate.education.programmeName}</td>
                    </tr>
                    <tr>
                      <td>Area of Study</td>
                      <td>
                        {/* {candidate.education.areaOfStudy && candidate.education.areaOfStudy.en}
                        <br />
                        {candidate.education.areaOfStudy.isOther ? candidate.education.otherAreaOfStudy || '' : ''} */}
                        {candidate.education.otherAreaOfStudy
                          ? candidate.education.otherAreaOfStudy
                          : candidate.education.areaOfStudy && candidate.education.areaOfStudy.en}
                      </td>
                    </tr>
                    <tr>
                      <td>Location</td>
                      <td>
                        {/* {candidate.education.location && candidate.education.location.en}
                        <br />
                        {candidate.education.location.isOther ? candidate.education.otherLocation || '' : ''} */}
                        {candidate.education.otherLocation
                          ? candidate.education.otherLocation
                          : candidate.education.location && candidate.education.location.en}
                      </td>
                    </tr>
                    <tr>
                      <td>Start Date</td>
                      <td>{`${candidate.education.startYear || ''}/${candidate.education.startMonth || ''}`}</td>
                    </tr>
                    <tr>
                      <td>Grad Date</td>
                      <td>
                        {_.isInteger(candidate.education.gradYear)
                          ? `${candidate.education.gradYear || ''}/${candidate.education.gradMonth || ''}`
                          : `${candidate.education.gradYear || ''}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Resume</td>
                      <td>
                        {candidate.resume ? (
                          <>
                            <Button href={candidate.resume} color="link" target="_blank">
                              Resume
                            </Button>
                          </>
                        ) : (
                          <div />
                        )}
                        {scope.includes('admin') ? (
                          <div class="input-group">
                            <div class="custom-file">
                              <input
                                type="file"
                                class="custom-file-input"
                                id="resume"
                                onChange={(e) => setResume(e.target.files)}
                              />
                              <label class="custom-file-label" for="resume">
                                {resume ? resume[0].name : 'Click here to select'}
                              </label>
                            </div>
                            <div class="input-group-append">
                              <LoadingButton
                                color="primary"
                                onClick={async () => {
                                  if (resume?.length > 0) {
                                    const result = await fetchWithFormData('/dashboard/updateCandidate', {
                                      candidate: candidate.id,
                                      resume: resume[0],
                                    });
                                    if (result) {
                                      alertContext.setAlert('Successfully updated', 'success');
                                      setResume();
                                      fetchApi();
                                    }
                                  } else {
                                    alertContext.setAlert('Please select file', 'danger');
                                  }
                                }}
                                text={'Submit'}
                              />
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Portfolio</td>
                      <td>
                        {candidate.portfolio ? (
                          <Button href={candidate.portfolio} color="link" target="_blank">
                            Portfolio
                          </Button>
                        ) : (
                          <div />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          ) : (
            <div />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {candidate.covid ? (
            <Card>
              <CardHeader>Covid 19 Information</CardHeader>
              <CardBody>
                <Table>
                  <tbody>
                    <tr>
                      <td width={'40%'}>Dose(s) of COVID-19 vaccine I received</td>
                      <td>{candidate.covid.q1}</td>
                    </tr>
                    <tr>
                      <td>Date of last dose of vaccine</td>
                      <td>{candidate.covid.q2 ? moment(candidate.covid.q2).format('YYYY-MM-DD') : 'N/A'}</td>
                    </tr>
                    <tr>
                      <td>Do you hold any of the followings</td>
                      <td>{covidAnswer[candidate.covid.q3]}</td>
                    </tr>
                    <tr>
                      <td>
                        Expiry date of my COVID-19 Vaccination Medical Exemption / Recovery Record QR Code /
                        &apos;Provisional Vaccine Pass&apos;
                      </td>
                      <td>{candidate.covid.q4 ? moment(candidate.covid.q4).format('YYYY-MM-DD') : 'N/A'}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          ) : (
            <div />
          )}
        </Col>
      </Row>
      <Modal centered={true} isOpen={modal} toggle={modalToggle}>
        <ModalHeader toggle={modalToggle}> </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>
              <b>Please select cohort</b>
            </Label>
            <Select options={cohorts} value={cohort} onChange={(v) => setCohort(v)} />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          {actionType == 'create' ? (
            <Button
              color="primary"
              onClick={async () => {
                if (cohort) {
                  const confirm = await popUpContext.onOpen('Are you confirm to create an application?');
                  if (confirm) {
                    fixApplication('', cohort.value, candidate.id, 'create', []);
                    setModal(!modal);
                  }
                }
              }}
            >
              Submit
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={async () => {
                if (cohort) {
                  const confirm = await popUpContext.onOpen('Are you confirm to copy this application?');
                  if (confirm) {
                    fixApplication(applications[activeTab].id, cohort.value, '', 'copy', []);
                    setModal(!modal);
                  }
                }
              }}
            >
              Submit
            </Button>
          )}
        </ModalFooter>
      </Modal>
      {scope.includes('admin') ? (
        <Button
          className="mb-3"
          block
          onClick={() => {
            setActionType('create');
            modalToggle();
          }}
        >
          Apply cohort application
        </Button>
      ) : null}
      {!_.isEmpty(applications) ? (
        <Nav tabs>
          {applications.map((v, i) => (
            <NavItem key={i}>
              <NavLink href="#" className={`${activeTab === i ? 'active' : ''}`} onClick={() => setActiveTab(i)}>
                {v.cohort.en}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      ) : (
        <div />
      )}
      {!_.isEmpty(applications) ? (
        <Row>
          <Col xs={12}>
            <Card key={activeTab}>
              <CardHeader>{applications[activeTab].cohort.en}</CardHeader>
              <CardBody>
                {scope.includes('admin') ? (
                  <ButtonGroup>
                    <Button
                      onClick={() => {
                        setActionType('copy');
                        modalToggle();
                      }}
                    >
                      Copy this application
                    </Button>

                    {getActiveStep(applications[activeTab]) <= 2 ? (
                      <Button
                        onClick={async () => {
                          const confirm = await popUpContext.onOpen(
                            'Are you confirm to create empty record until group interview'
                          );
                          if (confirm) {
                            fixApplication(applications[activeTab].id, '', '', 'fix', []);
                          }
                        }}
                      >
                        Create empty record until group interview
                      </Button>
                    ) : (
                      <></>
                    )}
                    {getActiveStep(applications[activeTab]) <= 1 ? (
                      <Button onClick={async () => sendNotification(applications[activeTab].id)}>
                        Send application submit reminder
                      </Button>
                    ) : (
                      <></>
                    )}
                  </ButtonGroup>
                ) : null}
                <StepperCard application={applications[activeTab]} />
                <div>
                  <ApplicationModal {...applications[activeTab]} fetchApi={fetchApi} jobRoles={jobRoles} />
                  {applications[activeTab].vask ? (
                    <VaskModal {...applications[activeTab]} fetchApi={fetchApi} />
                  ) : (
                    <div />
                  )}
                  {applications[activeTab].videoInterview ? (
                    <VideoInterviewModal {...applications[activeTab]} fetchApi={fetchApi} />
                  ) : (
                    <div />
                  )}
                  {applications[activeTab].groupInterview ? (
                    <GroupInterviewModal {...applications[activeTab]} fetchApi={fetchApi} />
                  ) : (
                    <div />
                  )}
                  {applications[activeTab].finalInterview.length > 0 ? (
                    <FinalInterviewModal {...applications[activeTab]} fetchApi={fetchApi} />
                  ) : (
                    <div />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <div />
      )}
    </div>
  );
}
function CollapseModal({ children, title }) {
  const [isOpen, setIsOpen] = useState(false);
  const ExpandIcon = isOpen ? Feather.ChevronDown : Feather.ChevronRight;

  return (
    <Card>
      <CardBody>
        <Row onClick={() => setIsOpen(!isOpen)}>
          <Col xs={10}>{title}</Col>
          <Col xs={2} className="text-right">
            <ExpandIcon className="menu-expand-icon" />
          </Col>
        </Row>
      </CardBody>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </Card>
  );
}
function ApplicationModal({ appliedRoles, candidate, id, fetchApi, jobRoles }) {
  const popUpContext = useContext(PopUpContext);
  const alertContext = useContext(PageAlertContext);

  const roles = Cookies.get('role');
  const scope = roles.split(',');

  const options = [{ value: '', label: 'Unselect' }, ...jobRoles];
  const [rolesModal, setRolesModal] = useState(false);
  const rolesModalToggle = () => {
    if (!rolesModal) {
      fetchApi();
    }
    setRolesModal(!rolesModal);
  };

  const [job1, setJob1] = useState(_.find(options, (v) => v.value === appliedRoles[0]?.id));
  const [job2, setJob2] = useState(_.find(options, (v) => v.value === appliedRoles[1]?.id));

  useEffect(() => {
    setJob1(_.find(options, (v) => v.value === appliedRoles[0]?.id));
    setJob2(_.find(options, (v) => v.value === appliedRoles[1]?.id));
  }, [jobRoles]);

  return (
    <>
      <Card>
        <CardHeader>
          Applied Roles{' '}
          {scope.includes('admin') ? (
            <Button color="link" onClick={() => setRolesModal(!rolesModal)}>
              Change Roles
            </Button>
          ) : (
            <></>
          )}
        </CardHeader>
        <CardBody>
          {appliedRoles.map((v, i) => (
            <CardText key={i}>{`${v.parentId.en} - ${v.en}`}</CardText>
          ))}
        </CardBody>
      </Card>
      <Modal centered={true} isOpen={rolesModal} toggle={rolesModalToggle}>
        <ModalHeader toggle={rolesModalToggle}> </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>
              <b>Please select job roles</b>
            </Label>
            <Select className="mb-2" options={options} value={job1} onChange={(v) => setJob1(v)} />
            <Select options={options} value={job2} onChange={(v) => setJob2(v)} />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              if (job1?.value || job2?.value) {
                const confirm = await popUpContext.onOpen('Are you confirm to change the job roles?');
                if (confirm) {
                  const appliedRolesList = [];
                  if (job1?.value) {
                    appliedRolesList.push(job1.value);
                  }
                  if (job2?.value) {
                    appliedRolesList.push(job2.value);
                  }
                  const result = await fetchWithFormData('/dashboard/updateCandidate', {
                    candidate: candidate.id,
                    applicationId: id,
                    appliedRoles: JSON.stringify(appliedRolesList),
                  });
                  if (result) {
                    alertContext.setAlert('Successfully updated', 'success');
                    setRolesModal(!rolesModal);
                    fetchApi();
                  }
                }
              } else {
                alertContext.setAlert('Please select at least one job role', 'danger');
              }
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

function VaskModal({ vask, role, fetchApi }) {
  console.log({ vask });
  return (
    <div>
      <Card>
        <CardHeader>VASK</CardHeader>
        <CardBody>
          <div>
            <Label>Values: </Label>
            {vask.values.map((v, i) => (
              <Badge color="primary" pill className="mr-2">
                {`${v.en} - ${v.zh}`}
              </Badge>
            ))}
          </div>
          <div>
            <Label>Attitudes: </Label>
            {vask.attitudes.map((v, i) => (
              <Badge color="primary" pill className="mr-2">
                {`${v.en} - ${v.zh}`}
              </Badge>
            ))}
          </div>
          <div>
            <Label>Skills: </Label>
            {vask.skills.map((v, i) => (
              <Badge color="primary" pill className="mr-2">
                {`${v.en} - ${v.zh}`}
              </Badge>
            ))}
          </div>
          <div>
            <Label>Knowledge: </Label>
            {vask.knowledge.map((v, i) => (
              <Badge color="primary" pill className="mr-2">
                {`${v.en} - ${v.zh}`}
              </Badge>
            ))}
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Paid Experience</CardHeader>
        <CardBody>
          {vask.paidExp.length > 0 ? (
            vask.paidExp.map((v) => (
              <Table>
                <tbody>
                  <tr>
                    <td>Type</td>
                    <td>{(v.type || {}).en}</td>
                  </tr>
                  <tr>
                    <td>Organization</td>
                    <td>{v.organization}</td>
                  </tr>
                  <tr>
                    <td>Role</td>
                    <td>{v.role}</td>
                  </tr>
                  <tr>
                    <td>From - To</td>
                    <td>
                      {v.from ? `${moment(v.from).format('YYYY-MM-DD')} - ${moment(v.to).format('YYYY-MM-DD')}` : ''}
                    </td>
                  </tr>
                  <tr>
                    <td>Link</td>
                    <td>
                      {/^(http|https):\/\/[^ "]+$/.test(v.link) ? (
                        <a href={v.link} target="_blank" rel="noopener noreferrer">
                          {v.link}
                        </a>
                      ) : (
                        <div>{v.link}</div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            ))
          ) : (
            <CardText>No paid experience</CardText>
          )}
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Unpaid Experience</CardHeader>
        <CardBody>
          {vask.unpaidExp.length > 0 ? (
            vask.unpaidExp.map((v) => (
              <Table>
                <tbody>
                  <tr>
                    <td>Type</td>
                    <td>{(v.type || {}).en}</td>
                  </tr>
                  <tr>
                    <td>Organization</td>
                    <td>{v.organization}</td>
                  </tr>
                  <tr>
                    <td>Role</td>
                    <td>{v.role}</td>
                  </tr>
                  <tr>
                    <td>From - To</td>
                    <td>
                      {v.from ? `${moment(v.from).format('YYYY-MM-DD')} - ${moment(v.to).format('YYYY-MM-DD')}` : ''}
                    </td>
                  </tr>
                  <tr>
                    <td>Link</td>
                    <td>
                      {/^(http|https):\/\/[^ "]+$/.test(v.link) ? (
                        <a href={v.link} target="_blank" rel="noopener noreferrer">
                          {v.link}
                        </a>
                      ) : (
                        <div>{v.link}</div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            ))
          ) : (
            <CardText>No unpaid experience</CardText>
          )}
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Self-Discovery</CardHeader>
        <CardBody>
          {vask.openQuestionAnswer.length > 0 ? (
            vask.openQuestionAnswer.map((v, i) => (
              <CardText>
                <h5>
                  <u>
                    <b>{v.type.en}</b>
                  </u>
                </h5>
                {v.answer}
              </CardText>
            ))
          ) : (
            <CardText>No Self-Discovery</CardText>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

function VideoInterviewModal({ videoInterview, appliedRoles }) {
  console.log(appliedRoles);
  return (
    <div>
      {!videoInterview.url ? (
        <Card>
          <CardHeader>Video Interview Information</CardHeader>
          <CardBody>
            {videoInterview.link ? (
              <div>
                <CardText>
                  Link:{' '}
                  <a
                    href={`${videoInterview.link.url}?access_code=${videoInterview.link.access_code}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{`${videoInterview.link.url}?access_code=${videoInterview.link.access_code}`}</a>
                </CardText>
                <CardText>Status: {videoInterview.status === 'finished' ? 'Finished' : 'Pending'}</CardText>
                <CardText>
                  Finish Time:{' '}
                  {videoInterview.videoFinishDatetime &&
                    moment(videoInterview.videoFinishDatetime).format('YYYY-MM-DD HH:mm:ss')}
                </CardText>
              </div>
            ) : (
              <CardText>Waiting for candidate click the video button</CardText>
            )}
          </CardBody>
        </Card>
      ) : (
        <></>
      )}
      <Card>
        <CardHeader>Video Interview</CardHeader>
        {videoInterview.url ? (
          <CardBody>
            {videoInterview.status === 'finished' ? (
              <CardText>
                <a href={videoInterview.url} target="_blank" rel="noopener noreferrer">
                  {videoInterview.url}
                </a>
              </CardText>
            ) : (
              <CardText>Candidate has not finished video interview</CardText>
            )}
          </CardBody>
        ) : (
          <CardBody>
            {videoInterview.status === 'finished' ? (
              _.chunk(
                [
                  'Q1: Please briefly introduce yourself',
                  'Q2: 你為什麼有興趣參加這個見習計劃和相關職位？',
                  'Q3: 請分享一個故事或經歷，你在當中扮演甚麼角色？有什麼啟發及得著？',
                  'Q4: 分享一個難忘的團隊合作經歷有什麼做得好的地方？有什麼要改善的地方？',
                  'Q5: 請分享一個最近有興趣或學習到的新技能，有什麼吸引之處？',
                ],
                2
              ).map((v, i) => (
                <Row>
                  {v.map((sv, si) => (
                    <Col sm={12} md={6}>
                      <div className="mb-2">
                        <Label style={{ minHeight: 72 }}>{sv}</Label>
                        <ReactPlayer
                          width="100%"
                          height="100%"
                          controls={true}
                          url={`${baseUrl}neufast/${(videoInterview.link || {}).code || ''}-${i * 2 + si + 1}.mp4`}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              ))
            ) : (
              <CardText>Candidate has not finished video interview</CardText>
            )}
          </CardBody>
        )}
      </Card>
      {!videoInterview.url ? (
        <Card>
          <CardHeader>Video Interview Analysis Result</CardHeader>
          <CardBody>
            {videoInterview.result ? (
              <div>
                <CardText>
                  <a
                    href={`${baseUrl}neufast/${videoInterview.link.code}.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Report
                  </a>
                </CardText>
                <Row>
                  {Object.keys(videoInterview.result).map((v) => {
                    if (!_.isEmpty(videoInterview.result[v])) {
                      return (
                        <Col sm={12} md={4}>
                          <Card>
                            <CardHeader>
                              {_.startCase(v.replace('_', ' '))}
                              {v === 'motivation'
                                ? appliedRoles[0]
                                  ? ` (${appliedRoles[0].en})`
                                  : ''
                                : v === 'motivation_b'
                                ? appliedRoles[1]
                                  ? ` (${appliedRoles[1].en})`
                                  : ''
                                : ''}
                            </CardHeader>
                            <CardBody>
                              <CardText>{`${videoInterview.result[v].value}/10`}</CardText>
                              <CardText>{videoInterview.result[v].comment}</CardText>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    }
                  })}
                </Row>
              </div>
            ) : (
              <CardText>Video Interview Analysis Report not available yet</CardText>
            )}
          </CardBody>
        </Card>
      ) : (
        <></>
      )}
    </div>
  );
}
const getRecommendation = (status) => {
  switch (status) {
    case 'recommended':
      return (
        <div>
          <Badge color="primary" pill>
            Recommended
          </Badge>
        </div>
      );
    case 'waitinglist':
      return (
        <div>
          <Badge color="warning" pill>
            Waiting List
          </Badge>
        </div>
      );
    case 'notrecommended':
      return (
        <div>
          <Badge color="danger" pill>
            Not Recommended
          </Badge>
        </div>
      );
    case 'absent':
      return (
        <div>
          <Badge color="info" pill>
            Absent
          </Badge>
        </div>
      );
    default:
      return <div />;
  }
};
function GroupInterviewModal({ groupInterview, cohort, fetchApi }) {
  console.log({ groupInterview });
  const alertContext = useContext(PageAlertContext);

  const _save = async (status) => {
    const result = await fetchWithPost('/dashboard/updateInterviewStatus', {
      stage: 'group',
      interviewId: groupInterview.id,
      status: status,
      interviewNotes: groupInterview.interviewer.interviewNotes,
    });

    if (result && (result.status === 400 || result.status === 404)) {
      alertContext.setAlert(result.data.message, 'danger');
    } else {
      alertContext.setAlert('Successfully updated', 'success');
    }
    fetchApi();
  };

  return (
    <>
      <Card>
        <CardHeader>Growth Progress Self-evaluation</CardHeader>
        <CardBody>
          <CardText>
            Survey Link:{' '}
            <a
              href={`https://esurvey.psy.cuhk.edu.hk/jfe/form/SV_equhjEpPaUK5ltA?FTJSID=${groupInterview.id}-${cohort.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >{`https://esurvey.psy.cuhk.edu.hk/jfe/form/SV_equhjEpPaUK5ltA?FTJSID=${groupInterview.id}-${cohort.id}`}</a>
          </CardText>
          <CardText>Finished: {groupInterview.surveyFinishDatetime ? 'True' : 'False'}</CardText>
          <CardText>
            Survey Finish Time:{' '}
            {groupInterview.surveyFinishDatetime &&
              moment(groupInterview.surveyFinishDatetime).format('YYYY-MM-DD HH:mm:ss')}
          </CardText>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Group Interview Information</CardHeader>
        <CardBody>
          {groupInterview.startDatetime ? (
            <CardText>
              Date: {moment(groupInterview.startDatetime).format('YYYY-MM-DD')}
              <br />
              Time: {moment(groupInterview.startDatetime).format('HH:mm')}
              {' - '}
              {moment(groupInterview.endDatetime).format('HH:mm')}
              <br />
              Link:{' '}
              <a href={groupInterview.interviewLink} target="_blank" rel="noopener noreferrer">
                {groupInterview.interviewLink}
              </a>
            </CardText>
          ) : (
            <CardText>No scheduled Group Interview</CardText>
          )}
          <Label>GenHK comment</Label>
          <ButtonGroup style={{ width: '100%' }}>
            <Button
              color="success"
              outline={!(groupInterview.interviewer.status === 'recommended')}
              onClick={() => {
                if (groupInterview.interviewer.status === 'recommended') {
                  _save('');
                } else {
                  _save('recommended');
                }
              }}
            >
              Recommended
            </Button>
            <Button
              color="warning"
              outline={!(groupInterview.interviewer.status === 'waitinglist')}
              onClick={() => {
                if (groupInterview.interviewer.status === 'waitinglist') {
                  _save('');
                } else {
                  _save('waitinglist');
                }
              }}
            >
              Waiting List
            </Button>
            <Button
              color="danger"
              outline={!(groupInterview.interviewer.status === 'notrecommended')}
              onClick={() => {
                if (groupInterview.interviewer.status === 'notrecommended') {
                  _save('');
                } else {
                  _save('notrecommended');
                }
              }}
            >
              Not Recommended
            </Button>
            <Button
              color="info"
              outline={!(groupInterview.interviewer.status === 'absent')}
              onClick={() => {
                if (groupInterview.interviewer.status === 'absent') {
                  _save('');
                } else {
                  _save('absent');
                }
              }}
            >
              Absent
            </Button>
          </ButtonGroup>
          {/* <CardText>{getRecommendation(groupInterview.interviewer.status)}</CardText> */}

          <Label>Convenors comment</Label>
          {groupInterview.comments.length > 0 ? (
            _.map(groupInterview.comments, (v, i) => {
              return (
                <CardText>
                  {v.convenor.givenName.en}
                  {getRecommendation(v.status)}
                  {/* <p className="text-muted">{v.interviewNotes || 'No Comments'}</p> */}
                </CardText>
              );
            })
          ) : (
            <div />
          )}
        </CardBody>
      </Card>
    </>
  );
}

function FinalInterviewModal({ finalInterview, editable, fetchApi }) {
  console.log({ finalInterview });

  return (
    <div>
      <Card>
        <CardHeader>Final Interview Information</CardHeader>
        {finalInterview.length > 0 ? (
          <CardBody>
            {_.map(finalInterview, (v) => {
              console.log(v);
              return (
                <Card>
                  <CardBody>
                    {v.startDatetime ? (
                      <div>
                        <Label>Interview Info</Label>
                        <CardText>
                          Date: {moment(v.startDatetime).format('YYYY-MM-DD')}
                          <br />
                          Time: {moment(v.startDatetime).format('HH:mm')}
                          {' - '}
                          {moment(v.endDatetime).format('HH:mm')}
                          <br />
                          Venue: {v.venue}
                          <br />
                          Important Notes: {v.importantNotes}
                          <br />
                          Notified candidate:{' '}
                          {v.status !== 'pending' ? (
                            <span className="text-success">Yes</span>
                          ) : (
                            <span className="text-danger">No</span>
                          )}
                        </CardText>
                      </div>
                    ) : (
                      <div />
                    )}

                    <Label>Interviewer comment</Label>
                    {v.interviewer.status ? (
                      <CardText>
                        {getRecommendation(v.interviewer.status)}
                        {/* {v.interviewer.interviewNotes || <p className="text-muted">{'No Comments'}</p>} */}
                      </CardText>
                    ) : (
                      <div />
                    )}
                    <Label>Convenors comment</Label>
                    {v.comments.length > 0 ? (
                      _.map(v.comments, (a, i) => {
                        return (
                          <CardText>
                            {getRecommendation(a.status)}
                            {/* {a.interviewNotes || <p className="text-muted">{'No Comments'}</p>} */}
                          </CardText>
                        );
                      })
                    ) : (
                      <div />
                    )}
                  </CardBody>
                </Card>
              );
            })}
          </CardBody>
        ) : (
          <CardBody>
            <CardText>No Interview</CardText>
          </CardBody>
        )}
      </Card>
    </div>
  );
}
