import React, { useState, useEffect } from 'react';
import {
  ButtonGroup,
  Button,
  Input,
  Label,
  FormGroup,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { fetchWithGet, fetchWithPost } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import { Loader } from '../../components';

export default function Stream() {
  let { path, url } = useRouteMatch();
  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <StreamList rootPath={url} />
        </Route>
        <Route path={`${path}/:streamId`}>
          <Roles rootPath={url} />
        </Route>
      </Switch>
    </Router>
  );
}

function StreamList({ rootPath }) {
  const history = useHistory();
  const [streams, setStreams] = useState([]);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);

  const [id, setId] = useState('');
  const [zh, setZh] = useState('');
  const [en, setEn] = useState('');
  const [isHided, setIsHided] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    '#',
    'Stream Name En',
    'Stream Name Zh',
    'Hided',
    {
      name: 'Actions',
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
          return (
            <ButtonGroup>
              <Button color="primary" outline onClick={() => toggle(streams[rowIndex])}>
                Edit Stream
              </Button>
              <Button color="primary" outline onClick={() => history.push(`${rootPath}/${streams[rowIndex].id}`)}>
                Go to Roles
              </Button>
            </ButtonGroup>
          );
        },
      },
    },
  ];
  const options = {
    selectableRows: 'none',
    print: false,
    download: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/content/streams');
    if (result) {
      setStreams(result);
      setData(result.map((v, i) => [i + 1, v.en, v.zh, v.isHided ? 'True' : 'False']));
    }
    setIsLoading(false);
  };

  const toggle = (v) => {
    if (v && v.id) {
      setId(v.id);
      setZh(v.zh);
      setEn(v.en);
      setIsHided(v.isHided);
    } else {
      setId('');
      setZh('');
      setEn('');
      setIsHided(false);
      fetchApi();
    }

    setModal(!modal);
  };
  const save = async (context) => {
    if (zh && en) {
      const result = await fetchWithPost('/content/streams', {
        id,
        zh,
        en,
        isHided,
      });
      if (result && result.status === 400) {
        context.setAlert(result.data.message, 'danger');
        setTimeout(() => context.closeAlert(), 2000);
      } else {
        context.setAlert('Successfully updated', 'success');
        setTimeout(() => context.closeAlert(), 2000);
      }
    } else {
      context.setAlert('Please fill in all the blank', 'danger');
      setTimeout(() => context.closeAlert(), 2000);
    }

    toggle();
  };

  return (
    <PageAlertContext.Consumer>
      {(context) => (
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={`${rootPath}`}>Streams</Link>
            </BreadcrumbItem>
          </Breadcrumb>
          <div className="text-right mb-2">
            <Button color="primary" onClick={toggle}>
              Create new stream
            </Button>
          </div>

          <MUIDataTable data={data} columns={columns} options={options} />

          <Modal centered={true} isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>
              <b>{id ? 'Edit' : 'Create'} Stream</b>
            </ModalHeader>
            <ModalBody>
              <FormGroup className="mb-2">
                <Label>Name</Label>
                <Input className="mb-2" placeholder="中文" value={zh} onChange={(v) => setZh(v.target.value)} />
                <Input placeholder="English" value={en} onChange={(v) => setEn(v.target.value)} />
              </FormGroup>
              <FormGroup>
                <Label>Hidden</Label>
                <Input
                  type="select"
                  name="select"
                  value={isHided}
                  onChange={(v) => setIsHided(v.target.value === 'true')}
                >
                  <option value={true}>{'True'}</option>
                  <option value={false}>{'False'}</option>
                </Input>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => save(context)}>
                {id ? 'Save' : 'Create'}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </PageAlertContext.Consumer>
  );
}

function Roles({ rootPath }) {
  let { streamId } = useParams();
  let { url } = useRouteMatch();
  const [stream, setStream] = useState({});
  const [roles, setRoles] = useState([]);
  const [convenors, setConvenors] = useState([]);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);

  const [id, setId] = useState('');
  const [zh, setZh] = useState('');
  const [en, setEn] = useState('');
  const [shortName, setShortName] = useState('');
  const [isHided, setIsHided] = useState(false);
  const [parentId, setParentId] = useState(streamId);
  const [convenor, setConvenor] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const columns = ['#', 'Stream Name Zh', 'Stream Name En', 'Short Name', 'Hided'];
  const options = {
    selectableRows: 'none',
    download: false,
    onRowClick: (rowData, rowMeta) => {
      toggle(roles[rowMeta.dataIndex]);
    },
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };
  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet(`/content/streams/${streamId}`);
    if (result) {
      setStream(result);
      setRoles(result.roles);
      setData(result.roles.map((v, i) => [i + 1, v.zh, v.en, v.shortName || '', v.isHided ? 'True' : 'False']));
    }
    const convenorList = await fetchWithGet(`/dashboard/getPositionConvenor`);

    if (convenorList) {
      setConvenors(convenorList);
    }
    setIsLoading(false);
  };

  const toggle = (v) => {
    if (v?.id) {
      setId(v.id);
      setZh(v.zh);
      setEn(v.en);
      setShortName(v.shortName || '');
      setConvenor(v.convenor || '');
      setIsHided(v.isHided);
    } else {
      setId('');
      setZh('');
      setEn('');
      setShortName('');
      setConvenor('');
      setIsHided(false);
      fetchApi();
    }
    setModal(!modal);
  };
  const save = async (context) => {
    if (zh && en) {
      const result = await fetchWithPost('/content/streams', {
        id,
        zh,
        en,
        shortName,
        isHided,
        parentId,
        convenor,
      });
      if (result && result.status === 400) {
        context.setAlert(result.data.message, 'danger');
        setTimeout(() => context.closeAlert(), 2000);
      } else {
        context.setAlert('Successfully updated', 'success');
        setTimeout(() => context.closeAlert(), 2000);
      }
    } else {
      context.setAlert('Please fill in all the blank', 'danger');
      setTimeout(() => context.closeAlert(), 2000);
    }
    toggle();
  };

  return (
    <PageAlertContext.Consumer>
      {(context) => (
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={`${rootPath}`}>Streams</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`${url}/${streamId}`}>{!_.isEmpty(stream) ? `${stream.zh}` : streamId}</Link>
            </BreadcrumbItem>
          </Breadcrumb>
          <div className="text-right mb-2">
            <Button color="primary" onClick={toggle}>
              Create new role
            </Button>
          </div>

          <MUIDataTable data={data} columns={columns} options={options} />

          <Modal centered={true} isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>
              <b>{id ? 'Edit' : 'Create new '} Role</b>
            </ModalHeader>
            <ModalBody>
              <FormGroup className="mb-2">
                <Label>Name</Label>
                <Input className="mb-2" placeholder="中文" value={zh} onChange={(v) => setZh(v.target.value)} />
                <Input placeholder="English" value={en} onChange={(v) => setEn(v.target.value)} />
              </FormGroup>
              <FormGroup className="mb-2">
                <Label>Short Name</Label>
                <Input
                  className="mb-2"
                  placeholder="Short Name"
                  value={shortName}
                  onChange={(v) => setShortName(v.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Convenor</Label>
                <Input type="select" name="Convenor" value={convenor} onChange={(v) => setConvenor(v.target.value)}>
                  {convenors.map((v) => {
                    return <option value={v.id}>{`${v.givenName.en}`}</option>;
                  })}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Hidden</Label>
                <Input
                  type="select"
                  name="select"
                  value={isHided}
                  onChange={(v) => setIsHided(v.target.value === 'true')}
                >
                  <option value={true}>{'True'}</option>
                  <option value={false}>{'False'}</option>
                </Input>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => save(context)}>
                {id ? 'Edit' : 'Create'}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </PageAlertContext.Consumer>
  );
}
