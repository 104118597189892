import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  FormGroup,
  Label,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardFooter,
  Table,
  ButtonGroup,
} from 'reactstrap';
import { fetchWithDelete, fetchWithGet, fetchWithPost } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import moment from 'moment';
import Datetime from 'react-datetime';
import _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import update from 'immutability-helper';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import PopUpContext from '../../components/components/PopUp/PopUpContext';
import Cookies from 'js-cookie';
import { Loader } from '../../components';
import { levelOptions } from '../../components/helpers/reportHelper';
import Select from 'react-select';
import CandidateInfo from '../candidate/CandidateInfo';
import { CSVLink, CSVDownload } from 'react-csv';

export default function Attendance(props) {
  let { path, url } = useRouteMatch();

  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <AttendanceList rootPath={url} />
        </Route>
        <Route path={`${path}/info/:id`}>
          <CandidateInfo rootPath={url} rootName={'Attendance'} from={'attendance'} />
        </Route>
        <Route path={`${path}/create`}>
          <CreateAttendanceDetails rootPath={url} />
        </Route>
        <Route path={`${path}/:id`}>
          <AttendanceDetails rootPath={url} />
        </Route>
      </Switch>
    </Router>
  );
}

function AttendanceList({ rootPath }) {
  const history = useHistory();
  const role = Cookies.get('role');
  const scope = role.split(',');

  const alertContext = useContext(PageAlertContext);

  const [isLoading, setIsLoading] = useState(false);

  const [trainees, setTrainees] = useState([]);
  const [selectedTrainees, setSelectedTrainees] = useState([]);
  const [cohorts, setCohorts] = useState([]);
  const [trainingList, setTrainingList] = useState([]);
  const [training, setTraining] = useState({
    name: { en: '' },
    startDatetime: moment(),
    endDatetime: moment(),
  });

  const tabs = ['Trainees', 'Training Group'];
  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);
    // const _trainees = await fetchWithGet('/research/getTrainees');
    // if (_trainees) {
    //   setTrainees(_trainees);
    // }
    const _attendance = await fetchWithGet('/research/getAttendanceEvent');
    if (_attendance) {
      setTrainingList(_attendance);
    }
    const cohortList = await fetchWithGet('/content/cohort');
    if (cohortList) {
      setCohorts(
        cohortList
          .filter((v) => !v.disabled)
          .map((v) => {
            return { value: v.id, label: v.en };
          })
      );
    }

    setIsLoading(false);
  };

  const [modal, setModal] = useState(false);
  const modalToggle = (v) => {
    if (v && v.id) {
      setTraining({
        ...v,
        startDatetime: moment(v.startDatetime).format('MM/DD/YYYY'),
        endDatetime: moment(v.endDatetime).format('MM/DD/YYYY'),
      });
    } else {
      setTraining({
        name: { en: '' },
        startDatetime: moment(),
        endDatetime: moment(),
      });
      fetchApi();
    }
    setModal(!modal);
  };

  const _create = async () => {
    const { startDatetime, endDatetime, name, cohort } = training;

    if (startDatetime && endDatetime && name.en && cohort.value) {
      if (startDatetime >= endDatetime) {
        alertContext.setAlert('Start date is greater than end date', 'danger');
      } else {
        const result = await fetchWithPost('/research/updateAttendanceEvent', {
          startDatetime,
          endDatetime,
          name,
          cohort: cohort.value,
          groups: selectedTrainees.map((v) => {
            return { candidate: v.candidate.id };
          }),
        });

        if (result) {
          if (result.status === 400) {
            alertContext.setAlert(result.data.message, 'danger');
          } else {
            alertContext.setAlert('Successfully updated', 'success');
          }
          modalToggle();
        }
      }
    } else {
      alertContext.setAlert('Please fill in all the blank', 'danger');
    }
  };

  const _scheduleTraining = (selectedRow) => {
    if (selectedRow.data.map((v) => trainees[v.dataIndex]).length > 0) {
      setSelectedTrainees(selectedRow.data.map((v) => trainees[v.dataIndex]));
      modalToggle();
    } else {
      alertContext.setAlert('Please select at least one candidate', 'danger');
    }
  };
  const _scheduleToExistingTraining = async (candidate, group) => {
    const record = _.find(trainingList, (v) => v.id === group);
    const result = await fetchWithPost('/research/updateAttendanceEvent', {
      ...record,
      cohort: record.cohort.id,
      groups: _.uniqBy(
        [
          { candidate: candidate.candidate.id },
          ...record.groups.map((v) => {
            return { candidate: v.candidate.id };
          }),
        ],
        'candidate'
      ),
    });

    if (result) {
      if (result.status === 400) {
        alertContext.setAlert(result.data.message, 'danger');
      } else {
        alertContext.setAlert('Successfully updated', 'success');
      }
    }
  };

  // const renderTable = () => {
  //   switch (activeTab) {
  //     case tabs[0]:
  //       return (
  //         <MUIDataTable
  //           key={tabs[0]}
  //           data={trainees.map((v, i) => [
  //             i + 1,
  //             `${v.candidate && v.candidate.surname.en} ${v.candidate && v.candidate.givenName.en}\n${
  //               (v.candidate && v.candidate.surname.zh) || ''
  //             } ${(v.candidate && v.candidate.givenName.zh) || ''}`,
  //             `${v.candidate && v.candidate.email}/${v.candidate && v.candidate.phoneNo}`,
  //             v.candidate && v.candidate.education
  //               ? v.candidate.education.level === 'Others'
  //                 ? v.candidate.education.otherLevel || '-'
  //                 : levelOptions[v.candidate.education.level] || '-'
  //               : '-',
  //           ])}
  //           columns={[
  //             '#',
  //             'Candidate',
  //             'Email/Phone',
  //             'Education Level',
  //             'Cohort',
  //             'Accepted Role',
  //             {
  //               name: 'Add to existing training',
  //               options: {
  //                 filter: false,
  //                 download: false,
  //                 customBodyRender: (value, tableMeta, updateValue) => {
  //                   const rowIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
  //                   return (
  //                     <Input
  //                       type="select"
  //                       name="select"
  //                       value={''}
  //                       onChange={(a) => _scheduleToExistingTraining(trainees[rowIndex], a.target.value)}
  //                     >
  //                       <option value={''}>{'Please select existing training'}</option>
  //                       {trainingList.map((k, i) => (
  //                         <option key={i} value={k.id}>
  //                           {k.name.en}
  //                         </option>
  //                       ))}
  //                     </Input>
  //                   );
  //                 },
  //               },
  //             },
  //           ]}
  //           options={{
  //             print: false,
  //             download: false,
  //             selectableRows: 'multiple',
  //             textLabels: {
  //               body: {
  //                 noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
  //               },
  //             },
  //             customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
  //               <Button color="primary" className="mr-4" onClick={() => _scheduleTraining(selectedRows)}>
  //                 Schedule Training
  //               </Button>
  //             ),
  //           }}
  //         />
  //       );
  //     case tabs[1]:
  //       return (
  //         <MUIDataTable
  //           key={tabs[1]}
  //           data={trainingList.map((v, i) => [
  //             i + 1,
  //             `${(v.name && v.name.en) || ''} ${(v.name && v.name.zh) || ''}`,
  //             moment(v.startDatetime).format('MM/DD/YYYY'),
  //             moment(v.endDatetime).format('MM/DD/YYYY'),
  //           ])}
  //           columns={['#', 'Training Name', 'Start Date', 'End Date']}
  //           options={{
  //             print: false,
  //             download: false,
  //             selectableRows: 'none',
  //             textLabels: {
  //               body: {
  //                 noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
  //               },
  //             },
  //             onRowClick: (rowData, rowMeta) => {
  //               history.push(`${rootPath}/${trainingList[rowMeta.dataIndex].id}`);
  //             },
  //           }}
  //         />
  //       );
  //   }
  // };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Attendance</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="mb-2 text-right">
        <Button color="primary" onClick={() => history.push(`${rootPath}/create`)}>
          Create
        </Button>
      </div>
      <MUIDataTable
        key={tabs[1]}
        data={trainingList.map((v, i) => [
          i + 1,
          `${(v.name && v.name.en) || ''} ${(v.name && v.name.zh) || ''}`,
          moment(v.createdAt).format('MM/DD/YYYY HH:mm'),
          moment(v.updatedAt).format('MM/DD/YYYY HH:mm'),
          moment(v.startDatetime).format('MM/DD/YYYY'),
          moment(v.endDatetime).format('MM/DD/YYYY'),
        ])}
        columns={['#', 'Training Name', 'Create Date Time', 'Last Updated Date Time', 'Start Date', 'End Date']}
        options={{
          print: false,
          download: false,
          selectableRows: 'none',
          textLabels: {
            body: {
              noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
            },
          },
          onRowClick: (rowData, rowMeta) => {
            history.push(`${rootPath}/${trainingList[rowMeta.dataIndex].id}`);
          },
        }}
      />
      {/*<Nav tabs>
        {tabs.map((v, i) => (
          <NavItem key={i}>
            <NavLink href="#" className={`${activeTab === v ? 'active' : ''}`} onClick={() => setActiveTab(v)}>
              {v}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      {renderTable()}
      <Modal centered={true} isOpen={modal} toggle={modalToggle}>
        <ModalHeader toggle={modalToggle}>
          <b>{training.id ? 'Edit' : 'Create'} training</b>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Cohort</Label>
            <Select
              options={cohorts}
              value={training.cohort}
              onChange={(v) =>
                setTraining({
                  ...training,
                  cohort: v,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label>Name</Label>
            <Input
              placeholder="Name"
              value={training.name.en}
              onChange={(v) =>
                setTraining({
                  ...training,
                  name: {
                    ...training.name,
                    en: v.target.value,
                  },
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label className="mb-2">Start time</Label>
            <Datetime
              value={training.startDatetime}
              timeFormat={false}
              onChange={(date) =>
                setTraining({
                  ...training,
                  startDatetime: date,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label className="mb-2">End time</Label>
            <Datetime
              value={training.endDatetime}
              timeFormat={false}
              onChange={(date) =>
                setTraining({
                  ...training,
                  endDatetime: date,
                })
              }
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={_create}>
            {training.id ? 'Save' : 'Create'}
          </Button>
        </ModalFooter>
      </Modal>
    */}
    </div>
  );
}

function AttendanceDetails({ rootPath }) {
  let { id } = useParams();
  let { url } = useRouteMatch();
  const userId = Cookies.get('userId');
  const role = Cookies.get('role');
  const scope = role.split(',');
  const history = useHistory();

  const alertContext = useContext(PageAlertContext);
  const popUpContext = useContext(PopUpContext);

  const [cohorts, setCohorts] = useState([]);
  const [training, setTraining] = useState({
    name: { en: '' },
    startDatetime: moment(),
    endDatetime: moment(),
  });
  const [dayRange, setDayRange] = useState([]);

  const [modal, setModal] = useState(false);
  const [selectedTrainees, setSelectedTrainees] = useState([]);
  const [trainees, setTrainees] = useState([]);
  const [remarks, setRemarks] = useState([]);

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    const _attendance = await fetchWithGet('/research/getAttendanceEvent');
    if (_attendance) {
      const cohortList = await fetchWithGet('/content/cohort');
      const converted = cohortList
        .filter((v) => !v.disabled)
        .map((v) => {
          return { value: v.id, label: v.en };
        });
      if (cohortList) {
        setCohorts(converted);
      }

      const record = _.find(_attendance, (v) => v.id === id);

      const dayDiff = moment(record.endDatetime).diff(moment(record.startDatetime), 'days');

      setDayRange([
        `${moment(record.startDatetime, 'YYYY-MM-DD').format('YYYY-MM-DD')} (AM)`,
        `${moment(record.startDatetime, 'YYYY-MM-DD').format('YYYY-MM-DD')} (PM)`,
        ...Array.from(Array(dayDiff).keys())
          .map((v) => [
            `${moment(record.startDatetime, 'YYYY-MM-DD')
              .add(v + 1, 'day')
              .format('YYYY-MM-DD')} (AM)`,
            `${moment(record.startDatetime, 'YYYY-MM-DD')
              .add(v + 1, 'day')
              .format('YYYY-MM-DD')} (PM)`,
          ])
          .flat(),
      ]);

      setRemarks(
        record.groups.map((v) => {
          return { candidate: v.candidate.id, remark: v.remark };
        })
      );
      setTraining({
        ...record,
        startDatetime: moment(record.startDatetime),
        endDatetime: moment(record.endDatetime),
        cohort: _.find(converted, (v) => v.value === record.cohort?.id),
        groups: record.groups.map((v) => {
          return {
            ...v,
            attendance: _.take(
              _.assign(
                Array.from(Array((dayDiff + 1) * 2).keys()).map((v) => ''),
                v.attendance
              ),
              (dayDiff + 1) * 2
            ),
          };
        }),
      });
    }
  };

  const _edit = async (newTraining) => {
    const data = newTraining || training;
    const { startDatetime, endDatetime, name, cohort } = data;

    if (startDatetime && endDatetime && name.en) {
      if (startDatetime >= endDatetime) {
        alertContext.setAlert('Start date is greater than end date', 'danger');
      } else {
        const result = await fetchWithPost('/research/updateAttendanceEvent', {
          ...data,
          cohort: cohort.value,
          groups: data.groups.map((v) => {
            return { ...v, candidate: v.candidate.id };
          }),
        });

        if (result) {
          if (result.status === 400) {
            alertContext.setAlert(result.data.message, 'danger');
          } else {
            alertContext.setAlert('Successfully updated', 'success');
            fetchApi();
          }
        }
      }
    } else {
      alertContext.setAlert('Please fill in all the blank', 'danger');
    }
  };

  const _remove = async () => {
    const confirm = await popUpContext.onOpen(
      'Are you confirm to remove this training event? All records will be removed.'
    );
    if (confirm) {
      const result = await fetchWithDelete(`/research/deleteAttendanceEvent/${id}`);
      if (result) {
        if (result.status === 400) {
          alertContext.setAlert(result.data.message, 'danger');
        } else {
          alertContext.setAlert('Successfully deleted', 'success');
          history.push(rootPath);
        }
      }
    }
  };

  const _addToExistingTraining = async () => {
    const { startDatetime, endDatetime, name, cohort } = training;

    if (startDatetime && endDatetime && name.en) {
      if (startDatetime >= endDatetime) {
        alertContext.setAlert('Start date is greater than end date', 'danger');
      } else {
        const result = await fetchWithPost('/research/updateAttendanceEvent', {
          ...training,
          cohort: cohort.value,
          groups: _.uniqBy(
            [
              ...selectedTrainees.map((v) => {
                return { candidate: traineesByCohort[v].candidate.id };
              }),
              ...training.groups.map((v) => {
                return { ...v, candidate: v.candidate.id };
              }),
            ],
            'candidate'
          ),
        });

        if (result) {
          if (result.status === 400) {
            alertContext.setAlert(result.data.message, 'danger');
          } else {
            alertContext.setAlert('Successfully updated', 'success');
            fetchApi();
            setModal(!modal);
          }
        }
      }
    } else {
      alertContext.setAlert('Please fill in all the blank', 'danger');
    }
  };

  const _saveCandidateRemark = async (candidate) => {
    const result = await fetchWithPost('/research/updateAttendanceEventRemark', {
      id: training.id,
      group: _.find(remarks, { candidate }),
    });

    if (result) {
      if (result.status === 400) {
        alertContext.setAlert(result.data.message, 'danger');
      } else {
        alertContext.setAlert('Successfully updated', 'success');
        fetchApi();
      }
    }
  };

  useEffect(() => {
    loadTrainee();
  }, [training.cohort]);
  const loadTrainee = async () => {
    if (training.cohort) {
      const _trainees = await fetchWithGet(`/research/getTrainees?cohortId=${training.cohort.value}`);

      if (_trainees) {
        setTrainees(_trainees);
      }
    } else {
      setTrainees([]);
    }
  };

  let traineesByCohort = trainees.filter((v) => !training?.groups?.find((e) => e.candidate.id === v.candidate.id));

  if (scope.includes('convenor')) {
    traineesByCohort = traineesByCohort.filter((v) => v.finalInterview?.position?.role?.convenor === userId);
  }

  return (
    <div style={{ display: 'grid' }}>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Attendance</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`${url}/${id}`}>{training.name.en || id}</Link>
        </BreadcrumbItem>
      </Breadcrumb>

      <Card>
        <CardBody>
          <FormGroup>
            <Label>Cohort</Label>
            <Select
              options={cohorts}
              value={training.cohort}
              onChange={(v) =>
                setTraining({
                  ...training,
                  cohort: v,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label>Name</Label>
            <Input
              placeholder="Name"
              value={training.name.en}
              onChange={(v) =>
                setTraining(
                  update(training, {
                    name: {
                      en: { $set: v.target.value },
                    },
                  })
                )
              }
            />
          </FormGroup>
          <FormGroup>
            <Label className="mb-2">Start time</Label>
            <Datetime
              value={training.startDatetime}
              timeFormat={false}
              onChange={(date) =>
                setTraining({
                  ...training,
                  startDatetime: date,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label className="mb-2">End time</Label>
            <Datetime
              value={training.endDatetime}
              timeFormat={false}
              onChange={(date) =>
                setTraining({
                  ...training,
                  endDatetime: date,
                })
              }
            />
          </FormGroup>
        </CardBody>
        <CardFooter className="d-flex justify-content-between">
          <Button color="primary" outline onClick={() => _edit()}>
            Save
          </Button>
          <Button color="danger" outline onClick={_remove}>
            Remove
          </Button>
        </CardFooter>
      </Card>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <Button color="primary" className="mb-2 text-right" outline onClick={() => setModal(!modal)}>
              Add trainees
            </Button>
            <CSVLink
              filename={training.name.en}
              data={
                training?.groups?.map((v) => {
                  const obj = {
                    candidate: `${v.candidate && v.candidate.surname.en} ${v.candidate && v.candidate.givenName.en}`,
                    preferredName: `${v.candidate && v.candidate.preferredName}`,
                    remark:
                      v?.remark
                        ?.replace(',', '')
                        ?.replace('"', '')
                        ?.replace(/(?:\\[rn])+/g, '') || '',
                  };
                  dayRange.map((d, di) => {
                    obj[d] = v.attendance[di];
                  });

                  return obj;
                }) || []
              }
              headers={[
                {
                  label: 'Candidate',
                  key: 'candidate',
                },
                {
                  label: 'Preferred Name',
                  key: 'preferredName',
                },
                {
                  label: 'Remark',
                  key: 'remark',
                },
                ...dayRange.map((v, vi) => {
                  return {
                    label: v,
                    key: v,
                  };
                }),
              ]}
            >
              Export attendance
            </CSVLink>
          </div>

          <div style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
            <Table>
              <thead>
                <tr>
                  <td>Candidate</td>
                  <td>Preferred Name</td>
                  {dayRange.map((v, vi) => (
                    <td key={vi} align="center">
                      {v}
                    </td>
                  ))}
                  <td>Remark</td>
                  <td>Remove</td>
                </tr>
              </thead>
              <tbody>
                {training?.groups?.map((v, vi) => (
                  <tr key={vi}>
                    <td>
                      <a href={`${rootPath}/info/${v.candidate.id}`}>
                        {`${v.candidate && v.candidate.surname.en} ${v.candidate && v.candidate.givenName.en}\n${
                          (v.candidate && v.candidate.surname.zh) || ''
                        } ${(v.candidate && v.candidate.givenName.zh) || ''}`}
                      </a>
                    </td>
                    <td>{`${v.candidate && v.candidate.preferredName}`}</td>
                    {v.attendance.map((a, ai) => {
                      return (
                        <td key={ai} align="center">
                          <ButtonGroup block={true}>
                            <Button
                              color="primary"
                              outline={!(a === 'present')}
                              onClick={async () => {
                                _edit(
                                  update(training, {
                                    groups: {
                                      [vi]: {
                                        attendance: {
                                          [ai]: { $set: 'present' },
                                        },
                                      },
                                    },
                                  })
                                );
                              }}
                            >
                              Present
                            </Button>
                            <Button
                              color="primary"
                              outline={!(a === 'late')}
                              onClick={async () => {
                                _edit(
                                  update(training, {
                                    groups: {
                                      [vi]: {
                                        attendance: {
                                          [ai]: { $set: 'late' },
                                        },
                                      },
                                    },
                                  })
                                );
                              }}
                            >
                              Late
                            </Button>
                            <Button
                              color="primary"
                              outline={!(a === 'absent')}
                              onClick={() => {
                                _edit(
                                  update(training, {
                                    groups: {
                                      [vi]: {
                                        attendance: {
                                          [ai]: { $set: 'absent' },
                                        },
                                      },
                                    },
                                  })
                                );
                              }}
                            >
                              Absent
                            </Button>
                          </ButtonGroup>
                        </td>
                      );
                    })}
                    <td align="center">
                      <FormGroup>
                        <Input
                          style={{ width: 300 }}
                          type="textarea"
                          name="remark"
                          id="remark"
                          value={_.find(remarks, { candidate: v.candidate.id }).remark}
                          placeholder={'Remark'}
                          className="mb-2"
                          onChange={(e) => {
                            const index = _.findIndex(remarks, { candidate: v.candidate.id });
                            setRemarks(
                              update(remarks, {
                                [index]: {
                                  remark: { $set: e.target.value },
                                },
                              })
                            );
                          }}
                        />
                        <Button onClick={() => _saveCandidateRemark(v.candidate.id)} block outline>
                          Save
                        </Button>
                      </FormGroup>
                    </td>
                    <td>
                      <Button
                        color="danger"
                        outline
                        onClick={async () => {
                          const confirm = await popUpContext.onOpen(
                            'Are you confirm to remove this candidate? All records will be removed.'
                          );
                          if (confirm) {
                            _edit(
                              update(training, {
                                groups: {
                                  $splice: [[vi, 1]],
                                },
                              })
                            );
                          }
                        }}
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <Modal centered={true} isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <ModalBody>
          <MUIDataTable
            data={traineesByCohort.map((v, i) => [
              i + 1,
              `${v.candidate && v.candidate.surname.en} ${v.candidate && v.candidate.givenName.en}\n${
                (v.candidate && v.candidate.surname.zh) || ''
              } ${(v.candidate && v.candidate.givenName.zh) || ''}`,
              `${v.candidate && v.candidate.email}/${v.candidate && v.candidate.phoneNo}`,
              v.candidate && v.candidate.education
                ? v.candidate.education.level === 'Others'
                  ? v.candidate.education.otherLevel || '-'
                  : levelOptions[v.candidate.education.level] || '-'
                : '-',
              v.finalInterview && v.finalInterview?.application?.cohort && v.finalInterview?.application?.cohort.en,
              v.finalInterview && v.finalInterview?.employer && v.finalInterview?.employer?.parentId?.givenName?.en,
              v.finalInterview && v.finalInterview?.position && v.finalInterview?.position?.role.en,
            ])}
            columns={['#', 'Candidate', 'Email/Phone', 'Education Level', 'Cohort', 'Convenor', 'Accepted Role']}
            options={{
              print: false,
              download: false,
              selectableRows: 'multiple',
              textLabels: {
                body: {
                  noMatch: 'Sorry, no matching records found',
                },
              },
              onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
                setSelectedTrainees(rowsSelected);
              },
              rowsSelected: selectedTrainees,
              customToolbarSelect: () => {},
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={_addToExistingTraining}>
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

function CreateAttendanceDetails({ rootPath }) {
  let { id } = useParams();
  let { url } = useRouteMatch();
  const userId = Cookies.get('userId');
  const role = Cookies.get('role');
  const scope = role.split(',');
  const history = useHistory();

  const alertContext = useContext(PageAlertContext);

  const [isLoading, setIsLoading] = useState(false);

  const [trainees, setTrainees] = useState([]);
  const [selectedTrainees, setSelectedTrainees] = useState([]);
  const [cohorts, setCohorts] = useState([]);
  const [trainingList, setTrainingList] = useState([]);
  const [training, setTraining] = useState({
    name: { en: '' },
    startDatetime: moment(),
    endDatetime: moment(),
  });

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);
    const _attendance = await fetchWithGet('/research/getAttendanceEvent');
    if (_attendance) {
      setTrainingList(_attendance);
    }
    const cohortList = await fetchWithGet('/content/cohort');
    if (cohortList) {
      setCohorts(
        cohortList
          .filter((v) => !v.disabled)
          .map((v) => {
            return { value: v.id, label: v.en };
          })
      );
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadTrainee();
  }, [training.cohort]);
  const loadTrainee = async () => {
    setIsLoading(true);
    if (training.cohort) {
      const _trainees = await fetchWithGet(`/research/getTrainees?cohortId=${training.cohort.value}`);

      if (_trainees) {
        setTrainees(_trainees);
      }
    } else {
      setTrainees([]);
    }
    setIsLoading(false);
  };

  const _create = async () => {
    const { startDatetime, endDatetime, name, cohort } = training;

    if (startDatetime && endDatetime && name.en && cohort.value) {
      if (startDatetime >= endDatetime) {
        alertContext.setAlert('Start date is greater than end date', 'danger');
      } else {
        const result = await fetchWithPost('/research/updateAttendanceEvent', {
          startDatetime,
          endDatetime,
          name,
          cohort: cohort.value,
          groups: selectedTrainees.map((v) => {
            return { candidate: traineesByCohort[v].candidate.id };
          }),
        });

        if (result) {
          if (result.status === 400) {
            alertContext.setAlert(result.data.message, 'danger');
          } else {
            alertContext.setAlert('Successfully updated', 'success');
            history.push(rootPath);
          }
        }
      }
    } else {
      alertContext.setAlert('Please fill in all the blank', 'danger');
    }
  };

  // const _scheduleTraining = (selectedRow) => {
  //   if (selectedRow.data.map((v) => trainees[v.dataIndex]).length > 0) {
  //     setSelectedTrainees(selectedRow.data.map((v) => trainees[v.dataIndex]));
  //   } else {
  //     alertContext.setAlert('Please select at least one candidate', 'danger');
  //   }
  // };
  // const _scheduleToExistingTraining = async (candidate, group) => {
  //   const record = _.find(trainingList, (v) => v.id === group);
  //   const result = await fetchWithPost('/research/updateAttendanceEvent', {
  //     ...record,
  //     cohort: record.cohort.id,
  //     groups: _.uniqBy(
  //       [
  //         { candidate: candidate.candidate.id },
  //         ...record.groups.map((v) => {
  //           return { candidate: v.candidate.id };
  //         }),
  //       ],
  //       'candidate'
  //     ),
  //   });
  //   if (result) {
  //     if (result.status === 400) {
  //       alertContext.setAlert(result.data.message, 'danger');
  //     } else {
  //       alertContext.setAlert('Successfully updated', 'success');
  //     }
  //   }
  // };
  let traineesByCohort = trainees.filter((v) => v.finalInterview?.application?.cohort?.id === training?.cohort?.value);

  if (scope.includes('convenor')) {
    traineesByCohort = traineesByCohort.filter((v) => v.finalInterview?.position?.role?.convenor === userId);
  }

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Attendance</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`${url}/create`}>Create</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <Card>
        <CardBody>
          <FormGroup>
            <Label>Cohort</Label>
            <Select
              options={cohorts}
              value={training.cohort}
              onChange={(v) =>
                setTraining({
                  ...training,
                  cohort: v,
                })
              }
            />
          </FormGroup>
          {training.cohort ? (
            <>
              <FormGroup>
                <Label>Training Name</Label>
                <Input
                  placeholder="Training Name"
                  value={training.name.en}
                  onChange={(v) =>
                    setTraining({
                      ...training,
                      name: {
                        ...training.name,
                        en: v.target.value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label className="mb-2">Start time</Label>
                <Datetime
                  value={training.startDatetime}
                  timeFormat={false}
                  onChange={(date) =>
                    setTraining({
                      ...training,
                      startDatetime: date,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label className="mb-2">End time</Label>
                <Datetime
                  value={training.endDatetime}
                  timeFormat={false}
                  onChange={(date) =>
                    setTraining({
                      ...training,
                      endDatetime: date,
                    })
                  }
                />
              </FormGroup>
              <MUIDataTable
                data={traineesByCohort.map((v, i) => [
                  i + 1,
                  `${v.candidate && v.candidate.surname.en} ${v.candidate && v.candidate.givenName.en}\n${
                    (v.candidate && v.candidate.surname.zh) || ''
                  } ${(v.candidate && v.candidate.givenName.zh) || ''}`,
                  `${v.candidate && v.candidate.email}/${v.candidate && v.candidate.phoneNo}`,
                  v.candidate && v.candidate.education
                    ? v.candidate.education.level === 'Others'
                      ? v.candidate.education.otherLevel || '-'
                      : levelOptions[v.candidate.education.level] || '-'
                    : '-',
                  v.finalInterview && v.finalInterview?.application?.cohort && v.finalInterview?.application?.cohort.en,
                  v.finalInterview && v.finalInterview?.employer && v.finalInterview?.employer?.parentId?.givenName.en,
                  v.finalInterview && v.finalInterview?.position && v.finalInterview?.position?.role.en,
                ])}
                columns={['#', 'Candidate', 'Email/Phone', 'Education Level', 'Cohort', 'Convenor', 'Accepted Role']}
                options={{
                  print: false,
                  download: false,
                  selectableRows: 'multiple',
                  textLabels: {
                    body: {
                      noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
                    },
                  },
                  onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
                    setSelectedTrainees(rowsSelected);
                  },
                  rowsSelected: selectedTrainees,
                  customToolbarSelect: () => {},
                }}
              />
            </>
          ) : (
            <></>
          )}
        </CardBody>
        {training.cohort ? (
          <CardFooter>
            <Button color="primary" onClick={_create}>
              Create
            </Button>
          </CardFooter>
        ) : (
          <></>
        )}
      </Card>
    </div>
  );
}
