import React, { useState, useEffect } from 'react';
import { Button, Card, CardHeader, CardBody } from 'reactstrap';
import { fetchWithGet } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { CSVLink, CSVDownload } from 'react-csv';
import Cookies from 'js-cookie';
import { Loader } from '../../components';
import moment from 'moment';
import {
  covidAnswer,
  levelOptions,
  videoHeader,
  cohortHeader,
  candidatesHeader,
  vaskStatusConvert,
  videoInterviewStatusConvert,
  groupInterviewStatusConvert,
  finalInterviewStatusConvert,
  groupInterviewResultConvert,
} from '../../components/helpers/reportHelper';

export default function Report() {
  let { path, url } = useRouteMatch();
  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <ReportContent rootPath={url} />
        </Route>
      </Switch>
    </Router>
  );
}

function ReportContent({ rootPath }) {
  const role = Cookies.get('role');
  const scope = role.split(',');

  const [isLoading, setIsLoading] = useState(false);
  const [isQualtricLoading, setIsQualtricLoading] = useState(false);

  const [allCandidate, setAllCandidate] = useState([]);

  const [cohort1, setCohort1] = useState([]);
  const [cohort2, setCohort2] = useState([]);
  const [cohort3, setCohort3] = useState([]);
  const [cohort4, setCohort4] = useState([]);
  const [qualtric, setQualtric] = useState([]);
  const [finalQualtric, setFinalQualtric] = useState([]);

  const cohortConvert = (v) => {
    return {
      id: v.candidate.userId,
      cohort: v.cohort && v.cohort.en,
      givenNameEn: v.candidate.givenName.en,
      surnameEn: v.candidate.surname.en,
      givenNameZh: v.candidate.givenName.zh || '',
      surnameZh: v.candidate.surname.zh || '',
      preferredName: v.candidate.preferredName,
      gender: v.candidate.gender,
      phoneNo: v.candidate.phoneNo,
      email: v.candidate.email,
      resume: v.candidate.resume,
      portfolio: v.candidate.portfolio,
      createdAt: moment(v.candidate.createdAt).format('YYYY-MM-DD'),
      jobOffer1:
        v.offer &&
        v.offer[0] &&
        `${v.offer[0].finalInterview.position.role.parentId.en}/${v.offer[0].finalInterview.position.role.en}`,
      jobEmployer1: v.offer && v.offer[0] && `${v.offer[0].finalInterview.employer.givenName.en}`,
      jobStatus1: v.offer && v.offer[0] && `${_.startCase(v.offer[0].status)}ed`,
      jobOffer2:
        v.offer &&
        v.offer[1] &&
        `${v.offer[1].finalInterview.position.role.parentId.en}/${v.offer[1].finalInterview.position.role.en}`,
      jobEmployer2: v.offer && v.offer[1] && `${v.offer[1].finalInterview.employer.givenName.en}`,
      jobStatus2: v.offer && v.offer[1] && `${_.startCase(v.offer[1].status)}ed`,
      jobOffer3:
        v.offer &&
        v.offer[2] &&
        `${v.offer[2].finalInterview.position.role.parentId.en}/${v.offer[2].finalInterview.position.role.en}`,
      jobEmployer3: v.offer && v.offer[2] && `${v.offer[2].finalInterview.employer.givenName.en}`,
      jobStatus3: v.offer && v.offer[2] && `${_.startCase(v.offer[2].status)}ed`,
      jobOffer4:
        v.offer &&
        v.offer[3] &&
        `${v.offer[3].finalInterview.position.role.parentId.en}/${v.offer[3].finalInterview.position.role.en}`,
      jobEmployer4: v.offer && v.offer[3] && `${v.offer[3].finalInterview.employer.givenName.en}`,
      jobStatus4: v.offer && v.offer[3] && `${_.startCase(v.offer[3].status)}ed`,
      roleConvenor1: v.appliedRoles && v.appliedRoles[0] && v.appliedRoles[0].convenor.givenName.en,
      role1: v.appliedRoles && v.appliedRoles[0] && `${v.appliedRoles[0].parentId.en} - ${v.appliedRoles[0].en}`,
      roleConvenor2: v.appliedRoles && v.appliedRoles[1] && v.appliedRoles[0].convenor.givenName.en,
      role2: v.appliedRoles && v.appliedRoles[1] && `${v.appliedRoles[1].parentId.en} - ${v.appliedRoles[1].en}`,
      level:
        v.candidate.education && v.candidate.education.level
          ? v.candidate.education.level === 'Others'
            ? v.candidate.education.otherLevel || ''
            : levelOptions[v.candidate.education.level] || ''
          : '',
      location: v.candidate.education
        ? v.candidate.education.otherLocation || (v.candidate.education.location && v.candidate.education.location.en)
        : '',
      institution: v.candidate.education
        ? v.candidate.education.otherInstitution ||
          (v.candidate.education.institution && v.candidate.education.institution.en)
        : '',
      programme: v.candidate.education && v.candidate.education.programmeName,
      areaOfStudy: v.candidate.education
        ? v.candidate.education.otherAreaOfStudy ||
          (v.candidate.education.areaOfStudy && v.candidate.education.areaOfStudy.en)
        : '',
      studyDuration:
        v.candidate.education &&
        `${v.candidate.education.startYear}/${v.candidate.education.startMonth} - ${
          _.isInteger(v.candidate.education.gradYear)
            ? `${v.candidate.education.gradYear}/${v.candidate.education.gradMonth}`
            : `${v.candidate.education.gradYear}`
        }`,
      paidExpCount: v.vask && v.vask.paidExp && v.vask.paidExp.length,
      employmentCount:
        v.vask && v.vask.paidExp && v.vask.paidExp.filter((a) => a.type && a.type.en === 'Employment').length,
      entrepreneurCount:
        v.vask && v.vask.paidExp && v.vask.paidExp.filter((a) => a.type && a.type.en === 'Entrepreneur').length,
      otherCount:
        v.vask &&
        v.vask.paidExp &&
        v.vask.paidExp.filter((a) => a.type && a.type.en !== 'Employment' && a.type.en !== 'Entrepreneur').length,
      pe1Type: v.vask && v.vask.paidExp[0] && v.vask.paidExp[0].type && v.vask.paidExp[0].type.en,
      pe1Organization: v.vask && v.vask.paidExp[0] && v.vask.paidExp[0].organization,
      pe1Role: v.vask && v.vask.paidExp[0] && v.vask.paidExp[0].role,
      pe1FromTo:
        v.vask &&
        v.vask.paidExp[0] &&
        `${moment(v.vask.paidExp[0].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[0].to).format('YYYY-MM-DD')}`,
      pe2Type: v.vask && v.vask.paidExp[1] && v.vask.paidExp[1].type && v.vask.paidExp[1].type.en,
      pe2Organization: v.vask && v.vask.paidExp[1] && v.vask.paidExp[1].organization,
      pe2Role: v.vask && v.vask.paidExp[1] && v.vask.paidExp[1].role,
      pe2FromTo:
        v.vask &&
        v.vask.paidExp[1] &&
        `${moment(v.vask.paidExp[1].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[1].to).format('YYYY-MM-DD')}`,
      pe3Type: v.vask && v.vask.paidExp[2] && v.vask.paidExp[2].type && v.vask.paidExp[2].type.en,
      pe3Organization: v.vask && v.vask.paidExp[2] && v.vask.paidExp[2].organization,
      pe3Role: v.vask && v.vask.paidExp[2] && v.vask.paidExp[2].role,
      pe3FromTo:
        v.vask &&
        v.vask.paidExp[2] &&
        `${moment(v.vask.paidExp[2].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[2].to).format('YYYY-MM-DD')}`,
      pe4Type: v.vask && v.vask.paidExp[3] && v.vask.paidExp[3].type && v.vask.paidExp[3].type.en,
      pe4Organization: v.vask && v.vask.paidExp[3] && v.vask.paidExp[3].organization,
      pe4Role: v.vask && v.vask.paidExp[3] && v.vask.paidExp[3].role,
      pe4FromTo:
        v.vask &&
        v.vask.paidExp[3] &&
        `${moment(v.vask.paidExp[3].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[3].to).format('YYYY-MM-DD')}`,
      pe5Type: v.vask && v.vask.paidExp[4] && v.vask.paidExp[4].type && v.vask.paidExp[4].type.en,
      pe5Organization: v.vask && v.vask.paidExp[4] && v.vask.paidExp[4].organization,
      pe5Role: v.vask && v.vask.paidExp[4] && v.vask.paidExp[4].role,
      pe5FromTo:
        v.vask &&
        v.vask.paidExp[4] &&
        `${moment(v.vask.paidExp[4].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[4].to).format('YYYY-MM-DD')}`,
      nonPaidExpCount: v.vask && v.vask.unpaidExp && v.vask.unpaidExp.length,
      voluntaryCount:
        v.vask && v.vask.unpaidExp && v.vask.unpaidExp.filter((a) => a.type && a.type.en === 'Voluntary').length,
      vocationalCount:
        v.vask &&
        v.vask.unpaidExp &&
        v.vask.unpaidExp.filter((a) => a.type && a.type.en === 'Vocational and Educational Training / Work Exposure')
          .length,
      seriousCount:
        v.vask &&
        v.vask.unpaidExp &&
        v.vask.unpaidExp.filter((a) => a.type && a.type.en === 'Serious Leisure or Interest').length,
      domesticCount:
        v.vask &&
        v.vask.unpaidExp &&
        v.vask.unpaidExp.filter((a) => a.type && a.type.en === 'Domestic and/or Neighborhood Provisioning').length,
      npe1Type: v.vask && v.vask.unpaidExp[0] && v.vask.unpaidExp[0].type && v.vask.unpaidExp[0].type.en,
      npe1Organization: v.vask && v.vask.unpaidExp[0] && v.vask.unpaidExp[0].organization,
      npe1Role: v.vask && v.vask.unpaidExp[0] && v.vask.unpaidExp[0].role,
      npe1FromTo:
        v.vask &&
        v.vask.unpaidExp[0] &&
        `${moment(v.vask.unpaidExp[0].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[0].to).format(
          'YYYY-MM-DD'
        )}`,
      npe2Type: v.vask && v.vask.unpaidExp[1] && v.vask.unpaidExp[1].type && v.vask.unpaidExp[1].type.en,
      npe2Organization: v.vask && v.vask.unpaidExp[1] && v.vask.unpaidExp[1].organization,
      npe2Role: v.vask && v.vask.unpaidExp[1] && v.vask.unpaidExp[1].role,
      npe2FromTo:
        v.vask &&
        v.vask.unpaidExp[1] &&
        `${moment(v.vask.unpaidExp[1].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[1].to).format(
          'YYYY-MM-DD'
        )}`,
      npe3Type: v.vask && v.vask.unpaidExp[2] && v.vask.unpaidExp[2].type && v.vask.unpaidExp[2].type.en,
      npe3Organization: v.vask && v.vask.unpaidExp[2] && v.vask.unpaidExp[2].organization,
      npe3Role: v.vask && v.vask.unpaidExp[2] && v.vask.unpaidExp[2].role,
      npe3FromTo:
        v.vask &&
        v.vask.unpaidExp[2] &&
        `${moment(v.vask.unpaidExp[2].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[2].to).format(
          'YYYY-MM-DD'
        )}`,
      npe4Type: v.vask && v.vask.unpaidExp[3] && v.vask.unpaidExp[3].type && v.vask.unpaidExp[3].type.en,
      npe4Organization: v.vask && v.vask.unpaidExp[3] && v.vask.unpaidExp[3].organization,
      npe4Role: v.vask && v.vask.unpaidExp[3] && v.vask.unpaidExp[3].role,
      npe4FromTo:
        v.vask &&
        v.vask.unpaidExp[3] &&
        `${moment(v.vask.unpaidExp[3].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[3].to).format(
          'YYYY-MM-DD'
        )}`,
      npe5Type: v.vask && v.vask.unpaidExp[4] && v.vask.unpaidExp[4].type && v.vask.unpaidExp[4].type.en,
      npe5Organization: v.vask && v.vask.unpaidExp[4] && v.vask.unpaidExp[4].organization,
      npe5Role: v.vask && v.vask.unpaidExp[4] && v.vask.unpaidExp[4].role,
      npe5FromTo:
        v.vask &&
        v.vask.unpaidExp[4] &&
        `${moment(v.vask.unpaidExp[4].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[4].to).format(
          'YYYY-MM-DD'
        )}`,
      V1: v.vask && v.vask.values[0] && v.vask.values[0].en,
      V2: v.vask && v.vask.values[1] && v.vask.values[1].en,
      V3: v.vask && v.vask.values[2] && v.vask.values[2].en,
      A1: v.vask && v.vask.attitudes[0] && v.vask.attitudes[0].en,
      A2: v.vask && v.vask.attitudes[1] && v.vask.attitudes[1].en,
      A3: v.vask && v.vask.attitudes[2] && v.vask.attitudes[2].en,
      S1: v.vask && v.vask.skills[0] && v.vask.skills[0].en,
      S2: v.vask && v.vask.skills[1] && v.vask.skills[1].en,
      S3: v.vask && v.vask.skills[2] && v.vask.skills[2].en,
      K1: v.vask && v.vask.knowledge[0] && v.vask.knowledge[0].en,
      K2: v.vask && v.vask.knowledge[1] && v.vask.knowledge[1].en,
      K3: v.vask && v.vask.knowledge[2] && v.vask.knowledge[2].en,
    };
  };

  const videoConvert = (v) => {
    return {
      id: v.candidate.userId,
      cohort: v.cohort && v.cohort.en,
      givenNameEn: v.candidate.givenName.en,
      surnameEn: v.candidate.surname.en,
      givenNameZh: v.candidate.givenName.zh || '',
      surnameZh: v.candidate.surname.zh || '',
      preferredName: v.candidate.preferredName,
      gender: v.candidate.gender,
      phoneNo: v.candidate.phoneNo,
      email: v.candidate.email,
      resume: v.candidate.resume,
      portfolio: v.candidate.portfolio,
      createdAt: moment(v.candidate.createdAt).format('YYYY-MM-DD'),
      adaptability: v.videoInterview && v.videoInterview.result && v.videoInterview.result?.adaptability?.value,
      composure: v.videoInterview && v.videoInterview.result && v.videoInterview.result?.composure?.value,
      enthusiasm: v.videoInterview && v.videoInterview.result && v.videoInterview.result?.enthusiasm?.value,
      interpersonal:
        v.videoInterview &&
        v.videoInterview.result &&
        v.videoInterview.result?.interpersonal_relationship_communication?.value,
      selfConfidence: v.videoInterview && v.videoInterview.result && v.videoInterview.result?.self_confidence?.value,
      task: v.videoInterview && v.videoInterview.result && v.videoInterview.result?.task_performance?.value,
    };
  };

  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/dashboard/getStageCandidate', { stage: 'all' });
    if (result) {
      const convert = [];
      const cohort1Data = [];
      const cohort2Data = [];
      const cohort3Data = [];
      const cohort4Data = [];
      result.forEach((e) => {
        if (e.applications.length > 0) {
          e.applications.forEach((v) => {
            convert.push({ ...{ ...v, applicationCreatedAt: v.createdAt, applicationUpdatedAt: v.updatedAt }, ...e });
            if (v.cohort.order === 1) {
              cohort1Data.push(v);
            }
            if (v.cohort.order === 2) {
              cohort2Data.push(v);
            }
            if (v.cohort.order === 3) {
              cohort3Data.push(v);
            }
            if (v.cohort.order === 4) {
              cohort4Data.push(v);
            }
          });
        } else {
          convert.push(e);
        }
      });

      setAllCandidate(convert);
      setCohort1(cohort1Data);
      setCohort2(cohort2Data);
      setCohort3(cohort3Data);
      setCohort4(cohort4Data);
    }
    setIsLoading(false);
  };

  const fetchQualtric = async () => {
    setIsQualtricLoading(true);
    const result = await fetchWithGet('/report/getQualtricsSurvey');
    const finalSurveyResult = await fetchWithGet('/research/getQualtricsSurvey/SV_bJyhSMHQMZGudJc');
    if (result) {
      setQualtric(result);
      setFinalQualtric(finalSurveyResult);
    }
    setIsQualtricLoading(false);
  };

  useEffect(() => {
    fetchApi();
    fetchQualtric();
  }, []);

  return (
    <>
      {scope.includes('admin') || scope.includes('researcher') ? (
        <Card>
          <CardHeader>
            <b>All Candidates Full Export</b>
          </CardHeader>
          {isLoading ? (
            <CardBody>
              <Loader small={true} />
            </CardBody>
          ) : (
            <CardBody>
              <CSVLink
                filename="Candidates-Full-Export.csv"
                data={allCandidate.map((v) => {
                  return {
                    id: v.userId,
                    cohort: v.cohort && v.cohort.en,
                    roleConvenor1: v.appliedRoles && v.appliedRoles[0] && v.appliedRoles[0].convenor.givenName.en,
                    roleCohort1: v.appliedRoles && v.appliedRoles[0] && v.appliedRoles[0].cohort.toString(),
                    role1:
                      v.appliedRoles &&
                      v.appliedRoles[0] &&
                      `${v.appliedRoles[0].parentId.en} - ${v.appliedRoles[0].en}`,
                    roleConvenor2: v.appliedRoles && v.appliedRoles[1] && v.appliedRoles[1].convenor.givenName.en,
                    roleCohort2: v.appliedRoles && v.appliedRoles[1] && v.appliedRoles[1].cohort.toString(),
                    role2:
                      v.appliedRoles &&
                      v.appliedRoles[1] &&
                      `${v.appliedRoles[1].parentId.en} - ${v.appliedRoles[1].en}`,
                    givenNameEn: v.givenName.en,
                    surnameEn: v.surname.en,
                    givenNameZh: v.givenName.zh || '',
                    surnameZh: v.surname.zh || '',
                    preferredName: v.preferredName,
                    gender: v.gender,
                    phoneNo: v.phoneNo,
                    email: v.email,
                    channel: v.channel,
                    resume: v.resume,
                    remark:
                      v.remark
                        ?.replace(',', '')
                        ?.replace('"', '')
                        ?.replace(/(?:\\[rn])+/g, '') || '',
                    reviewed: v.reviewed,
                    toCohort3: v?.toCohort3,
                    cohort3Group: v?.cohort3Group,
                    cohort3GroupFinal: v?.cohort3GroupFinal,
                    withdrawn: v?.withdrawn,
                    portfolio: v.portfolio,
                    createdAt: moment(v.createdAt).format('YYYY-MM-DD'),
                    updatedAt: moment(v.updatedAt).format('YYYY-MM-DD HH:mm'),
                    vask: vaskStatusConvert[v?.vask?.status] || '',
                    vaskUpdatedAt: v.vask ? moment(v.vask.updatedAt).format('YYYY-MM-DD HH:mm') : '',
                    videoStatus: videoInterviewStatusConvert[v?.videoInterview?.status] || '',
                    videoUpdatedAt: v.videoInterview
                      ? moment(v.videoInterview.updatedAt).format('YYYY-MM-DD  HH:mm')
                      : '',
                    groupStatus: groupInterviewStatusConvert[v?.groupInterview?.status] || '',
                    groupUpdatedAt: v.groupInterview
                      ? moment(v.groupInterview.updatedAt).format('YYYY-MM-DD  HH:mm')
                      : '',
                    groupInterviewResult: v.groupInterview
                      ? groupInterviewResultConvert[v.groupInterview.interviewer.status]
                      : '',
                    finalStatus: v.finalInterview && v.finalInterview.length,
                    offerStatus: v.offer && v.offer.length,
                    jobOffer1:
                      v.offer &&
                      v.offer[0] &&
                      `${v.offer[0].finalInterview.position.role.parentId.en}/${v.offer[0].finalInterview.position.role.en}`,
                    jobEmployer1: v.offer && v.offer[0] && `${v.offer[0].finalInterview.employer.givenName.en}`,
                    jobStatus1: v.offer && v.offer[0] && `${_.startCase(v.offer[0].status)}ed`,
                    jobOffer2:
                      v.offer &&
                      v.offer[1] &&
                      `${v.offer[1].finalInterview.position.role.parentId.en}/${v.offer[1].finalInterview.position.role.en}`,
                    jobEmployer2: v.offer && v.offer[1] && `${v.offer[1].finalInterview.employer.givenName.en}`,
                    jobStatus2: v.offer && v.offer[1] && `${_.startCase(v.offer[1].status)}ed`,
                    jobOffer3:
                      v.offer &&
                      v.offer[2] &&
                      `${v.offer[2].finalInterview.position.role.parentId.en}/${v.offer[2].finalInterview.position.role.en}`,
                    jobEmployer3: v.offer && v.offer[2] && `${v.offer[2].finalInterview.employer.givenName.en}`,
                    jobStatus3: v.offer && v.offer[2] && `${_.startCase(v.offer[2].status)}ed`,
                    jobOffer4:
                      v.offer &&
                      v.offer[3] &&
                      `${v.offer[3].finalInterview.position.role.parentId.en}/${v.offer[3].finalInterview.position.role.en}`,
                    jobEmployer4: v.offer && v.offer[3] && `${v.offer[3].finalInterview.employer.givenName.en}`,
                    jobStatus4: v.offer && v.offer[3] && `${_.startCase(v.offer[3].status)}ed`,
                    level:
                      v.education && v.education.level
                        ? v.education.level === 'Others'
                          ? v.education.otherLevel || ''
                          : levelOptions[v.education.level] || ''
                        : '',
                    location: v.education
                      ? v.education.otherLocation || (v.education.location && v.education.location.en)
                      : '',
                    institution: v.education
                      ? v.education.otherInstitution || (v.education.institution && v.education.institution.en)
                      : '',
                    startMonth: v.education && v.education.startMonth,
                    startYear: v.education && v.education.startYear,
                    gradMonth: v.education && v.education.gradMonth,
                    gradYear: v.education && v.education.gradYear,
                    areaOfStudy: v.education
                      ? v.education.otherAreaOfStudy || (v.education.areaOfStudy && v.education.areaOfStudy.en)
                      : '',
                    programme: v.education && v.education.programmeName,
                  };
                })}
                headers={candidatesHeader}
              >
                {'Export'}
              </CSVLink>
            </CardBody>
          )}
        </Card>
      ) : null}

      {scope.includes('admin') || scope.includes('researcher') ? (
        <Card>
          <CardHeader>
            <b>VASK-Competency</b>
          </CardHeader>
          {isLoading ? (
            <CardBody>
              <Loader small={true} />
            </CardBody>
          ) : (
            <CardBody>
              <CSVLink filename="VASK-Competency-Cohort-1.csv" data={cohort1.map(cohortConvert)} headers={cohortHeader}>
                {'Cohort 1'}
              </CSVLink>
              |
              <CSVLink filename="VASK-Competency-Cohort-2.csv" data={cohort2.map(cohortConvert)} headers={cohortHeader}>
                {'Cohort 2'}
              </CSVLink>
              |
              <CSVLink filename="VASK-Competency-Cohort-3" data={cohort3.map(cohortConvert)} headers={cohortHeader}>
                {'Cohort 3'}
              </CSVLink>
              {/* |
              <CSVLink filename="VASK-Competency-Cohort-4" data={cohort4.map(cohortConvert)} headers={cohortHeader}>
                {'Cohort 3 Group B'}
              </CSVLink> */}
            </CardBody>
          )}
        </Card>
      ) : null}

      {scope.includes('admin') || scope.includes('researcher') ? (
        <Card>
          <CardHeader>
            <b>Video Analysis</b>
          </CardHeader>
          {isLoading ? (
            <CardBody>
              <Loader small={true} />
            </CardBody>
          ) : (
            <CardBody>
              <CSVLink filename="Video-Analysis-Cohort-1.csv" data={cohort1.map(videoConvert)} headers={videoHeader}>
                {'Cohort 1'}
              </CSVLink>
              |
              <CSVLink filename="Video-Analysis-Cohort-2.csv" data={cohort2.map(videoConvert)} headers={videoHeader}>
                {'Cohort 2'}
              </CSVLink>
              |
              <CSVLink filename="Video-Analysis-Cohort-3.csv" data={cohort3.map(videoConvert)} headers={videoHeader}>
                {'Cohort 3'}
              </CSVLink>
              {/* |
              <CSVLink filename="Video-Analysis-Cohort-4.csv" data={cohort4.map(videoConvert)} headers={videoHeader}>
                {'Cohort 3 Group B'}
              </CSVLink> */}
            </CardBody>
          )}
        </Card>
      ) : null}

      {scope.includes('admin') || scope.includes('researcher') ? (
        <Card>
          <CardHeader>
            <b>Qualtric Report</b>
          </CardHeader>
          {isQualtricLoading ? (
            <CardBody>
              <Loader small={true} />
            </CardBody>
          ) : (
            <CardBody>
              <CSVLink filename="Indicator Questionnaire.csv" data={qualtric}>
                {'Indicator Questionnaire'}
              </CSVLink>
              |
              <CSVLink filename="Competency Evaluation.csv" data={finalQualtric}>
                {'Competency Evaluation'}
              </CSVLink>
            </CardBody>
          )}
        </Card>
      ) : null}
    </>
  );
}
