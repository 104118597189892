const covidAnswer = {
  A: 'COVID-19 Vaccination Medical Exemption Certification',
  B: 'COVID-19 Recovery Record QR Code',
  C: "'Provisional Vaccine Pass' issued to inbound travellers",
  D: 'None of the above',
};

const levelOptions = {
  'Master’s Degree or above': 'Master’s Degree or above',
  'Bachelor’s Degree': 'Bachelor’s Degree',
  'Associate Degree': 'Associate Degree',
  'Higher Diploma / Advanced Diploma': 'Higher Diploma / Advanced Diploma',
  HKDSE: 'HKDSE',
  'The International Baccalaureate': 'The International Baccalaureate',
  'Diploma of Foundation Studies': 'Diploma of Foundation Studies',
  'Diploma Yi Jin': 'Diploma Yi Jin',
  Others: 'Others',
};

const vaskStatusConvert = {
  pending: 'Not yet started',
  save: 'In progress',
  finished: 'Finished',
};

const videoInterviewStatusConvert = {
  pending: 'Not yet started',
  neufast: 'In progress',
  finished: 'Finished',
};

const groupInterviewStatusConvert = {
  pending: 'Not yet started',
  survey: 'In progress',
  release: 'In progress',
  finished: 'Finished',
};

const groupInterviewResultConvert = {
  recommended: 'Recommended',
  waitinglist: 'Waiting List',
  notrecommended: 'Not Recommended',
  absent: 'Absent',
};

const finalInterviewStatusConvert = {
  pending: 'Not yet started',
  assigned: 'In progress',
  release: 'In progress',
  finished: 'Finished',
};

const profileHeader = [
  {
    label: 'Candidate Id',
    key: 'id',
  },
  {
    label: 'Cohort',
    key: 'cohort',
  },
  {
    label: 'Given Name (Eng)',
    key: 'givenNameEn',
  },
  {
    label: 'Surname (Eng)',
    key: 'surnameEn',
  },
  {
    label: 'Given Name (Chi)',
    key: 'givenNameZh',
  },
  {
    label: 'Surname (Chi)',
    key: 'surnameZh',
  },
  {
    label: 'Preferred Name',
    key: 'preferredName',
  },
  {
    label: 'Gender',
    key: 'gender',
  },
  {
    label: 'Phone No.',
    key: 'phoneNo',
  },
  {
    label: 'Email',
    key: 'email',
  },
  {
    label: 'Resume',
    key: 'resume',
  },
  {
    label: 'Portal Remarks',
    key: 'remark',
  },
  {
    label: 'Portfolio',
    key: 'portfolio',
  },
  {
    label: 'Create Datetime',
    key: 'createdAt',
  },
  {
    label: 'Reviewed by GenHK',
    key: 'reviewed',
  },
  {
    label: 'Withdrawn',
    key: 'withdrawn',
  },
  {
    label: 'Shift to Cohort 3',
    key: 'toCohort3',
  },
  {
    label: 'Cohort 3 Group',
    key: 'cohort3Group',
  },
  {
    label: 'Cohort 3 Group',
    key: 'cohort3GroupFinal',
  },
  {
    label: 'Job Offer - 1',
    key: 'jobOffer1',
  },
  {
    label: 'Employers',
    key: 'jobEmployer1',
  },
  {
    label: 'Status',
    key: 'jobStatus1',
  },
  {
    label: 'Job Offer - 2',
    key: 'jobOffer2',
  },
  {
    label: 'Employers',
    key: 'jobEmployer2',
  },
  {
    label: 'Status',
    key: 'jobStatus2',
  },
  {
    label: 'Job Offer - 3',
    key: 'jobOffer3',
  },
  {
    label: 'Employers',
    key: 'jobEmployer3',
  },
  {
    label: 'Status',
    key: 'jobStatus3',
  },
  {
    label: 'Job Offer - 4',
    key: 'jobOffer4',
  },
  {
    label: 'Employers',
    key: 'jobEmployer4',
  },
  {
    label: 'Status',
    key: 'jobStatus4',
  },
  {
    label: 'Convenor',
    key: 'roleConvenor1',
  },
  {
    label: 'Cohort',
    key: 'roleCohort1',
  },
  {
    label: 'Applied Roles - 1',
    key: 'role1',
  },
  {
    label: 'Convenor',
    key: 'roleConvenor2',
  },
  {
    label: 'Cohort',
    key: 'roleCohort2',
  },
  {
    label: 'Applied Roles - 2',
    key: 'role2',
  },
  {
    label: 'Level',
    key: 'level',
  },
  {
    label: 'Location Of Study',
    key: 'location',
  },
  {
    label: 'Institution',
    key: 'institution',
  },
  {
    label: 'Programme Name',
    key: 'programme',
  },
  {
    label: 'Area Of Study',
    key: 'areaOfStudy',
  },
  {
    label: 'Study Duration',
    key: 'studyDuration',
  },
  {
    label: 'Paid Experience',
    key: 'paidExpCount',
  },
  {
    label: 'Employment',
    key: 'employmentCount',
  },
  {
    label: 'Entrepreneur',
    key: 'entrepreneurCount',
  },
  {
    label: 'Other',
    key: 'otherCount',
  },
  {
    label: 'PE1 Type',
    key: 'pe1Type',
  },
  {
    label: 'Organization',
    key: 'pe1Organization',
  },
  {
    label: 'Role',
    key: 'pe1Role',
  },
  {
    label: 'From To',
    key: 'pe1FromTo',
  },
  {
    label: 'PE2 Type',
    key: 'pe2Type',
  },
  {
    label: 'Organization',
    key: 'pe2Organization',
  },
  {
    label: 'Role',
    key: 'pe2Role',
  },
  {
    label: 'From To',
    key: 'pe2FromTo',
  },
  {
    label: 'PE3 Type',
    key: 'pe3Type',
  },
  {
    label: 'Organization',
    key: 'pe3Organization',
  },
  {
    label: 'Role',
    key: 'pe3Role',
  },
  {
    label: 'From To',
    key: 'pe3FromTo',
  },
  {
    label: 'PE4 Type',
    key: 'pe4Type',
  },
  {
    label: 'Organization',
    key: 'pe4Organization',
  },
  {
    label: 'Role',
    key: 'pe4Role',
  },
  {
    label: 'From To',
    key: 'pe4FromTo',
  },
  {
    label: 'PE5 Type',
    key: 'pe5Type',
  },
  {
    label: 'Organization',
    key: 'pe5Organization',
  },
  {
    label: 'Role',
    key: 'pe5Role',
  },
  {
    label: 'From To',
    key: 'pe5FromTo',
  },
  {
    label: 'Non-paid Experience',
    key: 'nonPaidExpCount',
  },
  {
    label: 'Voluntary',
    key: 'voluntaryCount',
  },
  {
    label: 'Vocational and Educational Training / Work Exposure',
    key: 'vocationalCount',
  },
  {
    label: 'Serious Leisure or Interest',
    key: 'seriousCount',
  },
  {
    label: 'Domestic and/or Neighborhood Provisioning',
    key: 'domesticCount',
  },
  {
    label: 'NPE1 Type',
    key: 'npe1Type',
  },
  {
    label: 'Organization',
    key: 'npe1Organization',
  },
  {
    label: 'Role',
    key: 'npe1Role',
  },
  {
    label: 'From To',
    key: 'npe1FromTo',
  },
  {
    label: 'NPE2 Type',
    key: 'npe2Type',
  },
  {
    label: 'Organization',
    key: 'npe2Organization',
  },
  {
    label: 'Role',
    key: 'npe2Role',
  },
  {
    label: 'From To',
    key: 'npe2FromTo',
  },
  {
    label: 'NPE3 Type',
    key: 'npe3Type',
  },
  {
    label: 'Organization',
    key: 'npe3Organization',
  },
  {
    label: 'Role',
    key: 'npe3Role',
  },
  {
    label: 'From To',
    key: 'npe3FromTo',
  },
  {
    label: 'NPE4 Type',
    key: 'npe4Type',
  },
  {
    label: 'Organization',
    key: 'npe4Organization',
  },
  {
    label: 'Role',
    key: 'npe4Role',
  },
  {
    label: 'From To',
    key: 'npe4FromTo',
  },
  {
    label: 'NPE5 Type',
    key: 'npe5Type',
  },
  {
    label: 'Organization',
    key: 'npe5Organization',
  },
  {
    label: 'Role',
    key: 'npe5Role',
  },
  {
    label: 'From To',
    key: 'npe5FromTo',
  },
  {
    label: 'V1',
    key: 'V1',
  },
  {
    label: 'V2',
    key: 'V2',
  },
  {
    label: 'V3',
    key: 'V3',
  },
  {
    label: 'A1',
    key: 'A1',
  },
  {
    label: 'A2',
    key: 'A2',
  },
  {
    label: 'A3',
    key: 'A3',
  },
  {
    label: 'S1',
    key: 'S1',
  },
  {
    label: 'S2',
    key: 'S2',
  },
  {
    label: 'S3',
    key: 'S3',
  },
  {
    label: 'K1',
    key: 'K1',
  },
  {
    label: 'K2',
    key: 'K2',
  },
  {
    label: 'K3',
    key: 'K3',
  },
];

const videoHeader = [
  {
    label: 'Candidate Id',
    key: 'id',
  },
  {
    label: 'Cohort',
    key: 'cohort',
  },
  {
    label: 'Given Name (Eng)',
    key: 'givenNameEn',
  },
  {
    label: 'Surname (Eng)',
    key: 'surnameEn',
  },
  {
    label: 'Given Name (Chi)',
    key: 'givenNameZh',
  },
  {
    label: 'Surname (Chi)',
    key: 'surnameZh',
  },
  {
    label: 'Preferred Name',
    key: 'preferredName',
  },
  {
    label: 'Gender',
    key: 'gender',
  },
  {
    label: 'Phone No.',
    key: 'phoneNo',
  },
  {
    label: 'Email',
    key: 'email',
  },
  {
    label: 'Resume',
    key: 'resume',
  },
  {
    label: 'Portfolio',
    key: 'portfolio',
  },
  {
    label: 'Create Datetime',
    key: 'createdAt',
  },
  {
    label: 'Adaptability',
    key: 'adaptability',
  },
  {
    label: 'Composure',
    key: 'composure',
  },
  {
    label: 'Enthusiasm',
    key: 'enthusiasm',
  },
  {
    label: 'Interpersonal Relationship Communication',
    key: 'interpersonal',
  },
  {
    label: 'Self Confidence',
    key: 'selfConfidence',
  },
  {
    label: 'Task Performance',
    key: 'task',
  },
];

const cohortHeader = [
  {
    label: 'Candidate Id',
    key: 'id',
  },
  {
    label: 'Cohort',
    key: 'cohort',
  },
  {
    label: 'Given Name (Eng)',
    key: 'givenNameEn',
  },
  {
    label: 'Surname (Eng)',
    key: 'surnameEn',
  },
  {
    label: 'Given Name (Chi)',
    key: 'givenNameZh',
  },
  {
    label: 'Surname (Chi)',
    key: 'surnameZh',
  },
  {
    label: 'Preferred Name',
    key: 'preferredName',
  },
  {
    label: 'Gender',
    key: 'gender',
  },
  {
    label: 'Phone No.',
    key: 'phoneNo',
  },
  {
    label: 'Email',
    key: 'email',
  },
  {
    label: 'Resume',
    key: 'resume',
  },
  {
    label: 'Portfolio',
    key: 'portfolio',
  },
  {
    label: 'Create Datetime',
    key: 'createdAt',
  },
  {
    label: 'Job Offer - 1',
    key: 'jobOffer1',
  },
  {
    label: 'Employers',
    key: 'jobEmployer1',
  },
  {
    label: 'Status',
    key: 'jobStatus1',
  },
  {
    label: 'Job Offer - 2',
    key: 'jobOffer2',
  },
  {
    label: 'Employers',
    key: 'jobEmployer2',
  },
  {
    label: 'Status',
    key: 'jobStatus2',
  },
  {
    label: 'Job Offer - 3',
    key: 'jobOffer3',
  },
  {
    label: 'Employers',
    key: 'jobEmployer3',
  },
  {
    label: 'Status',
    key: 'jobStatus3',
  },
  {
    label: 'Job Offer - 4',
    key: 'jobOffer4',
  },
  {
    label: 'Employers',
    key: 'jobEmployer4',
  },
  {
    label: 'Status',
    key: 'jobStatus4',
  },
  // {
  //   label: 'Convenor',
  //   key: 'roleConvenor1',
  // },
  {
    label: 'Applied Roles - 1',
    key: 'role1',
  },
  // {
  //   label: 'Convenor',
  //   key: 'roleConvenor2',
  // },
  {
    label: 'Applied Roles - 2',
    key: 'role2',
  },
  {
    label: 'Level',
    key: 'level',
  },
  {
    label: 'Location Of Study',
    key: 'location',
  },
  {
    label: 'Institution',
    key: 'institution',
  },
  {
    label: 'Programme Name',
    key: 'programme',
  },
  {
    label: 'Area Of Study',
    key: 'areaOfStudy',
  },
  {
    label: 'Study Duration',
    key: 'studyDuration',
  },
  {
    label: 'Paid Experience',
    key: 'paidExpCount',
  },
  {
    label: 'Employment',
    key: 'employmentCount',
  },
  {
    label: 'Entrepreneur',
    key: 'entrepreneurCount',
  },
  {
    label: 'Other',
    key: 'otherCount',
  },
  {
    label: 'PE1 Type',
    key: 'pe1Type',
  },
  {
    label: 'Organization',
    key: 'pe1Organization',
  },
  {
    label: 'Role',
    key: 'pe1Role',
  },
  {
    label: 'From To',
    key: 'pe1FromTo',
  },
  {
    label: 'PE2 Type',
    key: 'pe2Type',
  },
  {
    label: 'Organization',
    key: 'pe2Organization',
  },
  {
    label: 'Role',
    key: 'pe2Role',
  },
  {
    label: 'From To',
    key: 'pe2FromTo',
  },
  {
    label: 'PE3 Type',
    key: 'pe3Type',
  },
  {
    label: 'Organization',
    key: 'pe3Organization',
  },
  {
    label: 'Role',
    key: 'pe3Role',
  },
  {
    label: 'From To',
    key: 'pe3FromTo',
  },
  {
    label: 'PE4 Type',
    key: 'pe4Type',
  },
  {
    label: 'Organization',
    key: 'pe4Organization',
  },
  {
    label: 'Role',
    key: 'pe4Role',
  },
  {
    label: 'From To',
    key: 'pe4FromTo',
  },
  {
    label: 'PE5 Type',
    key: 'pe5Type',
  },
  {
    label: 'Organization',
    key: 'pe5Organization',
  },
  {
    label: 'Role',
    key: 'pe5Role',
  },
  {
    label: 'From To',
    key: 'pe5FromTo',
  },
  {
    label: 'Non-paid Experience',
    key: 'nonPaidExpCount',
  },
  {
    label: 'Voluntary',
    key: 'voluntaryCount',
  },
  {
    label: 'Vocational and Educational Training / Work Exposure',
    key: 'vocationalCount',
  },
  {
    label: 'Serious Leisure or Interest',
    key: 'seriousCount',
  },
  {
    label: 'Domestic and/or Neighborhood Provisioning',
    key: 'domesticCount',
  },
  {
    label: 'NPE1 Type',
    key: 'npe1Type',
  },
  {
    label: 'Organization',
    key: 'npe1Organization',
  },
  {
    label: 'Role',
    key: 'npe1Role',
  },
  {
    label: 'From To',
    key: 'npe1FromTo',
  },
  {
    label: 'NPE2 Type',
    key: 'npe2Type',
  },
  {
    label: 'Organization',
    key: 'npe2Organization',
  },
  {
    label: 'Role',
    key: 'npe2Role',
  },
  {
    label: 'From To',
    key: 'npe2FromTo',
  },
  {
    label: 'NPE3 Type',
    key: 'npe3Type',
  },
  {
    label: 'Organization',
    key: 'npe3Organization',
  },
  {
    label: 'Role',
    key: 'npe3Role',
  },
  {
    label: 'From To',
    key: 'npe3FromTo',
  },
  {
    label: 'NPE4 Type',
    key: 'npe4Type',
  },
  {
    label: 'Organization',
    key: 'npe4Organization',
  },
  {
    label: 'Role',
    key: 'npe4Role',
  },
  {
    label: 'From To',
    key: 'npe4FromTo',
  },
  {
    label: 'NPE5 Type',
    key: 'npe5Type',
  },
  {
    label: 'Organization',
    key: 'npe5Organization',
  },
  {
    label: 'Role',
    key: 'npe5Role',
  },
  {
    label: 'From To',
    key: 'npe5FromTo',
  },
  {
    label: 'V1',
    key: 'V1',
  },
  {
    label: 'V2',
    key: 'V2',
  },
  {
    label: 'V3',
    key: 'V3',
  },
  {
    label: 'A1',
    key: 'A1',
  },
  {
    label: 'A2',
    key: 'A2',
  },
  {
    label: 'A3',
    key: 'A3',
  },
  {
    label: 'S1',
    key: 'S1',
  },
  {
    label: 'S2',
    key: 'S2',
  },
  {
    label: 'S3',
    key: 'S3',
  },
  {
    label: 'K1',
    key: 'K1',
  },
  {
    label: 'K2',
    key: 'K2',
  },
  {
    label: 'K3',
    key: 'K3',
  },
];

const candidatesHeader = [
  {
    label: 'Candidate Id',
    key: 'id',
  },
  {
    label: 'Cohort',
    key: 'cohort',
  },
  {
    label: 'Convenor',
    key: 'roleConvenor1',
  },
  {
    label: 'Cohort',
    key: 'roleCohort1',
  },
  {
    label: 'Applied Roles - 1',
    key: 'role1',
  },
  {
    label: 'Convenor',
    key: 'roleConvenor2',
  },
  {
    label: 'Cohort',
    key: 'roleCohort2',
  },
  {
    label: 'Applied Roles - 2',
    key: 'role2',
  },
  {
    label: 'Given Name (Eng)',
    key: 'givenNameEn',
  },
  {
    label: 'Surname (Eng)',
    key: 'surnameEn',
  },
  {
    label: 'Given Name (Chi)',
    key: 'givenNameZh',
  },
  {
    label: 'Surname (Chi)',
    key: 'surnameZh',
  },
  {
    label: 'Preferred Name',
    key: 'preferredName',
  },
  {
    label: 'Gender',
    key: 'gender',
  },
  {
    label: 'Phone No.',
    key: 'phoneNo',
  },
  {
    label: 'Email',
    key: 'email',
  },
  {
    label: 'Channel',
    key: 'channel',
  },
  {
    label: 'Resume',
    key: 'resume',
  },
  {
    label: 'Portal Remarks',
    key: 'remark',
  },
  {
    label: 'GenHK Review',
    key: 'reviewed',
  },
  {
    label: 'Withdrawn',
    key: 'withdrawn',
  },
  {
    label: 'Shift to Cohort 3',
    key: 'toCohort3',
  },
  {
    label: 'Cohort 3 Group',
    key: 'cohort3Group',
  },
  {
    label: 'Cohort 3 Group',
    key: 'cohort3GroupFinal',
  },
  {
    label: 'Portfolio',
    key: 'portfolio',
  },
  {
    label: 'Create Datetime',
    key: 'createdAt',
  },
  {
    label: 'Last Update Datetime',
    key: 'updatedAt',
  },
  {
    label: 'Vask Status',
    key: 'vask',
  },
  {
    label: 'Last VASK Update Datetime',
    key: 'vaskUpdatedAt',
  },
  {
    label: 'Video Status',
    key: 'videoStatus',
  },
  {
    label: 'Last Video Update Datetime',
    key: 'videoUpdatedAt',
  },
  {
    label: 'Group Status',
    key: 'groupStatus',
  },
  {
    label: 'Group Interview Result',
    key: 'groupInterviewResult',
  },
  {
    label: 'Last Group Interview Update Datetime',
    key: 'groupUpdatedAt',
  },
  {
    label: 'No. Of Final Interview',
    key: 'finalStatus',
  },
  {
    label: 'No. Of Offer',
    key: 'offerStatus',
  },
  {
    label: 'Job Offer - 1',
    key: 'jobOffer1',
  },
  {
    label: 'Employers',
    key: 'jobEmployer1',
  },
  {
    label: 'Status',
    key: 'jobStatus1',
  },
  {
    label: 'Job Offer - 2',
    key: 'jobOffer2',
  },
  {
    label: 'Employers',
    key: 'jobEmployer2',
  },
  {
    label: 'Status',
    key: 'jobStatus2',
  },
  {
    label: 'Job Offer - 3',
    key: 'jobOffer3',
  },
  {
    label: 'Employers',
    key: 'jobEmployer3',
  },
  {
    label: 'Status',
    key: 'jobStatus3',
  },
  {
    label: 'Job Offer - 4',
    key: 'jobOffer4',
  },
  {
    label: 'Employers',
    key: 'jobEmployer4',
  },
  {
    label: 'Status',
    key: 'jobStatus4',
  },
  {
    label: 'Level',
    key: 'level',
  },
  {
    label: 'Location Of Study',
    key: 'location',
  },
  {
    label: 'Institution',
    key: 'institution',
  },
  {
    label: 'Start Month',
    key: 'startMonth',
  },

  {
    label: 'Start Year',
    key: 'startYear',
  },
  {
    label: 'Grad Month',
    key: 'gradMonth',
  },
  {
    label: 'Grad Year',
    key: 'gradYear',
  },
  {
    label: 'Area Of Study',
    key: 'areaOfStudy',
  },
  {
    label: 'Programme',
    key: 'programme',
  },
];

export {
  covidAnswer,
  levelOptions,
  profileHeader,
  videoHeader,
  cohortHeader,
  candidatesHeader,
  vaskStatusConvert,
  videoInterviewStatusConvert,
  groupInterviewStatusConvert,
  finalInterviewStatusConvert,
  groupInterviewResultConvert,
};
