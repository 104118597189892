import React, { useState, useEffect, useContext } from 'react';
import { ButtonGroup, Button, Breadcrumb, BreadcrumbItem, Badge } from 'reactstrap';
import { fetchWithGet, fetchWithPost, fetchWithFormData } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';
import CandidateInfo from './CandidateInfo';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import PopUpContext from '../../components/components/PopUp/PopUpContext';
import { Loader } from '../../components';
import { levelOptions } from '../../components/helpers/reportHelper';
import qs from 'qs';
import { CSVLink, CSVDownload } from 'react-csv';

export default function Offer() {
  let { path, url } = useRouteMatch();
  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <AllInterview rootPath={url} />
        </Route>
        <Route path={`${path}/info/:id`}>
          <CandidateInfo rootPath={url} rootName={'Offer'} from={'offer'} />
        </Route>
      </Switch>
    </Router>
  );
}

function AllInterview({ rootPath }) {
  const [candidates, setCandidates] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const offer = window.sessionStorage.getItem('offerSetting');
  const query = qs.parse(offer, { ignoreQueryPrefix: true });

  const alertContext = useContext(PageAlertContext);
  const popUpContext = useContext(PopUpContext);

  const columns = [
    '#',
    'Candidate Id',
    'Candidate',
    'Email/PhoneNo',
    'Cohort 3 Group',
    'Cohort 3 Group Final',
    'Education Level',
    'School',
    'Program name',
    'Area of Study ',
    'Grad Date ',
    'Employer',
    'Cohort',
    'Position',
    'Interview Status',
    'Interview Comments',
    'Status',
    'Competency Survey',
    {
      name: 'Actions',
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
          return (
            <ButtonGroup>
              {!candidates[rowIndex].offer ? (
                <Button onClick={() => _releaseOffer(candidates[rowIndex])}>Assign Offer</Button>
              ) : !['accept', 'reject'].includes(candidates[rowIndex].offer.status) ? (
                <>
                  <Button color="danger" onClick={() => _cancelOffer(candidates[rowIndex])}>
                    Cancel Offer
                  </Button>
                  <Button color="primary" onClick={() => _notifyCandidate(candidates[rowIndex])}>
                    Send Notification
                  </Button>
                </>
              ) : candidates[rowIndex].offer.status === 'accept' ? (
                candidates[rowIndex].offer?.isLeft ? (
                  <>This candidate is quit</>
                ) : (
                  <Button
                    color="primary"
                    onClick={async () => {
                      const confirm = await popUpContext.onOpen('Are you confirm this candidate is quit?');
                      if (confirm) {
                        const result = await fetchWithPost('/dashboard/quitOffer', {
                          offerId: candidates[rowIndex].offer.id,
                        });
                        if (result) {
                          alertContext.setAlert('Successfully updated', 'success');
                          fetchApi();
                        }
                      }
                    }}
                  >
                    Quit
                  </Button>
                )
              ) : (
                candidates[rowIndex].offer.reason && `Reason: ${candidates[rowIndex].offer.reason}`
              )}
            </ButtonGroup>
          );
        },
      },
    },
  ];
  const options = {
    selectableRows: 'none',
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
    onChangePage: (number) => {
      window.sessionStorage.setItem('offerSetting', qs.stringify({ ...query, page: number }));
    },
    onChangeRowsPerPage: (number) => {
      window.sessionStorage.setItem('offerSetting', qs.stringify({ ...query, rowsPerPage: number }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      window.sessionStorage.setItem(
        'offerSetting',
        qs.stringify({ ...query, sortOrder: { name: changedColumn, direction: direction } })
      );
    },
    page: query?.page,
    rowsPerPage: query?.rowsPerPage,
    sortOrder: query?.sortOrder,
  };

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);
    const offer = await fetchWithGet('/dashboard/getStageCandidate', { stage: 'offer' });
    if (offer) {
      setCandidates(offer);
      setData(
        offer.map((v, i) => [
          i + 1,
          v.candidate.userId,
          `${v.candidate && v.candidate.surname.en} ${v.candidate && v.candidate.givenName.en}\n${
            (v.candidate && v.candidate.surname.zh) || ''
          } ${(v.candidate && v.candidate.givenName.zh) || ''}`,
          `${v.candidate && v.candidate.email}/${v.candidate && v.candidate.phoneNo}`,
          `${v.candidate && v.candidate.cohort3Group}`,
          `${v.candidate && v.candidate.cohort3GroupFinal}`,
          v.candidate && v.candidate.education
            ? v.candidate.education.level === 'Others'
              ? v.candidate.education.otherLevel || '-'
              : levelOptions[v.candidate.education.level] || '-'
            : '-',
          v.candidate && v.candidate.education
            ? v.candidate.education.otherInstitution ||
              (v.candidate.education.institution && v.candidate.education.institution.en)
            : '-',
          v.candidate.education && v.candidate.education.programmeName,
          v.candidate.education
            ? v.candidate.education.otherAreaOfStudy ||
              (v.candidate.education.areaOfStudy && v.candidate.education.areaOfStudy.en)
            : '',
          v.candidate && v.candidate.education && _.isInteger(v.candidate.education.gradYear)
            ? `${v.candidate.education.gradYear}/${v.candidate.education.gradMonth}`
            : `${v.candidate.education.gradYear}`,
          v.employer.givenName.en,
          v.application.cohort.en,
          `${v.position.stream.en} - ${v.position.role.en}`,
          _.startCase(v.interviewer.status),
          v.interviewer.interviewNotes,
          v.offer
            ? `${_.startCase(v.offer.status)}${['accept', 'reject'].includes(v.offer.status) ? 'ed' : ''} `
            : 'Waiting for assign offer',
          v.surveyFinishDatetime
            ? 'Finished'
            : `https://esurvey.psy.cuhk.edu.hk/jfe/form/SV_bJyhSMHQMZGudJc?id=${v.id}-${v.employer.id}`,
        ])
      );
    }
    setIsLoading(false);
  };

  const _releaseOffer = async (offer) => {
    const confirm = await popUpContext.onOpen('Are you confirm to release offer?');
    if (confirm) {
      const result = await fetchWithPost('/dashboard/releaseOffer', {
        applicationId: offer.application.id,
        finalInterviewId: offer.id,
      });
      if (result && result.status === 400) {
        alertContext.setAlert(result.data.message, 'danger');
      } else {
        alertContext.setAlert('Successfully updated', 'success');
      }
      fetchApi();
    }
  };
  const _cancelOffer = async (offer) => {
    const confirm = await popUpContext.onOpen('Are you confirm to cancel offer?');
    if (confirm) {
      const result = await fetchWithPost('/dashboard/cancelOffer', {
        applicationId: offer.application.id,
        finalInterviewId: offer.id,
      });
      if (result && result.status === 400) {
        alertContext.setAlert(result.data.message, 'danger');
      } else {
        alertContext.setAlert('Successfully updated', 'success');
      }
      fetchApi();
    }
  };
  const _notifyCandidate = async (offer) => {
    const confirm = await popUpContext.onOpen('Are you confirm to send notification?');
    if (confirm) {
      const result = await fetchWithPost('/dashboard/sendNotification', { stage: 'offer', id: offer.offer.id });
      if (result && result.status === 400) {
        alertContext.setAlert(result.data.message, 'danger');
      } else {
        alertContext.setAlert('Successfully updated', 'success');
      }
      fetchApi();
    }
  };

  const exportHeader = [
    { label: 'Candidate Id', key: 'userId' },
    { label: 'Candidate', key: 'candidate' },
    { label: 'Email', key: 'email' },
    { label: 'PhoneNo', key: 'phoneNo' },
    { label: 'Cohort 3 Group', key: 'cohort3Group' },
    { label: 'Cohort 3 Group Final', key: 'cohort3GroupFinal' },
    { label: 'Education Level', key: 'education' },
    { label: 'School', key: 'institution' },
    { label: 'Program name', key: 'programmeName' },
    { label: 'Area of Study ', key: 'areaOfStudy' },
    { label: 'Grad Date ', key: 'gradDate' },
    { label: 'Employer', key: 'employer' },
    { label: 'Cohort', key: 'cohort' },
    { label: 'Position', key: 'position' },
    { label: 'Hiring Decision', key: 'status' },
    { label: 'Interview Notes', key: 'interviewNotes' },
    { label: 'Offer Status', key: 'offerStatus' },
    { label: 'Competency Survey', key: 'survey' },
    { label: 'Action', key: 'action' },
  ];

  const exportData = candidates.map((v, i) => {
    return {
      userId: v.candidate.userId,
      candidate: `${v.candidate && v.candidate.surname.en} ${v.candidate && v.candidate.givenName.en}\n${
        (v.candidate && v.candidate.surname.zh) || ''
      } ${(v.candidate && v.candidate.givenName.zh) || ''}`,
      email: v.candidate && v.candidate.email,
      phoneNo: v.candidate && v.candidate.phoneNo,
      cohort3Group: v?.candidate?.cohort3Group,
      cohort3GroupFinal: v?.candidate?.cohort3GroupFinal,
      education:
        v.candidate && v.candidate.education
          ? v.candidate.education.level === 'Others'
            ? v.candidate.education.otherLevel || '-'
            : levelOptions[v.candidate.education.level] || '-'
          : '-',
      programmeName: v.candidate.education && v.candidate.education.programmeName,
      areaOfStudy: v.candidate.education
        ? v.candidate.education.otherAreaOfStudy ||
          (v.candidate.education.areaOfStudy && v.candidate.education.areaOfStudy.en)
        : '',
      gradDate:
        v.candidate.education &&
        `${v.candidate.education.startYear}/${v.candidate.education.startMonth} - ${
          _.isInteger(v.candidate.education.gradYear)
            ? `${v.candidate.education.gradYear}/${v.candidate.education.gradMonth}`
            : `${v.candidate.education.gradYear}`
        }`,
      institution:
        v.candidate && v.candidate.education
          ? v.candidate.education.otherInstitution ||
            (v.candidate.education.institution && v.candidate.education.institution.en)
          : '',
      employer: v.employer.givenName.en,
      cohort: v.application.cohort.en,
      position: `${v.position.stream.en} - ${v.position.role.en}`,
      status: _.startCase(v.interviewer.status),
      interviewNotes: v.interviewer.interviewNotes,
      offerStatus: v.offer
        ? `${_.startCase(v.offer.status)}${['accept', 'reject'].includes(v.offer.status) ? 'ed' : ''} `
        : 'Waiting for assign offer',
      survey: v.surveyFinishDatetime
        ? 'Finished'
        : `https://esurvey.psy.cuhk.edu.hk/jfe/form/SV_bJyhSMHQMZGudJc?id=${v.id}-${v.employer.id}`,
      action: v.offer ? (v.offer.isLeft ? 'Quit' : '') : '',
    };
  });

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Offer</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="text-right mb-2">
        <CSVLink filename={`Offer-Export`} data={exportData} headers={exportHeader}>
          Export Candidates
        </CSVLink>
      </div>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}
