import React, { useState, useEffect, useContext } from 'react';
import {
  ButtonGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from 'reactstrap';
import { fetchWithGet, fetchWithPost } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import PopUpContext from '../../components/components/PopUp/PopUpContext';
import { Loader } from '../../components';
import update from 'immutability-helper';
import Datetime from 'react-datetime';

export default function Survey() {
  let { path, url } = useRouteMatch();
  console.log(path);
  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <SurveySectionPage rootPath={url} />
        </Route>
        <Route path={`${path}/:surveySection/create`}>
          <SurveyEdit rootPath={url} />
        </Route>
        <Route path={`${path}/:surveySection`}>
          <SurveyEdit rootPath={url} />
        </Route>
      </Switch>
    </Router>
  );
}

function SurveySectionPage({ rootPath }) {
  const history = useHistory();
  const alertContext = useContext(PageAlertContext);

  const [surveyList, setSurveyList] = useState([]);
  const [data, setData] = useState([]);
  const [survey, setSurvey] = useState({
    name: {
      zh: '',
      en: '',
    },
    description: '',
    isHided: true,
    startDatetime: '',
    endDatetime: '',
    target: 'system',
  });
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    '#',
    'Survey Name',
    'Hided',
    'Target',
    {
      name: 'Actions',
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
          return (
            <ButtonGroup>
              <Button color="primary" outline onClick={() => toggle(surveyList[rowIndex])}>
                Edit Survey
              </Button>
              <Button color="primary" outline onClick={() => history.push(`${rootPath}/${surveyList[rowIndex].id}`)}>
                Go to Survey Details
              </Button>
            </ButtonGroup>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: 'none',
    print: false,
    download: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };

  const [modal, setModal] = useState(false);
  const toggle = (v) => {
    if (v && v.id) {
      setSurvey(v);
    } else {
      setSurvey({
        name: {
          zh: '',
          en: '',
        },
        description: '',
        isHided: true,
        startDatetime: '',
        endDatetime: '',
        target: 'system',
      });
      fetchApi();
    }
    setModal(!modal);
  };

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/content/survey');
    if (result) {
      setSurveyList(result);
      setData(result.map((v, i) => [i + 1, v.name.en, v.isHided ? 'True' : 'False', _.startCase(v.target)]));
    }
    setIsLoading(false);
  };

  const save = async () => {
    if (survey) {
      delete survey.surveySections;
      delete survey.createdAt;
      delete survey.updatedAt;
      const result = await fetchWithPost('/content/survey', survey);
      if (result && result.status === 400) {
        alertContext.setAlert(result.data.message, 'danger');
      } else {
        alertContext.setAlert('Successfully updated', 'success');
      }
    } else {
      alertContext.setAlert('Please fill in all the blank', 'danger');
    }
    toggle();
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Survey</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="text-right mb-2">
        <Button color="primary" onClick={toggle}>
          Add Survey
        </Button>
        <Modal centered={true} isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            <b>{survey.id ? 'Edit' : 'Add'} Survey</b>
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label className="mb-2">Name</Label>
              <Input
                placeholder="Name"
                value={survey.name.en}
                onChange={(v) =>
                  setSurvey({
                    ...survey,
                    name: {
                      en: v.target.value,
                    },
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label className="mb-2">Description</Label>
              <Input
                placeholder="Description"
                value={survey.description}
                onChange={(v) =>
                  setSurvey({
                    ...survey,
                    description: v.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label>Hidden</Label>
              <Input
                type="select"
                name="select"
                value={survey.isHided}
                onChange={(v) =>
                  setSurvey({
                    ...survey,
                    isHided: v.target.value === 'true',
                  })
                }
              >
                <option value={true}>{'True'}</option>
                <option value={false}>{'False'}</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Target</Label>
              <Input
                type="select"
                name="select"
                value={survey.target}
                onChange={(v) =>
                  setSurvey({
                    ...survey,
                    target: v.target.value,
                  })
                }
              >
                <option value={'system'}>{'System'}</option>
                <option value={'candidate'}>{'Candidate'}</option>
                <option value={'employer'}>{'Employer'}</option>
              </Input>
            </FormGroup>
            {survey.target && survey.target !== 'system' ? (
              <>
                <FormGroup>
                  <Label className="mb-2">Start Datetime</Label>
                  <Datetime
                    value={new Date(survey.startDatetime)}
                    onChange={(v) =>
                      setSurvey({
                        ...survey,
                        startDatetime: v,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">End Datetime</Label>
                  <Datetime
                    value={new Date(survey.endDatetime)}
                    onChange={(v) =>
                      setSurvey({
                        ...survey,
                        endDatetime: v,
                      })
                    }
                  />
                </FormGroup>
              </>
            ) : (
              <></>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={save}>
              {survey.id ? 'Edit' : 'Add'}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}

function SurveyEdit({ rootPath }) {
  let { surveySection } = useParams();
  let { url } = useRouteMatch();
  const history = useHistory();
  const alertContext = useContext(PageAlertContext);
  const popUpContext = useContext(PopUpContext);

  const [survey, setSurvey] = useState({});

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    const result = await fetchWithGet(`/content/surveyDetails/${surveySection}`);
    if (result) {
      setSurvey(result);
    }
  };

  const saveSurvey = async (e) => {
    e.preventDefault();
    if (survey) {
      const result = await fetchWithPost('/content/surveyDetails', {
        id: survey.id,
        surveySections: survey.surveySections,
      });

      if (result && result.status === 400) {
        alertContext.setAlert(result.data.message, 'danger');
      } else {
        alertContext.setAlert('Successfully updated', 'success');

        history.push(`${rootPath}/${surveySection}`);
      }
    } else {
      alertContext.setAlert('Please fill in all the blank', 'danger');
    }
  };

  const addSection = async (e) => {
    setSurvey({
      ...survey,
      surveySections: [
        ...survey.surveySections,
        {
          title: { en: '' },
          sequence: 0,
          isHided: false,
          surveyType: 'single',
          surveyQuestions: [],
        },
      ],
    });
  };

  return (
    <>
      <div className="mb-2">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={`${rootPath}`}>Survey</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`${rootPath}/${surveySection}`}>{survey?.name?.en || ''}</Link>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div className="mb-2">
        <FormGroup>
          <Button color="primary" onClick={addSection}>
            Create Section
          </Button>
        </FormGroup>
      </div>
      <div className="mb-2">
        {(survey?.surveySections || []).map((section, sectionIndex) => {
          return (
            <Card key={sectionIndex}>
              <CardHeader>
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label className="mb-2">Title</Label>
                      <Input
                        className="mb-2"
                        placeholder="Title"
                        value={section?.title?.en || ''}
                        onChange={(v) =>
                          setSurvey({
                            ...survey,
                            surveySections: update(survey?.surveySections, {
                              [sectionIndex]: { title: { en: { $set: v.target.value } } },
                            }),
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={3}>
                    <FormGroup>
                      <Label>Type</Label>
                      <Input
                        type="select"
                        name="select"
                        value={section.surveyType}
                        onChange={(v) =>
                          setSurvey({
                            ...survey,
                            surveySections: update(survey?.surveySections, {
                              [sectionIndex]: { surveyType: { $set: v.target.value } },
                            }),
                          })
                        }
                      >
                        <option value={'single'}>{'Single Choice'}</option>
                        <option value={'multiple'}>{'Multiple Choice'}</option>
                        <option value={'rate'}>{'Rate'}</option>
                        <option value={'openend'}>{'OpenEnd Question'}</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={2}>
                    <FormGroup>
                      <Label>Hidden</Label>
                      <Input
                        type="select"
                        name="select"
                        value={section.isHided}
                        onChange={(v) =>
                          setSurvey({
                            ...survey,
                            surveySections: update(survey?.surveySections, {
                              [sectionIndex]: { isHided: { $set: v.target.value === 'true' } },
                            }),
                          })
                        }
                      >
                        <option value={true}>{'True'}</option>
                        <option value={false}>{'False'}</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={1}>
                    <FormGroup>
                      <Label>Seq.</Label>
                      <Input
                        type="number"
                        placeholder="Sequence"
                        value={section.sequence}
                        onChange={(v) =>
                          setSurvey({
                            ...survey,
                            surveySections: update(survey?.surveySections, {
                              [sectionIndex]: { sequence: { $set: v.target.value } },
                            }),
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ButtonGroup className="d-flex">
                      <Button
                        color="primary"
                        onClick={(v) =>
                          setSurvey({
                            ...survey,
                            surveySections: update(survey?.surveySections, {
                              [sectionIndex]: {
                                surveyQuestions: {
                                  $push: [
                                    {
                                      question: {
                                        en: '',
                                      },
                                      sequence: 0,
                                      isHided: false,
                                    },
                                  ],
                                },
                              },
                            }),
                          })
                        }
                      >
                        Add Question
                      </Button>
                      <Button
                        color="danger"
                        onClick={async (v) => {
                          const result = await popUpContext.onOpen('Are you confirm to remove this section?');
                          console.log({ result });
                          if (result) {
                            setSurvey({
                              ...survey,
                              surveySections: update(survey?.surveySections, {
                                $splice: [[sectionIndex, 1]],
                              }),
                            });
                          }
                        }}
                      >
                        Remove Section
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {(section?.surveyQuestions || []).map((question, questionIndex) => {
                  return (
                    <Row key={questionIndex}>
                      <Col sm={12} md={8}>
                        <FormGroup>
                          <Label>Question {questionIndex + 1}</Label>
                          <Input
                            className="mb-2"
                            placeholder="Question"
                            value={question.question.en}
                            onChange={(v) =>
                              setSurvey({
                                ...survey,
                                surveySections: update(survey?.surveySections, {
                                  [sectionIndex]: {
                                    surveyQuestions: {
                                      [questionIndex]: { question: { en: { $set: v.target.value } } },
                                    },
                                  },
                                }),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={12} md={2}>
                        <FormGroup>
                          <Label>Hidden</Label>
                          <Input
                            type="select"
                            name="select"
                            value={question.isHided}
                            onChange={(v) =>
                              setSurvey({
                                ...survey,
                                surveySections: update(survey?.surveySections, {
                                  [sectionIndex]: {
                                    surveyQuestions: {
                                      [questionIndex]: { isHided: { $set: v.target.value === 'true' } },
                                    },
                                  },
                                }),
                              })
                            }
                          >
                            <option value={true}>{'True'}</option>
                            <option value={false}>{'False'}</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm={12} md={1}>
                        <FormGroup>
                          <Label>Seq.</Label>
                          <Input
                            type="number"
                            placeholder="Sequence"
                            value={question.sequence}
                            onChange={(v) =>
                              setSurvey({
                                ...survey,
                                surveySections: update(survey?.surveySections, {
                                  [sectionIndex]: {
                                    surveyQuestions: {
                                      [questionIndex]: { sequence: { $set: v.target.value } },
                                    },
                                  },
                                }),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={12} md={1} className="align-self-end">
                        <FormGroup>
                          <Button
                            color="danger"
                            block
                            onClick={async () => {
                              const result = await popUpContext.onOpen('Are you confirm to remove this question?');
                              if (result) {
                                setSurvey({
                                  ...survey,
                                  surveySections: update(survey?.surveySections, {
                                    [sectionIndex]: {
                                      surveyQuestions: {
                                        $splice: [[questionIndex, 1]],
                                      },
                                    },
                                  }),
                                });
                              }
                            }}
                          >
                            X
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  );
                })}
              </CardBody>
            </Card>
          );
        })}
      </div>
      <Button style={{ position: 'fixed', left: 0, bottom: 0 }} color="primary" block onClick={saveSurvey}>
        Save
      </Button>
    </>
  );
}
