import React, { useState, useEffect } from 'react';
import {
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';
import { fetchWithGet, fetchWithPost } from '../../httpHelper';
import MUIDataTable from 'mui-datatables';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import { Loader } from '../../components';

export default function Vask() {
  const vaskType = [
    { value: 'values', label: 'Values' },
    { value: 'attitudes', label: 'Attitudes' },
    { value: 'skills', label: 'Skills' },
    { value: 'knowledge', label: 'Knowledge' },
  ];
  const [vask, setVask] = useState([]);
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState(vaskType[0].value);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const columns = ['#', 'ZH', 'EN', 'Sequence', 'Hided'];
  const options = {
    selectableRows: 'none',
    download: false,
    onRowClick: (rowData, rowMeta) => {
      toggle(vask.filter((a) => a.type === activeTab)[rowMeta.dataIndex]);
    },
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };

  const [id, setId] = useState('');
  const [type, setType] = useState('');
  const [zh, setZh] = useState('');
  const [en, setEn] = useState('');
  const [isHided, setIsHided] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const [sequence, setSequence] = useState(0);

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/content/vaskConfig');
    setVask(result);
    setData(
      result
        .filter((a) => a.type === activeTab)
        .map((v, i) => [i + 1, v.zh, v.en, v.sequence, v.isHided ? 'True' : 'False'])
    );
    setIsLoading(false);
  };

  useEffect(() => {
    setData(
      vask
        .filter((a) => a.type === activeTab)
        .map((v, i) => [i + 1, v.zh, v.en, v.sequence, v.isHided ? 'True' : 'False'])
    );
  }, [activeTab]);

  const toggle = (v) => {
    if (v.id) {
      setId(v.id);
      setType(v.type);
      setZh(v.zh);
      setEn(v.en);
      setIsHided(v.isHided);
      setIsOther(v.isOther);
      setSequence(v.sequence);
    } else {
      setId('');
      setZh('');
      setEn('');
      setType(vaskType[0]);
      setIsHided(false);
      setIsOther(false);
      setSequence(0);
      fetchApi();
    }
    setModal(!modal);
  };
  const saveVask = async (context) => {
    if (zh && en) {
      const result = await fetchWithPost('/content/vaskConfig', {
        id,
        type,
        zh,
        en,
        isHided,
        isOther,
        sequence,
      });
      if (result && result.status === 400) {
        context.setAlert(result.data.message, 'danger');
        setTimeout(() => context.closeAlert(), 2000);
      } else {
        context.setAlert('Successfully updated', 'success');
        setTimeout(() => context.closeAlert(), 2000);
      }
    } else {
      context.setAlert('Please fill in all the blank', 'danger');
      setTimeout(() => context.closeAlert(), 2000);
    }
    toggle();
  };

  return (
    <PageAlertContext.Consumer>
      {(context) => (
        <div>
          <div className="text-right mb-2">
            <Button color="primary" onClick={toggle}>
              Add
            </Button>
            <Modal centered={true} isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>
                <b>VASK Config</b>
              </ModalHeader>
              <ModalBody>
                <div className="mb-2">
                  <FormGroup>
                    <Label>Type</Label>
                    <Input type="select" name="select" value={type} onChange={(v) => setType(v)}>
                      {vaskType.map((v, i) => (
                        <option key={i} value={v.value}>
                          {v.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>Name</Label>
                    <Input placeholder="中文" value={zh} onChange={(v) => setZh(v.target.value)} />
                  </FormGroup>
                  <FormGroup>
                    <Input placeholder="English" value={en} onChange={(v) => setEn(v.target.value)} />
                  </FormGroup>
                  <FormGroup>
                    <Label>Hidden</Label>
                    <Input
                      type="select"
                      name="select"
                      value={isHided}
                      onChange={(v) => setIsHided(v.target.value === 'true')}
                    >
                      <option value={true}>{'True'}</option>
                      <option value={false}>{'False'}</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>Sequence</Label>
                    <Input
                      type="number"
                      placeholder="Sequence"
                      value={sequence}
                      onChange={(v) => setSequence(v.target.value)}
                    />
                  </FormGroup>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => saveVask(context)}>
                  {id ? 'Save' : 'Create'}
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <Card>
            <CardBody>
              <Nav tabs>
                {vaskType.map((v, i) => (
                  <NavItem key={i}>
                    <NavLink
                      href="#"
                      className={`${activeTab === v.value ? 'active' : ''}`}
                      onClick={() => setActiveTab(v.value)}
                    >
                      {v.label}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <MUIDataTable data={data} columns={columns} options={options} />
            </CardBody>
          </Card>
        </div>
      )}
    </PageAlertContext.Consumer>
  );
}
