import React, { useState, useEffect, useContext } from 'react';
import { Card, CardBody, Button, Input, FormGroup, Label, Row, Col } from 'reactstrap';
import { fetchWithGet, fetchWithPost, fetchWithFormData } from '../../httpHelper';

import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import { Loader } from '../../components';

export default function PopUp() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    isHided: false,
    actionText: { en: '', zh: '' },
    thumbnail: { en: '', zh: '' },
  });

  const alertContext = useContext(PageAlertContext);

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/content/getPopUp');
    setData(
      result[0] || {
        isHided: false,
        actionText: { en: '', zh: '' },
        thumbnail: { en: '', zh: '' },
      }
    );
    setIsLoading(false);
  };

  const _save = async () => {
    const { thumbnail, actionText } = data;

    if (thumbnail && actionText.en && actionText.zh) {
      const result = await fetchWithPost('/content/updatePopUp', data);
      if (result) {
        if (result.status === 400) {
          alertContext.setAlert(result.data.message, 'danger');
        } else {
          alertContext.setAlert('Successfully updated', 'success');
        }
      }
    } else {
      alertContext.setAlert('Please fill in all the blank', 'danger');
    }
  };

  const uploadThumbnail = async (v) => {
    const { id, files } = v.target;

    if (files.length > 0) {
      const result = await fetchWithFormData('/content/uploadImage', { location: 'blog', image: files[0] });
      if (result) {
        if (id === 'imageZh') {
          setData({ ...data, thumbnail: { en: data.thumbnail.en, zh: result.data.link } });
        } else if (id === 'imageEn') {
          setData({ ...data, thumbnail: { zh: data.thumbnail.zh, en: result.data.link } });
        }
      }
    }
  };

  return (
    <Card>
      <CardBody>
        {isLoading ? (
          <Loader type={'spin'} />
        ) : (
          <>
            <FormGroup>
              <Label>Hidden*</Label>
              <Input
                type="select"
                name="select"
                value={data.isHided}
                onChange={(v) => setData({ ...data, isHided: v.target.value === 'true' })}
              >
                <option value={true}>{'True'}</option>
                <option value={false}>{'False'}</option>
              </Input>
            </FormGroup>
            <Row>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label>Thumbnail (Chinese)*(Max width 760px)</Label>
                  <Input type="file" placeholder="Click here to upload" id="imageZh" onChange={uploadThumbnail} />
                  {!!data.thumbnail.zh ? (
                    <img src={data.thumbnail.zh} alt={'thumbnail zh'} />
                  ) : (
                    <div>No image uploaded</div>
                  )}
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label>Thumbnail (English)*(Max width 760px)</Label>
                  <Input type="file" placeholder="Click here to upload" id="imageEn" onChange={uploadThumbnail} />
                  {!!data.thumbnail.en ? (
                    <img src={data.thumbnail.en} alt={'thumbnail en'} />
                  ) : (
                    <div>No image uploaded</div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label className="mb-2">Button Text (Chinese)</Label>
                  <Input
                    value={data.actionText.zh}
                    onChange={(v) => setData({ ...data, actionText: { ...data.actionText, zh: v.target.value } })}
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label className="mb-2">Button Text (English)</Label>
                  <Input
                    value={data.actionText.en}
                    onChange={(v) => setData({ ...data, actionText: { ...data.actionText, en: v.target.value } })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label className="mb-2">Action (Url)</Label>
              <Input
                placeholder="Url"
                value={data.route}
                onChange={(v) => setData({ ...data, route: v.target.value })}
              />
            </FormGroup>
            <Button color="primary" block onClick={_save}>
              Save
            </Button>
          </>
        )}
      </CardBody>
    </Card>
  );
}
