import React, { useState, useEffect } from 'react';
import {
  ButtonGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { fetchWithGet, fetchWithPost, fetchWithFormData } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import MUIDataTable from 'mui-datatables';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import { Loader } from '../../components';

export default function Quiz() {
  let { path, url } = useRouteMatch();
  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <QuizSectionPage rootPath={url} />
        </Route>
        <Route path={`${path}/:quizSection/create`}>
          <QuizEdit rootPath={url} />
        </Route>
        <Route path={`${path}/:quizSection/:id`}>
          <QuizEdit rootPath={url} />
        </Route>
        <Route path={`${path}/:quizSection`}>
          <QuizDetailPage rootPath={url} />
        </Route>
      </Switch>
    </Router>
  );
}

function QuizSectionPage({ rootPath }) {
  const history = useHistory();
  const [quiz, setQuiz] = useState([]);
  const [data, setData] = useState([]);
  const [id, setId] = useState('');
  const [zh, setZh] = useState('');
  const [en, setEn] = useState('');
  const [cuLink, setCuLink] = useState('');
  const [isHided, setIsHided] = useState(false);
  const [sequence, setSequence] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    '#',
    'Section Name',
    'Hided',
    'Sequence',
    {
      name: 'Actions',
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
          return (
            <ButtonGroup>
              <Button color="primary" outline onClick={() => toggle(quiz[rowIndex])}>
                Edit Section
              </Button>
              <Button color="primary" outline onClick={() => history.push(`${rootPath}/${quiz[rowIndex].id}`)}>
                Go to Quizs
              </Button>
            </ButtonGroup>
          );
        },
      },
    },
  ];
  const options = {
    selectableRows: 'none',
    print: false,
    download: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };

  const [modal, setModal] = useState(false);
  const toggle = (v) => {
    if (v && v.id) {
      setId(v.id);
      setEn(v.en);
      setZh(v.zh);
      setCuLink(v.cuLink);
      setIsHided(v.isHided);
      setSequence(v.sequence);
    } else {
      setId('');
      setZh('');
      setEn('');
      setCuLink('');
      setIsHided(false);
      setSequence(0);
      fetchApi();
    }
    setModal(!modal);
  };

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/content/getAllMcs');
    if (result) {
      setQuiz(result);
      setData(result.map((v, i) => [i + 1, v.en + '\n' + v.zh, v.isHided ? 'True' : 'False', v.sequence]));
    }
    setIsLoading(false);
  };

  const save = async (context) => {
    if (zh && en) {
      const result = await fetchWithPost('/content/editMcSection', {
        id,
        zh,
        en,
        cuLink,
        isHided,
        sequence,
      });
      if (result && result.status === 400) {
        context.setAlert(result.data.message, 'danger');
        setTimeout(() => context.closeAlert(), 2000);
      } else {
        context.setAlert('Successfully updated', 'success');
        setTimeout(() => context.closeAlert(), 2000);
      }
    } else {
      context.setAlert('Please fill in all the blank', 'danger');
      setTimeout(() => context.closeAlert(), 2000);
    }
    toggle();
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Quiz Section</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <PageAlertContext.Consumer>
        {(context) => (
          <div className="text-right mb-2">
            <Button color="primary" onClick={toggle}>
              Add Quiz Section
            </Button>
            <Modal centered={true} isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>
                <b>{id ? 'Edit' : 'Add'} Quiz Section</b>
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label className="mb-2">Name</Label>
                  <Input className="mb-2" placeholder="中文" value={zh} onChange={(v) => setZh(v.target.value)} />
                  <Input placeholder="English" value={en} onChange={(v) => setEn(v.target.value)} />
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">CU Link</Label>
                  <Input placeholder="Link" value={cuLink} onChange={(v) => setCuLink(v.target.value)} />
                </FormGroup>
                <FormGroup>
                  <Label>Hidden</Label>
                  <Input
                    type="select"
                    name="select"
                    value={isHided}
                    onChange={(v) => setIsHided(v.target.value === 'true')}
                  >
                    <option value={true}>{'True'}</option>
                    <option value={false}>{'False'}</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label>Sequence</Label>
                  <Input
                    type="number"
                    placeholder="Sequence"
                    value={sequence}
                    onChange={(v) => setSequence(v.target.value)}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => save(context)}>
                  {id ? 'Edit' : 'Add'}
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        )}
      </PageAlertContext.Consumer>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}

function QuizDetailPage({ rootPath }) {
  let { quizSection } = useParams();
  let { url } = useRouteMatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [section, setSection] = useState({});
  const [quiz, setQuiz] = useState([]);
  const [data, setData] = useState([]);

  const columns = ['#', 'Question En', 'Question Zh', 'Sequence'];
  const options = {
    selectableRows: 'none',
    download: false,
    onRowClick: (rowData, rowMeta) => {
      history.push(`${rootPath}/${quizSection}/${quiz[rowMeta.dataIndex].id}`);
    },
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };

  useEffect(() => {
    fetchQuiz();
  }, []);
  const fetchQuiz = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/content/getAllMcs');
    if (result) {
      const mapped = result.find((v) => v.id === quizSection);
      setSection(mapped);
      setQuiz(mapped.questions);
      setData(mapped.questions.map((v, i) => [i + 1, v.question.en, v.question.zh, v.sequence]));
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Quiz Section</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`${url}/${quizSection}`}>{!_.isEmpty(section) ? `${section.zh}` : quizSection}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="text-right mb-2">
        <Button color="primary" onClick={() => history.push(`${rootPath}/${quizSection}/create`)}>
          Add
        </Button>
      </div>
      {quizSection ? (
        <div>
          <hr />
          <Label>Link</Label>
          <Row>
            <Col sm={8}>
              <Input value={`https://candidate.jcprocruitc.org.hk/quiz/item?id=${quizSection}`} disabled={true} />
            </Col>
            <Col sm={4}>
              <Button
                color="primary"
                onClick={() =>
                  navigator.clipboard.writeText(`https://candidate.jcprocruitc.org.hk/quiz/item?id=${quizSection}`)
                }
                block
              >
                Copy To Clipboard
              </Button>
            </Col>
          </Row>
          <hr />
        </div>
      ) : (
        <div />
      )}
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}

function QuizEdit({ rootPath }) {
  let { id, quizSection } = useParams();
  let { url } = useRouteMatch();
  const history = useHistory();

  const [section, setSection] = useState({});

  const [quizId, setQuizId] = useState(id);
  const [questionZh, setQuestionZh] = useState('');
  const [questionEn, setQuestionEn] = useState('');
  const [answers, setAnswers] = useState([]);
  const [isHided, setIsHided] = useState(false);
  const [sequence, setSequence] = useState(0);

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    const section = await fetchWithGet('/content/getAllMcs');
    if (section) {
      const mapped = section.find((v) => v.id === quizSection);
      setSection(mapped);
      if (id) {
        setQuizId(id);
        const quiz = mapped.questions.find((v) => v.id === id);
        if (quiz) {
          setQuestionZh(quiz.question.zh);
          setQuestionEn(quiz.question.en);
          setAnswers(quiz.answer);
          setIsHided(quiz.isHided);
          setSequence(quiz.sequence);
        }
      }
    }
  };

  const saveQuiz = async (context) => {
    if (questionEn && questionZh && answers.length > 0) {
      const result = await fetchWithPost('/content/editMc', {
        id: quizId,
        question: {
          zh: questionZh,
          en: questionEn,
        },
        answer: answers.map((a) => {
          let obj = { ...a, id: a._id };
          delete obj._id;
          return obj;
        }),
        isHided,
        sequence,
        section: quizSection,
      });

      if (result && result.status === 400) {
        context.setAlert(result.data.message, 'danger');
        setTimeout(() => context.closeAlert(), 2000);
      } else {
        context.setAlert('Successfully updated', 'success');
        setTimeout(() => context.closeAlert(), 2000);
        history.push(`${rootPath}/${quizSection}`);
      }
    } else {
      context.setAlert('Please fill in all the blank', 'danger');
      setTimeout(() => context.closeAlert(), 2000);
    }
  };

  return (
    <div>
      <div className="mb-2">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={`${rootPath}`}>Quiz Section</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`${rootPath}/${quizSection}`}>{section.zh}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`${rootPath}/${quizSection}/${id}`}>{id ? questionZh : 'Create Quiz'}</Link>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
      <PageAlertContext.Consumer>
        {(context) => (
          <Card>
            <CardBody>
              <div className="mb-2">
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label className="mb-2">Chinese Question</Label>
                      <Input
                        className="mb-2"
                        placeholder="中文"
                        value={questionZh}
                        onChange={(v) => setQuestionZh(v.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label className="mb-2">English Question</Label>
                      <Input placeholder="English" value={questionEn} onChange={(v) => setQuestionEn(v.target.value)} />
                    </FormGroup>
                  </Col>
                </Row>
                {answers.map((answer, ansIndex) => {
                  return (
                    <Row>
                      <Col sm={1}>Ans {ansIndex + 1}</Col>
                      <Col sm={8}>
                        <FormGroup>
                          <Label>Answer</Label>
                          <Input
                            placeholder="English"
                            value={answer && answer.en ? answer.en : ''}
                            onChange={(v) => {
                              setAnswers(_.assign([], answers, _.assign(answers[ansIndex], { en: v.target.value })));
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={2}>
                        <FormGroup>
                          <Label>Correct</Label>
                          <Input
                            type="select"
                            name="select"
                            value={answer && answer.isCorrect ? answer.isCorrect : false}
                            onChange={(v) => {
                              setAnswers(
                                _.assign([], answers, _.assign(answers[ansIndex], { isCorrect: v.target.value }))
                              );
                            }}
                          >
                            <option value={true}>{'True'}</option>
                            <option value={false}>{'False'}</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm={1}>
                        <Button
                          color="danger"
                          onClick={() => setAnswers(_.filter(answers, (a, ai) => ai !== ansIndex))}
                          block
                        >
                          X
                        </Button>
                      </Col>
                      <hr />
                    </Row>
                  );
                })}
                <Button color="primary" onClick={() => setAnswers([...answers, { isCorrect: false }])} block>
                  Add Answer
                </Button>
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>Hidden</Label>
                      <Input
                        type="select"
                        name="select"
                        value={isHided}
                        onChange={(v) => setIsHided(v.target.value === 'true')}
                      >
                        <option value={true}>{'True'}</option>
                        <option value={false}>{'False'}</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>Sequence</Label>
                      <Input
                        type="number"
                        placeholder="Sequence"
                        value={sequence}
                        onChange={(v) => setSequence(v.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={() => saveQuiz(context)} block>
                {id ? 'Save' : 'Create'}
              </Button>
            </CardFooter>
          </Card>
        )}
      </PageAlertContext.Consumer>
    </div>
  );
}
