import React, { useState, useEffect } from 'react';
import { Input, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { fetchWithGet, fetchWithPost, fetchWithFormData } from '../../httpHelper';
import _ from 'lodash';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import { CSVLink } from 'react-csv';

import { Loader } from '../../components';

export default function ContactUs(props) {
  let { path, url } = useRouteMatch();

  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <ContactUsList rootPath={url} />
        </Route>
      </Switch>
    </Router>
  );
}

function ContactUsList({ rootPath }) {
  const [contactUs, setContactUs] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    '#',
    'Name',
    'Email',
    'Phone No',
    'Role',
    'Track',
    {
      name: 'Status',
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
          return (
            <Input
              type="select"
              name="select"
              value={contactUs[rowIndex].status}
              onChange={(a) => _onChange(contactUs[rowIndex], a.target.value)}
            >
              <option value={'Pending'}>{'Pending'}</option>
              <option value={'Work In Progress'}>{'Work In Progress'}</option>
              <option value={'Solved'}>{'Solved'}</option>
            </Input>
          );
        },
      },
    },
  ];
  const options = {
    selectableRows: 'none',
    download: false,
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };

  const exportHeader = [
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    { label: 'PhoneNo', key: 'phoneNo' },
    { label: 'Role', key: 'role' },
    { label: 'Track', key: 'track' },
    { label: 'Status', key: 'status' },
    { label: 'Create DateTime', key: 'createdAt' },
  ];

  const exportData = contactUs.map((v, i) => {
    return {
      firstName: v.firstName,
      lastName: v.lastName,
      email: v.email,
      phoneNo: v.phoneNo,
      role: v.otherRole || v.role,
      track: v.track.toString(),
      status: v.status,
      createdAt: moment(v.createdAt).format('YYYY-MM-DD hh:mm'),
    };
  });

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/dashboard/getContactUs');
    if (result) {
      setContactUs(result);
      setData(
        result.map((v, i) => [
          i + 1,
          v.firstName + ' ' + v.lastName,
          v.email,
          v.phoneNo,
          v.otherRole || v.role,
          v.track.toString(),
        ])
      );
    }
    setIsLoading(false);
  };

  const _onChange = async (row, status) => {
    const result = await fetchWithPost('/dashboard/updateContactUsStatus', { id: row.id, status });
    if (result) {
      fetchApi();
    }
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Contact Us</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="text-right mb-2">
        <CSVLink filename={`Contact-Us-Export`} data={exportData} headers={exportHeader}>
          Export
        </CSVLink>
      </div>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}
