import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import React from 'react';

const StepperCard = (props) => {
  const { application, stayStep } = props;

  return (
    <div className="w-100">
      <div className="scrollable">
        <Stepper activeStep={stayStep != null ? stayStep : getActiveStep(application)} alternativeLabel>
          {applicationSteps.map((label, li) => {
            return (
              <Step key={li} className="stepppp">
                <StepLabel key={li}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
    </div>
  );
};

export default StepperCard;

const applicationSteps = [
  'Application',
  'Self-discovery',
  'Video Recording',
  'Group Interview',
  'Final Interview',
  'Job Offer',
];

export const getActiveStep = (application) => {
  let step = 0;
  if (!application) {
    return step;
  }
  if (application && application.id && application.status !== 'save') {
    console.log('application');
    step += 1;
  }
  if (application.vask && application.vask.status !== 'save') {
    console.log('vask');
    step += 1;
  }
  if (
    application.videoInterview &&
    application.videoInterview.status === 'finished' &&
    application.vask?.openQuestionAnswer &&
    application.vask.openQuestionAnswer
      .map((a) => {
        return a.answer;
      })
      .filter((a) => {
        return a !== null && a !== '';
      }).length === 2 &&
    application.groupInterview &&
    ![null, 'pending'].includes(application.groupInterview?.status)
  ) {
    console.log('vask & video');
    step += 1;
  }
  if (
    application.groupInterview &&
    application.groupInterview.status === 'finished' &&
    application.finalInterview &&
    application.finalInterview.length > 0 &&
    application.finalInterview.filter((fi) => {
      return ['release', 'finished'].includes(fi.status);
    }).length > 0
  ) {
    console.log('group & final');
    step += 1;
  }
  if (
    application.offer &&
    application.offer.length > 0 &&
    application.offer.filter((of) => {
      return ['release', 'accept', 'reject'].includes(of.status);
    }).length > 0
  ) {
    console.log('offer');
    step += 1;
  }
  // if (application.finalInterview && application.finalInterview.length > 0) {
  //   step++;
  // }
  return step;
};
