import React, { useEffect, useState } from 'react';
import { Card, CardTitle, Row, Col } from 'reactstrap';
import { Bar, Line } from 'react-chartjs-2';
import moment from 'moment';
import { fetchWithGet } from '../../../httpHelper';
import { Loader } from '../../../components';
import AnalyticsWidget from './AnalyticsWidget';
import { CSVLink, CSVDownload } from 'react-csv';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
ChartJS.register(zoomPlugin, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

export default function ConvenorStatisticWidget() {
  const [isLoading, setIsLoading] = useState(false);
  const [statistic, setStatistic] = useState({});

  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/report/getOverviewByConvenor');
    setStatistic(result);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchApi();
  }, []);

  const educationLevels = [
    'Master’s Degree or above',
    'Bachelor’s Degree',
    'Associate Degree',
    'Higher Diploma / Advanced Diploma',
    'HKDSE',
    'The International Baccalaureate',
    'Diploma of Foundation Studies',
    'Diploma Yi Jin',
    'Others',
  ];

  const stageList = ['Application', 'VASK', 'Video Interview', 'Group Interview', 'Final Interview', 'Offer'];

  const chartColors = {
    red: 'rgb(233, 30, 99)',
    danger: 'rgb(233, 30, 99)',
    dangerTransparent: 'rgba(233, 30, 99, .8)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 180, 0)',
    green: 'rgb(34, 182, 110)',
    blue: 'rgb(68, 159, 238)',
    primary: 'rgb(68, 159, 238)',
    primaryTransparent: 'rgba(68, 159, 238, .8)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(201, 203, 207)',

    primaryShade1: 'rgb(68, 159, 238)',
    primaryShade2: 'rgb(23, 139, 234)',
    primaryShade3: 'rgb(14, 117, 202)',
    primaryShade4: 'rgb(9, 85, 148)',
    primaryShade5: 'rgb(12, 70, 117)',
  };

  return (
    <div>
      <Card body>
        {isLoading || !statistic?.statistics ? (
          <Loader small={true} />
        ) : (
          <>
            <Row>
              <Col lg={3} md={6} sm={6} xs={6} className="mt-3">
                <CardTitle className="text-uppercase text-muted h6 mb-0">Total Application</CardTitle>
                <div className="h2 font-weight-bold mb-0">{statistic.totalApplications}</div>
              </Col>
            </Row>
            <Row>
              {statistic.statistics.map((cohort) => {
                return (
                  <Col lg={3} md={6} sm={6} xs={6} className="mt-3">
                    <CardTitle className="text-uppercase text-muted h6 mb-0">{cohort.cohortDetail.en}</CardTitle>
                    {cohort.applications}
                  </Col>
                );
              })}
            </Row>
          </>
        )}
      </Card>
      <Row>
        <Col sm={12} md={6}>
          <Card body>
            {isLoading || !statistic?.statistics ? (
              <Loader small={true} />
            ) : (
              <>
                <Row>
                  <Col>
                    <div style={{ lineHeight: 0.5 }}>
                      <span style={{ fontSize: 10 }}>
                        *Click the Cohort boxes under the title to hide corresponding data. Click again to resume.
                      </span>
                    </div>
                  </Col>
                  <Col style={{ textAlign: 'right' }}>
                    <CSVLink
                      filename={'Applicant Status.csv'}
                      headers={[
                        {
                          label: 'Cohort',
                          key: 'cohort',
                        },
                        ...stageList.map((v) => {
                          return {
                            label: v,
                            key: v,
                          };
                        }),
                        {
                          label: 'Total',
                          key: 'total',
                        },
                      ]}
                      data={statistic.statistics.map((v) => {
                        return {
                          cohort: v.cohortDetail.en,
                          ...v.stage,
                          total: Object.values(v.stage).reduce((p, c) => p + c, 0),
                        };
                      })}
                    >
                      {'Download'}
                    </CSVLink>
                  </Col>
                </Row>
                <Bar
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: true,
                        text: 'Application Status',
                      },
                    },
                  }}
                  data={{
                    labels: stageList,
                    datasets: statistic.statistics.map((s) => {
                      return {
                        label: s.cohortDetail.en,
                        data: stageList.map((cStatus) => {
                          return s.stage[cStatus] || 0;
                        }),
                        backgroundColor: [
                          chartColors.red,
                          chartColors.orange,
                          chartColors.green,
                          chartColors.blue,
                          chartColors.purple,
                          chartColors.grey,
                        ][statistic.statistics.indexOf(s)],
                      };
                    }),
                  }}
                />
              </>
            )}
          </Card>
        </Col>
        <Col sm={12} md={6}>
          <Card body>
            {isLoading || !statistic?.statistics ? (
              <Loader small={true} />
            ) : (
              <>
                <Row>
                  <Col>
                    <div style={{ lineHeight: 0.5 }}>
                      <span style={{ fontSize: 10 }}>
                        *Click the Cohort boxes under the title to hide corresponding data. Click again to resume.
                      </span>
                    </div>
                  </Col>
                  <Col style={{ textAlign: 'right' }}>
                    <CSVLink
                      filename={'Education Background of Submitted Application.csv'}
                      headers={[
                        {
                          label: 'Cohort',
                          key: 'cohort',
                        },
                        ...educationLevels.map((v) => {
                          return {
                            label: v,
                            key: v,
                          };
                        }),
                        {
                          label: 'Total',
                          key: 'total',
                        },
                      ]}
                      data={statistic.statistics.map((v) => {
                        return {
                          cohort: v.cohortDetail.en,
                          ...v.education,
                          total: Object.values(v.education).reduce((p, c) => p + c, 0),
                        };
                      })}
                    >
                      {'Download'}
                    </CSVLink>
                  </Col>
                </Row>
                <Bar
                  options={{
                    indexAxis: 'y',
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: true,
                        text: 'Education Background of Submitted Application',
                      },
                    },
                  }}
                  data={{
                    labels: educationLevels,
                    datasets: statistic.statistics.map((s) => {
                      return {
                        label: s.cohortDetail.en,
                        data: educationLevels.map((level) => {
                          return s.education[level] || 0;
                        }),
                        backgroundColor: [
                          chartColors.red,
                          chartColors.orange,
                          chartColors.green,
                          chartColors.blue,
                          chartColors.purple,
                          chartColors.grey,
                        ][statistic.statistics.indexOf(s)],
                      };
                    }),
                  }}
                />
              </>
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12}>
          <Card body>
            {isLoading || !statistic?.statistics ? (
              <Loader small={true} />
            ) : (
              <>
                <Row>
                  <Col>
                    <div style={{ lineHeight: 0.5 }}>
                      <span style={{ fontSize: 10 }}>
                        *Click the Cohort boxes under the title to hide corresponding data. Click again to resume.
                      </span>
                    </div>
                  </Col>
                  <Col style={{ textAlign: 'right' }}>
                    <CSVLink
                      filename={'Sector.csv'}
                      headers={[
                        {
                          label: 'Cohort',
                          key: 'cohort',
                        },
                        ...statistic.streamDetail.map((v) => {
                          return {
                            label: v.en,
                            key: v.en,
                          };
                        }),
                      ]}
                      data={statistic.statistics.map((v) => {
                        return {
                          cohort: v.cohortDetail.en,
                          ...v.stream,
                        };
                      })}
                    >
                      {'Download'}
                    </CSVLink>
                  </Col>
                </Row>
                <Bar
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: true,
                        text: 'Sector',
                      },
                    },
                  }}
                  data={{
                    labels: statistic.streamDetail.map((v) => v.en),
                    datasets: statistic.statistics.map((s) => {
                      return {
                        label: s.cohortDetail.en,
                        data: statistic.streamDetail.map((cStatus) => {
                          return s.stream[cStatus.en] || 0;
                        }),
                        backgroundColor: [
                          chartColors.red,
                          chartColors.orange,
                          chartColors.green,
                          chartColors.blue,
                          chartColors.purple,
                          chartColors.grey,
                        ][statistic.statistics.indexOf(s)],
                      };
                    }),
                  }}
                />
              </>
            )}
          </Card>
        </Col>
        <Col sm={12} md={12}>
          <Card body>
            {isLoading || !statistic?.statistics ? (
              <Loader small={true} />
            ) : (
              <>
                <Row>
                  <Col>
                    <div style={{ lineHeight: 0.5 }}>
                      <span style={{ fontSize: 10 }}>
                        *Click the Cohort boxes under the title to hide corresponding data. Click again to resume.
                      </span>
                    </div>
                  </Col>
                  <Col style={{ textAlign: 'right' }}>
                    <CSVLink
                      filename={'Job Roles.csv'}
                      headers={[
                        {
                          label: 'Cohort',
                          key: 'cohort',
                        },
                        ...statistic.roleDetail.map((v) => {
                          return {
                            label: v.en,
                            key: v.en,
                          };
                        }),
                      ]}
                      data={statistic.statistics.map((v) => {
                        return {
                          cohort: v.cohortDetail.en,
                          ...v.role,
                        };
                      })}
                    >
                      {'Download'}
                    </CSVLink>
                  </Col>
                </Row>
                <Bar
                  options={{
                    indexAxis: 'y',
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: true,
                        text: 'Selected job roles by candidates',
                      },
                    },
                  }}
                  data={{
                    labels: statistic.roleDetail.map((v) => v.en),
                    datasets: statistic.statistics.map((s) => {
                      return {
                        label: s.cohortDetail.en,
                        data: statistic.roleDetail.map((cStatus) => {
                          return s.role[cStatus.en] || 0;
                        }),
                        backgroundColor: [
                          chartColors.red,
                          chartColors.orange,
                          chartColors.green,
                          chartColors.blue,
                          chartColors.purple,
                          chartColors.grey,
                        ][statistic.statistics.indexOf(s)],
                      };
                    }),
                  }}
                />
              </>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}
