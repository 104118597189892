import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  Button,
  Input,
  FormGroup,
  Label,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
} from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { fetchWithGet, fetchWithPost, fetchWithFormData } from '../../httpHelper';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Select from 'react-select';
import MUIDataTable from 'mui-datatables';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import { Loader } from '../../components';
import moment from 'moment';
import Datetime from 'react-datetime';

export default function Cohort(props) {
  let { path, url } = useRouteMatch();

  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <CohortList rootPath={url} />
        </Route>
        <Route path={`${path}/create`}>
          <CohortEdit rootPath={url} />
        </Route>
        <Route path={`${path}/:id`}>
          <CohortEdit rootPath={url} />
        </Route>
      </Switch>
    </Router>
  );
}

function CohortList({ rootPath }) {
  const history = useHistory();
  const [cohorts, setCohorts] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = ['#', 'ZH', 'EN', 'Period', 'isHided'];
  const options = {
    selectableRows: 'none',
    download: false,
    onRowClick: (rowData, rowMeta) => {
      history.push(`${rootPath}/${cohorts[rowMeta.dataIndex].id}`);
    },
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };
  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/content/cohort');
    if (result) {
      setCohorts(result);
      setData(
        result.map((v, i) => [
          i + 1,
          v.zh,
          v.en,
          `${moment(v.startDatetime).format('YYYY-MM-DD HH:mm')} - ${moment(v.endDatetime).format('YYYY-MM-DD HH:mm')}`,
          v.disabled ? 'True' : 'False',
        ])
      );
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Cohorts</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="text-right mb-2">
        <Button color="primary" onClick={() => history.push(`${rootPath}/create`)}>
          Add
        </Button>
      </div>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}

function CohortEdit({ rootPath }) {
  let { id } = useParams();
  let { url } = useRouteMatch();
  const history = useHistory();

  const [cohortId, setCohortId] = useState(id);
  const [cohorts, setCohorts] = useState([]);
  const [cohortObj, setCohortObj] = useState([]);

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    const cohortList = await fetchWithGet('/content/cohort');
    if (cohortList) {
      setCohorts(
        cohortList.map((v) => {
          return { value: v.id, label: v.en };
        })
      );
    }

    if (id) {
      setCohortId(id);
      const result = await fetchWithGet(`/content/cohort/${id}`);
      if (result) {
        setCohortObj(result);
      }
    }
  };

  const saveCohort = async (context) => {
    if (
      cohortObj.zh &&
      cohortObj.en &&
      cohortObj.startDatetime &&
      cohortObj.endDatetime &&
      cohortObj.interviewStartDatetime &&
      cohortObj.interviewEndDatetime &&
      cohortObj.offerDatetime &&
      cohortObj.trainingStartDatetime &&
      cohortObj.trainingEndDatetime &&
      cohortObj.onboardDatetime &&
      cohortObj.videoEndDatetime &&
      cohortObj.offerAcceptDatetime
    ) {
      const result = await fetchWithPost('/content/cohort', cohortObj);

      if (result && result.status === 400) {
        context.setAlert(result.data.message, 'danger');
        setTimeout(() => context.closeAlert(), 2000);
      } else {
        context.setAlert('Successfully updated', 'success');
        setTimeout(() => context.closeAlert(), 2000);
        history.push(`${rootPath}`);
      }
    } else {
      context.setAlert('Please fill in all the blank', 'danger');
      setTimeout(() => context.closeAlert(), 2000);
    }
  };
  console.log({ cohortObj });
  return (
    <div>
      <div className="mb-2">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={`${rootPath}`}>Cohorts</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`${url}/${id}`}>{id ? 'Edit Cohort' : 'Create Cohort'}</Link>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
      <PageAlertContext.Consumer>
        {(context) => (
          <Card>
            <CardBody>
              <div className="mb-2">
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>Chinese content</Label>
                      <Input
                        placeholder="中文"
                        value={cohortObj.zh}
                        onChange={(v) => setCohortObj({ ...cohortObj, zh: v.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>English content</Label>
                      <Input
                        placeholder="English"
                        value={cohortObj.en}
                        onChange={(v) => setCohortObj({ ...cohortObj, en: v.target.value })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label>Order</Label>
                  <Input
                    placeholder="Order"
                    value={cohortObj.order}
                    onChange={(v) => setCohortObj({ ...cohortObj, order: v.target.value })}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Is Hided</Label>
                  <Input
                    type="select"
                    name="select"
                    value={cohortObj.disabled}
                    onChange={(v) => setCohortObj({ ...cohortObj, disabled: v.target.value === 'true' })}
                  >
                    <option value={true}>{'True'}</option>
                    <option value={false}>{'False'}</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">Start Datetime</Label>
                  <Datetime
                    value={new Date(cohortObj.startDatetime)}
                    onChange={(v) => setCohortObj({ ...cohortObj, startDatetime: v })}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">End Datetime</Label>
                  <Datetime
                    value={new Date(cohortObj.endDatetime)}
                    onChange={(v) => setCohortObj({ ...cohortObj, endDatetime: v })}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">Interview Start Datetime</Label>
                  <Datetime
                    value={new Date(cohortObj.interviewStartDatetime)}
                    onChange={(v) => setCohortObj({ ...cohortObj, interviewStartDatetime: v })}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">Interview End Datetime</Label>
                  <Datetime
                    value={new Date(cohortObj.interviewEndDatetime)}
                    onChange={(v) => setCohortObj({ ...cohortObj, interviewEndDatetime: v })}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">Video End Datetime</Label>
                  <Datetime
                    value={new Date(cohortObj.videoEndDatetime)}
                    onChange={(v) => setCohortObj({ ...cohortObj, videoEndDatetime: v })}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">Offer Datetime</Label>
                  <Datetime
                    value={new Date(cohortObj.offerDatetime)}
                    onChange={(v) => setCohortObj({ ...cohortObj, offerDatetime: v })}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">Offer Accept End Datetime</Label>
                  <Datetime
                    value={new Date(cohortObj.offerAcceptDatetime)}
                    onChange={(v) => setCohortObj({ ...cohortObj, offerAcceptDatetime: v })}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">Training Start Datetime</Label>
                  <Datetime
                    value={new Date(cohortObj.trainingStartDatetime)}
                    onChange={(v) => setCohortObj({ ...cohortObj, trainingStartDatetime: v })}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">Training End Datetime</Label>
                  <Datetime
                    value={new Date(cohortObj.trainingEndDatetime)}
                    onChange={(v) => setCohortObj({ ...cohortObj, trainingEndDatetime: v })}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">Onboard Datetime</Label>
                  <Datetime
                    value={new Date(cohortObj.onboardDatetime)}
                    onChange={(v) => setCohortObj({ ...cohortObj, onboardDatetime: v })}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Neufast Key</Label>
                  <Input
                    placeholder="Neufast Key"
                    value={cohortObj.neufastKey}
                    onChange={(v) => setCohortObj({ ...cohortObj, neufastKey: v.target.value })}
                  />
                </FormGroup>
              </div>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={() => saveCohort(context)} block>
                {id ? 'Save' : 'Create'}
              </Button>
            </CardFooter>
          </Card>
        )}
      </PageAlertContext.Consumer>
    </div>
  );
}
