import React, { useState, useEffect } from 'react';
import {
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  InputGroupAddon,
  InputGroup,
} from 'reactstrap';
import { fetchWithGet, fetchWithPost } from '../../httpHelper';
import MUIDataTable from 'mui-datatables';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import Select from 'react-select';
import { Loader } from '../../components';
import _ from 'lodash';

export default function UserManagement() {
  const colors = [
    { value: '#00ccaa', label: 'Caribbean Green' }, // for CUHK
    { value: '#0d6efd', label: 'Blue' }, // for GENHK
    { value: '#6610f2', label: 'Indigo' },
    { value: '#6f42c1', label: 'Purple' },
    { value: '#d63384', label: 'Pink' },
    { value: '#dc3545', label: 'Red' },
    { value: '#fd7e14', label: 'Orange' },
    { value: '#ffc107', label: 'Yellow' },
    { value: '#198754', label: 'Green' },
    { value: '#4d3900', label: 'Brown' },
    { value: '#0dcaf0', label: 'Cyan' },
    { value: '#236902', label: 'Lincoln Green' },
  ];
  const [selectedColor, setSelectedColor] = useState();

  const roleType = [
    { value: 'admin', label: 'Admin' },
    { value: 'convenor', label: 'Convenor' },
    { value: 'employer', label: 'Employer' },
    { value: 'researcher', label: 'Researcher' },
  ];
  const [user, setUser] = useState([]);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState(roleType[0].value);
  const [scope, setScope] = useState([]);
  const [shortName, setShortName] = useState('');

  const [streams, setStreams] = useState([]);
  const [roles, setRoles] = useState([]);

  const [convenor, setConvenor] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const columns = ['#', 'User Id', 'Name', 'Email', 'Role', 'Scope', 'Convenor', 'Stream'];
  const options = {
    selectableRows: 'none',
    download: true,
    onRowClick: (rowData, rowMeta) => {
      toggle(user[rowMeta.dataIndex]);
    },
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };
  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/dashboard/getDashboardUsers');
    if (result) {
      setUser(result);
      setData(
        result.map((v, i) => [
          i + 1,
          v.userId,
          (v.givenName && v.givenName.en) || '',
          v.email,
          roleType.find((a) => a.value === v.role).label,
          v.scope.map((v) => _.startCase(v)).join(', '),
          (v.parentId && v.parentId.givenName.en) || '',
          (v.stream && v.stream.en) || '',
        ])
      );
    }

    const streamList = await fetchWithGet('/content/streams');
    if (streamList) {
      const map = [];
      if (streamList.length > 0) {
        for (let i = 0; i < streamList.length; i++) {
          if (streamList[i].roles.length > 0) {
            for (let y = 0; y < streamList[i].roles.length; y++) {
              map.push({
                id: `${streamList[i].id}-${streamList[i].roles[y].id}`,
                stream: streamList[i].id,
                role: streamList[i].roles[y].id,
                label: `${streamList[i].en} - ${streamList[i].roles[y].en}`,
                value: streamList[i].roles[y].id,
              });
            }
          }
        }
      }
      setStreams(map);
    }
    setIsLoading(false);
  };

  const toggle = (v) => {
    if (v && v.id) {
      setId(v.id);
      setName((v.givenName && v.givenName.en) || '');
      setEmail(v.email);
      setRole(v.role);
      setRoles(
        (v.roles || []).map((v) => {
          return { value: v.id, label: `${v.parentId.en} - ${v.en}` };
        })
      );
      setScope(
        (v.scope || []).map((v) => {
          return { value: v, label: _.startCase(v) };
        })
      );
      setConvenor((v.parentId && v.parentId.id) || '');
      setShortName(v.shortName);
      setSelectedColor(_.find(colors, (a) => a.value === v.color));
    } else {
      setId('');
      setName('');
      setEmail('');
      setRole(roleType[0].value);
      setScope([]);
      setPassword('');
      setRoles([]);
      setConvenor('');
      setShortName('');
      setSelectedColor('');
      fetchApi();
    }

    setModal(!modal);
  };

  const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const _randomString = () => {
    return Math.random().toString(36).substring(2, 8);
  };

  const editAccount = async (context) => {
    if (name && email && role && scope.length > 0) {
      let result;
      if (scope.map((v) => v.value).includes('employer') && !scope.map((v) => v.value).includes('convenor')) {
        if (convenor) {
          result = await fetchWithPost('/dashboard/account', {
            id,
            email,
            name,
            role,
            parentId: convenor,
            password,
            scope: scope.map((v) => v.value),
          });
        } else {
          context.setAlert('Please select convenor', 'danger');
          setTimeout(() => context.closeAlert(), 2000);
        }
      } else if (scope.map((v) => v.value).includes('convenor')) {
        if (roles.length > 0) {
          result = await fetchWithPost('/dashboard/account', {
            id,
            email,
            name,
            role,
            roles: roles.map((v) => v.value),
            password,
            scope: scope.map((v) => v.value),
            shortName,
            color: selectedColor.value,
          });
        } else {
          context.setAlert('Please select at least one role', 'danger');
          setTimeout(() => context.closeAlert(), 2000);
        }
      } else {
        result = await fetchWithPost('/dashboard/account', {
          id,
          email,
          name,
          role,
          password,
          scope: scope.map((v) => v.value),
          color: selectedColor.value,
        });
      }

      if (result && (result.status === 400 || result.status === 404)) {
        context.setAlert(result.data.message, 'danger');
        setTimeout(() => context.closeAlert(), 2000);
      } else {
        context.setAlert('Successfully updated', 'success');
        setTimeout(() => context.closeAlert(), 2000);
      }
    } else {
      context.setAlert('Please fill in all the blank', 'danger');
      setTimeout(() => context.closeAlert(), 2000);
    }
    toggle();
  };

  const roleOption = (role) => {
    switch (role) {
      case 'convenor':
        return (
          <FormGroup>
            <Label>Select Roles</Label>
            <Select options={streams} value={roles} isMulti onChange={(v) => setRoles(v)} />
          </FormGroup>
        );
      case 'employer':
        return (
          <FormGroup>
            <Label>Select Convenor</Label>
            <Input type="select" name="select" value={convenor} onChange={(v) => setConvenor(v.target.value)}>
              <option value={''}>{'Please select'}</option>
              {user
                .filter((v) => v.role === 'convenor')
                .map((v) => (
                  <option value={v.id}>
                    {v.givenName && v.givenName.en} - {v.email}
                  </option>
                ))}
            </Input>
          </FormGroup>
        );
      default:
        return <div />;
    }
  };

  return (
    <PageAlertContext.Consumer>
      {(context) => (
        <div>
          <div className="text-right mb-2">
            <Button color="primary" onClick={toggle}>
              Create new account
            </Button>
            <Modal centered={true} isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>
                <b>{id ? 'Edit' : 'Create new'} account</b>
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label>Name</Label>
                  <Input placeholder="Name" value={name} onChange={(v) => setName(v.target.value)} />
                </FormGroup>
                <FormGroup>
                  <Label>Email</Label>
                  <Input placeholder="Email" value={email} onChange={(v) => setEmail(v.target.value)} />
                </FormGroup>
                <FormGroup>
                  <Label>Password</Label>
                  <InputGroup>
                    <Input
                      type="input"
                      placeholder="password"
                      value={password}
                      onChange={(v) => setPassword(v.target.value)}
                    />
                    <InputGroupAddon onClick={() => setPassword(_randomString())}>Generate</InputGroupAddon>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Label>Select role</Label>
                  <Input type="select" name="select" value={role} onChange={(v) => setRole(v.target.value)}>
                    {roleType.map((v) => (
                      <option value={v.value}>{v.label}</option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label>Select scopes</Label>
                  <Select options={roleType} value={scope} isMulti onChange={(v) => setScope(v)} />
                </FormGroup>
                {scope.map((v) => v.value).includes('convenor') ? (
                  <>
                    <FormGroup>
                      <Label>Select Roles</Label>
                      <Select options={streams} value={roles} isMulti onChange={(v) => setRoles(v)} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Short Name (Please don't modify it)</Label>
                      <Input
                        type="input"
                        placeholder="Short Name"
                        value={shortName}
                        onChange={(v) => setShortName(v.target.value)}
                      />
                    </FormGroup>
                  </>
                ) : null}
                {scope.map((v) => v.value).includes('employer') && !scope.map((v) => v.value).includes('convenor') ? (
                  <FormGroup>
                    <Label>Select Convenor</Label>
                    <Input type="select" name="select" value={convenor} onChange={(v) => setConvenor(v.target.value)}>
                      <option value={''}>{'Please select'}</option>
                      {user
                        .filter((v) => v.role === 'convenor')
                        .map((v) => (
                          <option value={v.id}>
                            {v.givenName && v.givenName.en} - {v.email}
                          </option>
                        ))}
                    </Input>
                  </FormGroup>
                ) : null}
                {!scope.map((v) => v.value).includes('employer') ? (
                  <FormGroup>
                    <Label className="mb-2">Color (For calendar use)</Label>
                    <Select
                      options={colors}
                      value={selectedColor}
                      onChange={(v) => {
                        setSelectedColor(v);
                      }}
                      styles={{
                        singleValue: (styles, { data }) => ({ ...styles, ...dot(selectedColor.value) }),
                      }}
                      isSearchable={false}
                    />
                  </FormGroup>
                ) : null}
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => editAccount(context)}>
                  {id ? 'Edit' : 'Create'}
                </Button>
              </ModalFooter>
            </Modal>
          </div>

          <MUIDataTable data={data} columns={columns} options={options} />
        </div>
      )}
    </PageAlertContext.Consumer>
  );
}
