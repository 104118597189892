import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Card, CardBody, Button, Table, FormGroup, Input } from 'reactstrap';
import Banner from '../../assets/custom/banner.png';
import Cookies from 'js-cookie';
import _ from 'lodash';
import StatisticWidget from './widgets/StatisticWidget';
import ConvenorStatisticWidget from './widgets/ConvenorStatisticWidget';
import { fetchWithGet, fetchWithPost, fetchWithFormData } from '../../httpHelper';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import PopUpContext from '../../components/components/PopUp/PopUpContext';
import LoadingButton from './widgets/LoadingButton';

const InputFrom = ({ content, files, onClick }) => {
  const alertContext = useContext(PageAlertContext);

  const [inputContent, setInputContent] = useState(content || '');
  const [inputFiles, setInputFiles] = useState(files || []);

  const _onClick = () => {
    onClick(inputContent, inputFiles);
  };

  const upload = async (v) => {
    if (v.target.files.length > 0) {
      const result = await fetchWithFormData('/content/uploadImage', { location: 'blog', image: v.target.files[0] });
      if (result) {
        return result.data;
      }
      // return { link: '', name: v.target.files[0].name };
    }
  };

  return (
    <FormGroup>
      <Input
        type="textarea"
        className="mb-2"
        value={inputContent}
        onChange={(e) => {
          setInputContent(e.target.value);
        }}
      />
      <Input
        type="file"
        className="mb-2"
        onChange={async (e) => {
          if (inputFiles.length >= 3) {
            alertContext.setAlert('Only allow update max 3 files', 'danger');
            return;
          }
          const result = await upload(e);
          setInputFiles((currentFiles) => [...currentFiles, { url: result.link, name: result.name }]);
        }}
      />
      <div className="mb-2">
        {inputFiles.map((f, fi) => (
          <div
            className="mb-2 d-flex justify-content-between"
            style={{
              backgroundColor: '#fff3cd',
              padding: 8,
              borderRadius: 8,
              marginBottom: 20,
            }}
          >
            <a target="_black" href={f.url}>
              {f.name}
            </a>
            <button
              type="button"
              class="close"
              aria-label="Close"
              onClick={() => {
                setInputFiles((currentFiles) =>
                  currentFiles.filter((object) => {
                    return object.name !== currentFiles[fi].name;
                  })
                );
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ))}
      </div>
      <LoadingButton color="primary" block onClick={_onClick} text={'Submit'} />
    </FormGroup>
  );
};

const Dashboard = (props) => {
  const alertContext = useContext(PageAlertContext);
  const popUpContext = useContext(PopUpContext);

  const heroStyles = {
    padding: '50px 0 70px',
  };
  const roles = Cookies.get('role');
  const userId = Cookies.get('userId');

  const [surveyList, setSurveyList] = useState([]);
  const [trainees, setTrainees] = useState([]);

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    const surveyEventList = await fetchWithGet('/research/getSurveyEvent');
    if (surveyEventList) {
      setSurveyList(surveyEventList);
    }

    const _trainees = await fetchWithGet(`/research/getTrainees`);
    if (_trainees) {
      setTrainees(_trainees);
    }
  };

  const submit = async (target, id) => {
    const result = await fetchWithPost('/research/submitContent', {
      id: id,
      content: target.content,
      files: target.files,
    });
    if (result && (result.status === 400 || result.status === 404)) {
      alertContext.setAlert(result.data.message, 'danger');
    } else {
      alertContext.setAlert('Successfully updated', 'success');
    }
    fetchApi();
  };

  return (
    <div>
      {roles === 'employer' && surveyList.length > 0 ? (
        <div style={{ marginBottom: 20 }}>
          <Row>
            <Col>
              <h2 className="h4" style={{ color: '#856404', fontWeight: 'bold' }}>
                Actions required
              </h2>
            </Col>
          </Row>
          {surveyList.map((v) => {
            const target = _.find(v.targetList, (a) => a.target.id === userId);

            return (
              <div style={{ backgroundColor: '#fff3cd', padding: 18, borderRadius: 18, marginBottom: 20 }}>
                <Row className="align-items-center">
                  <Col>
                    {v.name.en} - {v.name.zh} ({v?.cohort?.en})
                  </Col>
                  {!v.withCandidate && v.actionType === 'survey' ? (
                    v.actionType === 'survey' ? (
                      <Col className="text-right">
                        {target?.finishDatetime ? (
                          'Completed'
                        ) : (
                          <Button
                            color="primary"
                            onClick={() => {
                              window.open(`${v.survey.surveyLink}?id=${v.id}-${userId}`, '_blank');
                            }}
                          >
                            Start
                          </Button>
                        )}
                      </Col>
                    ) : (
                      <></>
                    )
                  ) : (
                    <Col className="text-right">
                      {target?.finishDatetime ? (
                        'Completed'
                      ) : v.actionType === 'input' ? (
                        <Button
                          color="primary"
                          onClick={async () => {
                            const confirm = await popUpContext.onOpen(
                              "Are you confirm completed this action? Once confirm, you can't edit it anymore."
                            );
                            if (confirm) {
                              if (
                                trainees.filter((a) => a?.finalInterview?.application?.cohort?.id === v.cohort.id)
                                  .length !== v.withCandidateList.length
                              ) {
                                alertContext.setAlert('Please finish all the action', 'danger');
                              } else {
                                const result = await fetchWithPost(`/research/completeSurveyEvent`, {
                                  id: v.id,
                                });
                                if (result) {
                                  if (result.status === 400) {
                                    alertContext.setAlert(result.data.message, 'danger');
                                  } else {
                                    alertContext.setAlert('Successfully updated', 'success');
                                  }
                                  fetchApi();
                                }
                              }
                            }
                          }}
                        >
                          Completed
                        </Button>
                      ) : null}
                    </Col>
                  )}
                </Row>
                {!v.withCandidate && v.actionType === 'input' && !target?.finishDatetime ? (
                  <Row style={{ marginTop: 20 }}>
                    <Col>
                      <Card>
                        <CardBody>
                          <InputFrom
                            content={target?.content}
                            files={target?.files}
                            onClick={(content, files) => submit({ content, files }, v.id)}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
                {v.withCandidate && !target?.finishDatetime ? (
                  <Row style={{ marginTop: 20, overflowX: 'scroll' }}>
                    <Col>
                      <Table hover>
                        <thead>
                          <tr className="text-center">
                            <th>#</th>
                            <th>Candidate Id</th>
                            <th>Candidate Name</th>
                            <th>Applied Role</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {trainees
                            .filter(
                              (a) => a?.finalInterview?.application?.cohort?.id === v.cohort.id && a.isLeft === false
                            )
                            .map((a, ai) => {
                              let candidate = _.find(v.withCandidateList, (b) => b.candidate === a?.candidate?.id);

                              return (
                                <tr className="text-center">
                                  <td>{ai + 1}</td>
                                  <td>{a?.candidate?.userId}</td>
                                  <td>
                                    {a?.candidate?.surname?.en} {a?.candidate?.givenName.en}
                                  </td>
                                  <td>{a?.finalInterview?.position?.role?.en}</td>
                                  <td>
                                    {candidate?.finishDatetime && v.actionType === 'survey' ? (
                                      'Completed'
                                    ) : v.actionType === 'input' ? (
                                      <InputFrom
                                        content={candidate?.content}
                                        files={candidate?.files}
                                        onClick={(content, files) =>
                                          submit({ content, files }, `${v.id}-${a?.candidate?.id}`)
                                        }
                                      />
                                    ) : (
                                      <Button
                                        color="primary"
                                        onClick={() => {
                                          window.open(
                                            `${v.survey.surveyLink}?id=${v.id}-${userId}-${a.candidate.id}`,
                                            '_blank'
                                          );
                                        }}
                                      >
                                        Start
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
      <Row>
        <Col md={12} className="text-center">
          <img src={Banner} style={{ maxWidth: 750 }} />
          <div className="home-hero" style={heroStyles}>
            <h1>Welcome to JC PROcruit C Portal</h1>
          </div>
        </Col>
      </Row>
      <Row>
        {roles === 'convenor' ? (
          <Col sm={12} md={6}>
            <Card>
              <CardBody>
                <h2 className="h4">For Convenor</h2>
                <p className="text-muted">
                  <ul>
                    <li>View scheduled interviews under you roles</li>
                    <li>View full application profile for shortlisted candidates under your roles</li>
                    <li>Upload research reports (Upcoming)</li>
                  </ul>
                </p>
              </CardBody>
            </Card>
          </Col>
        ) : (
          <></>
        )}
        {roles === 'employer' ? (
          <Col sm={12} md={6}>
            <Card>
              <CardBody>
                <h2 className="h4">For Employer</h2>
                <p className="text-muted">
                  <ul>
                    <li>Schedule final interview with shortlisted candidates</li>
                    <li>View full application profile for shortlisted candidates</li>
                    <li>Recommend candidates for programme offer</li>
                    <li>Upload research reports (Upcoming)</li>
                  </ul>
                </p>
              </CardBody>
            </Card>
          </Col>
        ) : (
          <></>
        )}
      </Row>

      {roles === 'admin' || roles === 'researcher' ? (
        <Row>
          <Col>
            <StatisticWidget />
          </Col>
        </Row>
      ) : (
        <></>
      )}

      {roles === 'convenor' ? (
        <Row>
          <Col>
            <ConvenorStatisticWidget />
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Dashboard;
