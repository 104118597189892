import React, { useState, useEffect } from 'react';
import {
  ButtonGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { fetchWithGet, fetchWithPost, fetchWithFormData } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import MUIDataTable from 'mui-datatables';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import { Loader } from '../../components';

export default function Faq() {
  let { path, url } = useRouteMatch();
  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <FaqSectionPage rootPath={url} />
        </Route>
        <Route path={`${path}/:faqSection/create`}>
          <FaqEdit rootPath={url} />
        </Route>
        <Route path={`${path}/:faqSection/:id`}>
          <FaqEdit rootPath={url} />
        </Route>
        <Route path={`${path}/:faqSection`}>
          <FaqDetailPage rootPath={url} />
        </Route>
      </Switch>
    </Router>
  );
}

function MyEditor({ onChange, value, placeholder }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [initValue, setInitValue] = useState('');
  useEffect(() => {
    if (!initValue && value) {
      setInitValue(value);
      const contentBlock = htmlToDraft(value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const contentEditorState = EditorState.createWithContent(contentState);
        setEditorState(contentEditorState);
      }
    }
  }, [value]);

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };
  const uploadCallback = async (file) => {
    const result = await fetchWithFormData('/content/uploadImage', { location: 'faq', image: file });
    return result;
  };

  return (
    <Editor
      toolbar={{ image: { previewImage: true, uploadCallback } }}
      editorState={editorState}
      wrapperClassName="rich-editor demo-wrapper"
      editorClassName="demo-editor"
      onEditorStateChange={handleEditorChange}
      placeholder={placeholder}
    />
  );
}

function FaqSectionPage({ rootPath }) {
  const history = useHistory();
  const [faq, setFaq] = useState([]);
  const [data, setData] = useState([]);
  const [id, setId] = useState('');
  const [zh, setZh] = useState('');
  const [en, setEn] = useState('');
  const [isHided, setIsHided] = useState(false);
  const [sequence, setSequence] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    '#',
    'Section Name',
    'Hided',
    'Sequence',
    {
      name: 'Actions',
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
          return (
            <ButtonGroup>
              <Button color="primary" outline onClick={() => toggle(faq[rowIndex])}>
                Edit Section
              </Button>
              <Button color="primary" outline onClick={() => history.push(`${rootPath}/${faq[rowIndex].id}`)}>
                Go to Faqs
              </Button>
            </ButtonGroup>
          );
        },
      },
    },
  ];
  const options = {
    selectableRows: 'none',
    print: false,
    download: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };

  const [modal, setModal] = useState(false);
  const toggle = (v) => {
    if (v && v.id) {
      setId(v.id);
      setEn(v.en);
      setZh(v.zh);
      setIsHided(v.isHided);
      setSequence(v.sequence);
    } else {
      setId('');
      setZh('');
      setEn('');
      setIsHided(false);
      setSequence(0);
      fetchApi();
    }
    setModal(!modal);
  };

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/content/faq');
    if (result) {
      setFaq(result);
      setData(result.map((v, i) => [i + 1, v.en + '\n' + v.zh, v.isHided ? 'True' : 'False', v.sequence]));
    }
    setIsLoading(false);
  };

  const save = async (context) => {
    if (zh && en) {
      const result = await fetchWithPost('/content/faqSection', {
        id,
        zh,
        en,
        isHided,
        sequence,
      });
      if (result && result.status === 400) {
        context.setAlert(result.data.message, 'danger');
        setTimeout(() => context.closeAlert(), 2000);
      } else {
        context.setAlert('Successfully updated', 'success');
        setTimeout(() => context.closeAlert(), 2000);
      }
    } else {
      context.setAlert('Please fill in all the blank', 'danger');
      setTimeout(() => context.closeAlert(), 2000);
    }
    toggle();
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Faq Section</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <PageAlertContext.Consumer>
        {(context) => (
          <div className="text-right mb-2">
            <Button color="primary" onClick={toggle}>
              Add FAQ Section
            </Button>
            <Modal centered={true} isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>
                <b>{id ? 'Edit' : 'Add'} FAQ Section</b>
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label className="mb-2">Name</Label>
                  <Input className="mb-2" placeholder="中文" value={zh} onChange={(v) => setZh(v.target.value)} />
                  <Input placeholder="English" value={en} onChange={(v) => setEn(v.target.value)} />
                </FormGroup>
                <FormGroup>
                  <Label>Hidden</Label>
                  <Input
                    type="select"
                    name="select"
                    value={isHided}
                    onChange={(v) => setIsHided(v.target.value === 'true')}
                  >
                    <option value={true}>{'True'}</option>
                    <option value={false}>{'False'}</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label>Sequence</Label>
                  <Input
                    type="number"
                    placeholder="Sequence"
                    value={sequence}
                    onChange={(v) => setSequence(v.target.value)}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => save(context)}>
                  {id ? 'Edit' : 'Add'}
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        )}
      </PageAlertContext.Consumer>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}

function FaqDetailPage({ rootPath }) {
  let { faqSection } = useParams();
  let { url } = useRouteMatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [section, setSection] = useState({});
  const [faq, setFaq] = useState([]);
  const [data, setData] = useState([]);

  const columns = ['#', 'Question En', 'Question Zh', 'Sequence'];
  const options = {
    selectableRows: 'none',
    download: false,
    onRowClick: (rowData, rowMeta) => {
      history.push(`${rootPath}/${faqSection}/${faq[rowMeta.dataIndex].id}`);
    },
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };

  useEffect(() => {
    fetchFaq();
  }, []);
  const fetchFaq = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/content/faq');
    if (result) {
      const mapped = result.find((v) => v.id === faqSection);
      setSection(mapped);
      setFaq(mapped.faq);
      setData(mapped.faq.map((v, i) => [i + 1, v.question.en, v.question.zh, v.sequence]));
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Faq Section</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`${url}/${faqSection}`}>{!_.isEmpty(section) ? `${section.zh}` : faqSection}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="text-right mb-2">
        <Button color="primary" onClick={() => history.push(`${rootPath}/${faqSection}/create`)}>
          Add
        </Button>
      </div>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}

function FaqEdit({ rootPath }) {
  let { id, faqSection } = useParams();
  let { url } = useRouteMatch();
  const history = useHistory();

  const [section, setSection] = useState({});

  const [faqId, setFaqId] = useState(id);
  const [questionZh, setQuestionZh] = useState('');
  const [questionEn, setQuestionEn] = useState('');
  const [answerZh, setAnswerZh] = useState('');
  const [answerEn, setAnswerEn] = useState('');
  const [isHided, setIsHided] = useState(false);
  const [sequence, setSequence] = useState(0);

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    const section = await fetchWithGet('/content/faq');
    if (section) {
      const mapped = section.find((v) => v.id === faqSection);
      setSection(mapped);
      if (id) {
        setFaqId(id);
        const faq = mapped.faq.find((v) => v.id === id);
        if (faq) {
          setQuestionZh(faq.question.zh);
          setQuestionEn(faq.question.en);
          setAnswerZh(faq.answer.zh);
          setAnswerEn(faq.answer.en);
          setIsHided(faq.isHided);
          setSequence(faq.sequence);
        }
      }
    }
  };

  const saveFaq = async (context) => {
    if (questionEn && questionZh && answerEn && answerZh) {
      const result = await fetchWithPost('/content/faq', {
        id: faqId,
        question: {
          zh: questionZh,
          en: questionEn,
        },
        answer: {
          zh: answerZh,
          en: answerEn,
        },
        isHided,
        sequence,
        section: faqSection,
      });

      if (result && result.status === 400) {
        context.setAlert(result.data.message, 'danger');
        setTimeout(() => context.closeAlert(), 2000);
      } else {
        context.setAlert('Successfully updated', 'success');
        setTimeout(() => context.closeAlert(), 2000);
        history.push(`${rootPath}/${faqSection}`);
      }
    } else {
      context.setAlert('Please fill in all the blank', 'danger');
      setTimeout(() => context.closeAlert(), 2000);
    }
  };

  return (
    <div>
      <div className="mb-2">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={`${rootPath}`}>Faq Section</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`${rootPath}/${faqSection}`}>{section.zh}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`${rootPath}/${faqSection}/${id}`}>{id ? questionZh : 'Create Faq'}</Link>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
      <PageAlertContext.Consumer>
        {(context) => (
          <Card>
            <CardBody>
              <div className="mb-2">
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label className="mb-2">Chinese Question</Label>
                      <Input
                        className="mb-2"
                        placeholder="中文"
                        value={questionZh}
                        onChange={(v) => setQuestionZh(v.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label className="mb-2">English Question</Label>
                      <Input placeholder="English" value={questionEn} onChange={(v) => setQuestionEn(v.target.value)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>Chinese Answer</Label>
                      <MyEditor onChange={(v) => setAnswerZh(v)} placeholder="中文" value={answerZh} />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>English Answer</Label>
                      <MyEditor onChange={(v) => setAnswerEn(v)} placeholder="English" value={answerEn} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>Hidden</Label>
                      <Input
                        type="select"
                        name="select"
                        value={isHided}
                        onChange={(v) => setIsHided(v.target.value === 'true')}
                      >
                        <option value={true}>{'True'}</option>
                        <option value={false}>{'False'}</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>Sequence</Label>
                      <Input
                        type="number"
                        placeholder="Sequence"
                        value={sequence}
                        onChange={(v) => setSequence(v.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={() => saveFaq(context)} block>
                {id ? 'Save' : 'Create'}
              </Button>
            </CardFooter>
          </Card>
        )}
      </PageAlertContext.Consumer>
    </div>
  );
}
