import React, { useState, useEffect } from 'react';
import { Card, CardBody, Button, Input, Form } from 'reactstrap';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

import login from '../assets/custom/logo.png';
import { fetchWithPost } from '../httpHelper';
import _ from 'lodash';

export default function LoginPage() {
  const history = useHistory();

  const [isLoggedIn] = useState(!!Cookies.get('token'));
  const [email, setEmail] = useState(Cookies.get('email') || '');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(!!Cookies.get('email'));
  const [errorMsg, setErrorMsg] = useState('');
  const _login = async (e) => {
    e.preventDefault();
    Cookies.remove('email');
    if (rememberMe) Cookies.set('email', email);
    if (email && password) {
      const result = await fetchWithPost('dashboard/login', {
        email: _.trim(email),
        password: _.trim(password),
      });
      if (result && result.token) {
        Cookies.set('role', result.user.scope.toString());
        Cookies.set('userId', result.user.id);
        Cookies.set('username', result.user.givenName.en);
        Cookies.set('token', result.token);

        const roles = Cookies.get('role');
        const scope = roles.split(',');
        if (scope.includes('admin')) {
          history.push('/event');
        } else {
          history.push('/');
        }
      } else if (result.status) {
        setErrorMsg(result.data.message);
      }
    } else {
      setErrorMsg('Please enter email and password');
    }
  };
  useEffect(() => {
    if (isLoggedIn === true) {
      const roles = Cookies.get('role');
      const scope = roles.split(',');
      if (scope.includes('admin')) {
        history.push('/event');
      } else {
        history.push('/');
      }
    }
  }, []);

  return (
    <div className="login-page-wrapper">
      <div className="login-wrap text-center">
        <Card>
          <CardBody>
            <div className="mb-4">
              <img alt="jcprocruitc" src={login} className="login-img mb-2" />
              <h3>Dashboard</h3>
            </div>
            <Form onSubmit={_login}>
              <div className="mb-2">
                <Input placeholder="Email address" value={email} onChange={(v) => setEmail(v.target.value)} />
              </div>
              <div className="mb-2">
                <Input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(v) => setPassword(v.target.value)}
                />
              </div>
              <div className="mb-2 ml-4 text-left">
                <Input type="checkbox" checked={rememberMe} onChange={(v) => setRememberMe(v.target.checked)} />
                Remember Me
                <br />
              </div>
              <div className="mb-2 text-left">
                <span className="text-danger">{errorMsg}</span>
              </div>

              <Button type="submit" block>
                Log in
              </Button>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
