import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardHeader,
  CardText,
  CardBody,
  CardFooter,
  Table,
  Button,
  FormGroup,
  Label,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  ButtonGroup,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  InputGroup,
} from 'reactstrap';
import { fetchWithGet, fetchWithPost } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';
import * as Feather from 'react-feather';
import CandidateInfo from './CandidateInfo';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import MUIDataTable from 'mui-datatables';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import PopUpContext from '../../components/components/PopUp/PopUpContext';
import Cookies from 'js-cookie';
import Datetime from 'react-datetime';
import { Loader } from '../../components';
import { levelOptions } from '../../components/helpers/reportHelper';
import { CSVLink, CSVDownload } from 'react-csv';
import qs from 'qs';

export default function FinalInterview() {
  let { path, url } = useRouteMatch();
  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <AllInterview rootPath={url} />
        </Route>

        <Route path={`${path}/info/:id`}>
          <CandidateInfo rootPath={url} rootName={'Final Interview'} from={'final'} />
        </Route>
        <Route path={`${path}/assignToEmployer/:id`}>
          <AssignCandidates rootPath={url} />
        </Route>
        <Route path={`${path}/:id`}>
          <InterviewCandidates rootPath={url} />
        </Route>
      </Switch>
    </Router>
  );
}

function AllInterview({ rootPath }) {
  const history = useHistory();
  const role = Cookies.get('role');
  const scope = role.split(',');
  let tabs = [
    { label: 'Candidate List', value: 'candidate' },
    { label: 'Calendar', value: 'calendar' },
  ];

  if (scope.includes('admin') || scope.includes('convenor')) {
    tabs = [{ label: 'All candidates', value: 'unassigned' }, ...tabs];
  }

  const finalInterviewSetting = window.sessionStorage.getItem('finalInterview');
  const query = qs.parse(finalInterviewSetting, { ignoreQueryPrefix: true });

  const [isLoading, setIsLoading] = useState(false);

  const [activeTab, setActiveTab] = useState(query?.activeTab || tabs[0].value);
  const localizer = momentLocalizer(moment);

  const [candidates, setCandidates] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [unassignedList, setUnassignedList] = useState([]);

  const fetchApi = async () => {
    setIsLoading(true);
    if ((query?.activeTab || tabs[0].value) === 'unassigned') {
      const final = await fetchWithGet('/dashboard/getStageCandidate', { stage: 'final' });
      if (final) {
        setUnassignedList(
          final.filter((v) => {
            return v.groupInterview.status === 'finished';
          })
        );
      }

      const finalList = await fetchWithGet('/dashboard/getFinalInterviewList');
      if (finalList) {
        setCandidates(finalList.filter((v) => v.status !== 'pending'));
      }

      const interviewSchedule = await fetchWithGet('/dashboard/getFinalInterviewSchedules');
      if (interviewSchedule) {
        setSchedules(
          interviewSchedule
            .filter((v) => v.status !== 'pending')
            .map((v) => {
              v.start = new Date(v.startDatetime);
              v.end = new Date(v.endDatetime);
              v.title = v.candidate && `${v.candidate.givenName.en} ${v.candidate.surname.en} - ${v.position.role.en}`;
              return v;
            })
        );
      }
    } else if ((query?.activeTab || tabs[0].value) === 'candidate') {
      const finalList = await fetchWithGet('/dashboard/getFinalInterviewList');
      if (finalList) {
        setCandidates(finalList.filter((v) => v.status !== 'pending'));
      }

      const final = await fetchWithGet('/dashboard/getStageCandidate', { stage: 'final' });
      if (final) {
        setUnassignedList(
          final.filter((v) => {
            return v.groupInterview.status === 'finished';
          })
        );
      }

      const interviewSchedule = await fetchWithGet('/dashboard/getFinalInterviewSchedules');
      if (interviewSchedule) {
        setSchedules(
          interviewSchedule
            .filter((v) => v.status !== 'pending')
            .map((v) => {
              v.start = new Date(v.startDatetime);
              v.end = new Date(v.endDatetime);
              v.title = v.candidate && `${v.candidate.givenName.en} ${v.candidate.surname.en} - ${v.position.role.en}`;
              return v;
            })
        );
      }
    } else {
      const interviewSchedule = await fetchWithGet('/dashboard/getFinalInterviewSchedules');
      if (interviewSchedule) {
        setSchedules(
          interviewSchedule
            .filter((v) => v.status !== 'pending')
            .map((v) => {
              v.start = new Date(v.startDatetime);
              v.end = new Date(v.endDatetime);
              v.title = v.candidate && `${v.candidate.givenName.en} ${v.candidate.surname.en} - ${v.position.role.en}`;
              return v;
            })
        );
      }

      const final = await fetchWithGet('/dashboard/getStageCandidate', { stage: 'final' });
      if (final) {
        setUnassignedList(
          final.filter((v) => {
            return v.groupInterview.status === 'finished';
          })
        );
      }

      const finalList = await fetchWithGet('/dashboard/getFinalInterviewList');
      if (finalList) {
        setCandidates(finalList.filter((v) => v.status !== 'pending'));
      }
    }

    setIsLoading(false);
  };
  useEffect(() => {
    fetchApi();
  }, []);

  //renderPage
  const renderPage = () => {
    switch (activeTab) {
      case 'calendar':
        return (
          <Card>
            <CardBody>
              <Calendar
                localizer={localizer}
                events={schedules}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                onSelectEvent={(event) => {
                  history.push(`${rootPath}/${event.id}`);
                }}
                onSelectSlot={(e) => console.log({ e })}
              />
            </CardBody>
          </Card>
        );
      case 'unassigned':
        return (
          <MUIDataTable
            key={'unassigned'}
            data={unassignedList.map((v, i) => [
              i + 1,
              v.candidate.userId,
              `${v.candidate && v.candidate.surname.en} ${v.candidate && v.candidate.givenName.en}\n${
                (v.candidate && v.candidate.surname.zh) || ''
              } ${(v.candidate && v.candidate.givenName.zh) || ''}`,
              `${v.candidate && v.candidate.email}/${v.candidate && v.candidate.phoneNo}`,
              v.candidate && v.candidate.education
                ? v.candidate.education.level === 'Others'
                  ? v.candidate.education.otherLevel || '-'
                  : levelOptions[v.candidate.education.level] || '-'
                : '-',
              `${v.cohort && v.cohort.en}`,
              v.appliedRoles[0] && v.appliedRoles[0].en,
              v.appliedRoles[0] && v.appliedRoles[0].convenor?.givenName?.en,
              v.appliedRoles[1] && v.appliedRoles[1].en,
              v.appliedRoles[1] && v.appliedRoles[1].convenor?.givenName?.en,
              v.groupInterview.comments
                .filter((v) => v.status === 'recommended')
                .map((v, i) => v.convenor.givenName?.en)
                .toString(),
              `${v.finalInterview.map((v, i) => v.employer?.parentId?.givenName?.en).toString()}`,
              `${v.finalInterview.map((v, i) => v.employer?.givenName?.en).toString()}`,
              `${v.finalInterview.length}`,
            ])}
            columns={[
              '#',
              'Candidate Id',
              'Candidate',
              'Email/PhoneNo',
              'Education Level',
              {
                name: 'Cohort',
                // options: {
                //   filter: true,
                //   filterList: ['Cohort 3'],
                // },
              },
              'Selected job role 1',
              'Name of convenor',
              'Selected job role 2',
              'Name of convenor',
              'Recommended by Convenor',
              'Convenors coordinated interview',
              'Assigned Employer',
              'Count',
            ]}
            options={{
              print: false,
              download: false,
              selectableRows: 'none',
              onRowClick: (rowData, rowMeta) => {
                history.push(`${rootPath}/assignToEmployer/${unassignedList[rowMeta.dataIndex].id}`);
              },
              textLabels: {
                body: {
                  noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
                },
              },
              onChangePage: (number) => {
                query[activeTab] = { ...query[activeTab], page: number };
                window.sessionStorage.setItem('finalInterview', qs.stringify(query));
              },
              onChangeRowsPerPage: (number) => {
                query[activeTab] = { ...query[activeTab], rowsPerPage: number };
                window.sessionStorage.setItem('finalInterview', qs.stringify(query));
              },
              onColumnSortChange: (changedColumn, direction) => {
                query[activeTab] = { ...query[activeTab], sortOrder: { name: changedColumn, direction: direction } };
                window.sessionStorage.setItem('finalInterview', qs.stringify(query));
              },
              page: query[activeTab]?.page,
              rowsPerPage: query[activeTab]?.rowsPerPage,
              sortOrder: query[activeTab]?.sortOrder,
            }}
          />
        );
      case 'candidate':
        return (
          <MUIDataTable
            key={'candidate'}
            data={candidates.map((v, i) => [
              i + 1,
              v.startDatetime
                ? `${moment(v.startDatetime).format('YYYY-MM-DD')}  ${moment(v.startDatetime).format('HH:mm')}-${moment(
                    v.endDatetime
                  ).format('HH:mm')}`
                : '',
              v.candidate.userId,
              `${v.candidate && v.candidate.surname.en} ${v.candidate && v.candidate.givenName.en}\n${
                (v.candidate && v.candidate.surname.zh) || ''
              } ${(v.candidate && v.candidate.givenName.zh) || ''}`,
              `${v.candidate && v.candidate.email}/${v.candidate && v.candidate.phoneNo}`,
              v.candidate && v.candidate.education
                ? v.candidate.education.level === 'Others'
                  ? v.candidate.education.otherLevel || '-'
                  : levelOptions[v.candidate.education.level] || '-'
                : '-',
              `${v.application.cohort.en}`,
              v.application.appliedRoles.map((v) => v.en).toString(),
              v.status === 'release' ? 'Sent' : v.status === 'finished' ? 'Finished' : 'Pending',
              `${v.employer.givenName.en}/${v.employer.email}`,
            ])}
            columns={[
              '#',
              'Scheduled Time',
              'Candidate Id',
              'Candidate',
              'Email/Phone',
              'Education Level',
              {
                name: 'Cohort',
                // options: {
                //   filter: true,
                //   filterList: ['Cohort 3'],
                // },
              },
              'Roles',
              'Status',
              'Employer',
            ]}
            options={{
              print: false,
              download: false,
              selectableRows: 'none',
              onRowClick: (rowData, rowMeta) => {
                history.push(`${rootPath}/${candidates[rowMeta.dataIndex].id}`);
              },
              textLabels: {
                body: {
                  noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
                },
              },
              onChangePage: (number) => {
                query[activeTab] = { ...query[activeTab], page: number };
                window.sessionStorage.setItem('finalInterview', qs.stringify(query));
              },
              onChangeRowsPerPage: (number) => {
                query[activeTab] = { ...query[activeTab], rowsPerPage: number };
                window.sessionStorage.setItem('finalInterview', qs.stringify(query));
              },
              onColumnSortChange: (changedColumn, direction) => {
                query[activeTab] = { ...query[activeTab], sortOrder: { name: changedColumn, direction: direction } };
                window.sessionStorage.setItem('finalInterview', qs.stringify(query));
              },
              page: query[activeTab]?.page,
              rowsPerPage: query[activeTab]?.rowsPerPage,
              sortOrder: query[activeTab]?.sortOrder,
            }}
          />
        );
      default:
        return <div />;
    }
  };

  const exportHeader = [
    { label: 'Candidate Id', key: 'userId' },
    { label: 'Candidate', key: 'candidate' },
    { label: 'Email', key: 'email' },
    { label: 'PhoneNo', key: 'phoneNo' },
    { label: 'Education Level', key: 'education' },
    { label: 'Cohort', key: 'cohort' },
    { label: 'Selected job role 1', key: 'job1' },
    { label: 'Name of convenor', key: 'jobConvenor1' },
    { label: 'Selected job role 2', key: 'job2' },
    { label: 'Name of convenor', key: 'jobConvenor2' },
    { label: 'Recommended by Convenor', key: 'recommendedConvenor' },
    { label: 'Convenors coordinated interview', key: 'coordinated' },
    { label: 'Assigned Employer', key: 'employer' },
    { label: 'Count', key: 'count' },
  ];
  const exportData = unassignedList.map((v, i) => {
    return {
      userId: v.candidate.userId,
      candidate: `${v.candidate && v.candidate.surname.en} ${v.candidate && v.candidate.givenName.en}\n${
        (v.candidate && v.candidate.surname.zh) || ''
      } ${(v.candidate && v.candidate.givenName.zh) || ''}`,
      email: v.candidate && v.candidate.email,
      phoneNo: v.candidate && v.candidate.phoneNo,
      education:
        v.candidate && v.candidate.education
          ? v.candidate.education.level === 'Others'
            ? v.candidate.education.otherLevel || '-'
            : levelOptions[v.candidate.education.level] || '-'
          : '-',
      cohort: `${v.cohort && v.cohort.en}`,
      job1: v.appliedRoles[0] && v.appliedRoles[0].en,
      jobConvenor1: v.appliedRoles[0] && v.appliedRoles[0].convenor?.givenName?.en,
      job2: v.appliedRoles[1] && v.appliedRoles[1].en,
      jobConvenor2: v.appliedRoles[1] && v.appliedRoles[1].convenor?.givenName?.en,
      recommendedConvenor: v.groupInterview.comments
        .filter((v) => v.status === 'recommended')
        .map((v, i) => v.convenor.givenName?.en)
        .toString(),
      coordinated: `${v.finalInterview.map((v, i) => v.employer?.parentId?.givenName?.en).toString()}`,
      employer: `${v.finalInterview.map((v, i) => v.employer?.givenName?.en).toString()}`,
      count: `${v.finalInterview.length}`,
    };
  });

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Final Interview</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      {/* {activeTab === 'unassigned' ? (
        <div className="text-right mb-2">
          <CSVLink filename={`Final-Interview-Export`} data={exportData} headers={exportHeader}>
            Export Candidates
          </CSVLink>
        </div>
      ) : (
        <></>
      )} */}
      <Nav tabs>
        {tabs.map((v, i) => (
          <NavItem key={i}>
            <NavLink
              href="#"
              className={`${activeTab === v.value ? 'active' : ''}`}
              onClick={() => {
                window.sessionStorage.setItem('finalInterview', qs.stringify({ ...query, activeTab: v.value }));
                setActiveTab(v.value);
              }}
            >
              {v.label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <div>{renderPage()}</div>
    </div>
  );
}

function AssignCandidates({ rootPath }) {
  let { id } = useParams();
  let { url } = useRouteMatch();
  const history = useHistory();
  const userId = Cookies.get('userId');
  const role = Cookies.get('role');
  const scope = role.split(',');

  const alertContext = useContext(PageAlertContext);

  const [employers, setEmployers] = useState([]);
  const [application, setApplication] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  //functions
  const _assign = async (e, application) => {
    e.preventDefault();
    const split = e.target.select.value.split('-');

    const result = await fetchWithPost('/dashboard/assignToEmployer', {
      employer: split[0],
      applicationId: application.id,
      position: split[1],
    });
    if (result && result.status === 400) {
      alertContext.setAlert(result.data.message, 'danger');
      fetchApi();
    } else {
      alertContext.setAlert('Successfully updated', 'success');
      fetchApi();
    }
  };

  const _removeFromEmployer = async (v) => {
    const result = await fetchWithPost('/dashboard/removeFromEmployer', {
      applicationId: v.application,
      finalInterviewId: v.id,
    });
    if (result && (result.status === 400 || result.status === 404)) {
      alertContext.setAlert(result.data.message, 'danger');
      fetchApi();
    } else {
      alertContext.setAlert('Successfully updated', 'success');
      fetchApi();
    }
  };

  const _sendNotification = async (v) => {
    const result = await fetchWithPost('/dashboard/sendNotification', {
      id: v.id,
      stage: 'assignToEmployer',
    });
    if (result && result.status === 400) {
      alertContext.setAlert(result.data.message, 'danger');
      fetchApi();
    } else {
      alertContext.setAlert('Successfully updated', 'success');
      fetchApi();
    }
  };

  const _changeFinalInterviewStatus = async (v, status) => {
    const result = await fetchWithPost('/dashboard/changeFinalInterviewStatus', {
      finalInterviewId: v.id,
      status,
    });
    if (result && result.status === 400) {
      alertContext.setAlert(result.data.message, 'danger');
      fetchApi();
    } else {
      alertContext.setAlert('Successfully updated', 'success');
      fetchApi();
    }
  };

  const fetchApi = async () => {
    setIsLoading(true);
    const final = await fetchWithGet('/dashboard/getStageCandidate', { stage: 'final' });
    if (final) {
      setApplication(final.find((v) => v.id === id));
    }

    const employerList = await fetchWithGet(`/dashboard/getPositionEmployer`);
    if (employerList) {
      setEmployers(employerList);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchApi();
  }, []);

  let columns = [
    '#',
    'Employer',
    'Position',
    'Notification Status',
    {
      name: 'Remove',
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ButtonGroup>
              <Button color="danger" onClick={() => _removeFromEmployer(value)}>
                Remove
              </Button>
              <Button color="warning" onClick={() => _sendNotification(value)}>
                Send Notification
              </Button>
            </ButtonGroup>
          );
        },
      },
    },
  ];

  if (scope.includes('admin')) {
    columns = [
      ...columns,
      {
        name: 'Change Status',
        options: {
          filter: false,
          download: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const rowIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
            return (
              <Input
                type="select"
                name="select"
                value={application.finalInterview[rowIndex].status}
                onChange={(a) => _changeFinalInterviewStatus(application.finalInterview[rowIndex], a.target.value)}
              >
                <option value={'pending'}>{'Pending'}</option>
                <option value={'assigned'}>{'Assigned'}</option>
                <option value={'release'}>{'Release'}</option>
                <option value={'finished'}>{'Finished'}</option>
              </Input>
            );
          },
        },
      },
    ];
  }

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Final Interview</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`${url}/assignToEmployer/${id}`}>
            {!_.isEmpty(application) ? `${application.candidate.givenName.en} ${application.candidate.surname.en}` : id}
          </Link>
        </BreadcrumbItem>
      </Breadcrumb>
      {!_.isEmpty(application) ? (
        <div>
          <Card>
            <CardBody>
              <Button
                color="primary"
                block
                onClick={() => history.push(`${rootPath}/info/${application.candidate.id}`)}
                className="mb-2"
              >
                Full Profile
              </Button>
              <Row>
                <Col xs={12} md={6}>
                  <Card>
                    <CardBody>
                      <Label>Personal Information</Label>
                      <Table>
                        <tbody>
                          <tr>
                            <td>English Name</td>
                            <td>{`${application.candidate.givenName.en} ${application.candidate.surname.en}`}</td>
                          </tr>
                          <tr>
                            <td>Chinese Name</td>
                            <td>{`${application.candidate.surname.zh || ''}${
                              application.candidate.givenName.zh || ''
                            }`}</td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td>{application.candidate.email}</td>
                          </tr>
                          <tr>
                            <td>Phone No</td>
                            <td>{application.candidate.phoneNo}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <Label>Applied Roles</Label>
                      <Table>
                        <tbody>
                          {application.appliedRoles.map((v) => (
                            <tr>
                              <td>{v.en}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6}>
                  <Card>
                    <CardBody>
                      <Label>Education Background</Label>
                      <Table>
                        <tbody>
                          <tr>
                            <td>Institution</td>
                            <td>
                              {application.candidate.education.institution &&
                                application.candidate.education.institution.en}
                              <br />
                              {application.candidate.education.otherInstitution || ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Programme Name</td>
                            <td>{application.candidate.education.programmeName}</td>
                          </tr>
                          <tr>
                            <td>Area of Study</td>
                            <td>
                              {application.candidate.education.areaOfStudy &&
                                application.candidate.education.areaOfStudy.en}
                              <br />
                              {application.candidate.education.other_areaOfStudy || ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Location</td>
                            <td>
                              {application.candidate.education.location && application.candidate.education.location.en}
                              <br />
                              {application.candidate.education.other_location || ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Start Date</td>
                            <td>{`${application.candidate.education.startYear}/${application.candidate.education.startMonth}`}</td>
                          </tr>
                          <tr>
                            <td>Grad Date</td>
                            <td>
                              {_.isInteger(application.candidate.education.gradYear)
                                ? `${application.candidate.education.gradYear}/${application.candidate.education.gradMonth}`
                                : `${application.candidate.education.gradYear}`}
                            </td>
                          </tr>
                          <tr>
                            <td>Resume</td>
                            <td>
                              <Button href={application.candidate.resume || ''} color="link" target="_blank">
                                Resume
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <form onSubmit={(event) => _assign(event, application)}>
                    <InputGroup>
                      <Input type="select" name="select">
                        <option value={''}>{'Please select employer'}</option>
                        {employers
                          .filter((a) => {
                            return (
                              _.intersection(
                                a.parentId?.roles?.map((v) => v.id),
                                application.appliedRoles.map((v) => v.id)
                              ).length > 0
                            );
                          })
                          .map((a, i) => {
                            const positions = _.intersectionBy(a.parentId?.roles, application.appliedRoles, 'id');

                            return (
                              <>
                                {positions.map((v, vi) => (
                                  <option
                                    key={`${i}${vi}`}
                                    value={`${a.id}-${v.id}`}
                                  >{`${a.givenName.en} (${v.en})`}</option>
                                ))}
                              </>
                            );
                          })}
                      </Input>

                      <Button>Assign</Button>
                    </InputGroup>
                  </form>

                  <MUIDataTable
                    data={application.finalInterview
                      .filter((v) => {
                        if (scope.includes('convenor')) {
                          return v.employer.parentId.id === userId;
                        }
                        return true;
                      })
                      .map((v, i) => [
                        i + 1,
                        <div>
                          {`${v.employer && v.employer.givenName.en}`}
                          <br />
                          {`${v.employer && v.employer.email}`}
                        </div>,
                        `${v.position.stream.en} - ${v.position.role.en}`,
                        v.status === 'pending' ? 'Pending' : 'Sent',
                        v,
                      ])}
                    columns={columns}
                    options={{
                      print: false,
                      download: false,
                      selectableRows: 'none',
                      textLabels: {
                        body: {
                          noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
                        },
                      },
                    }}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

function InterviewCandidates({ rootPath }) {
  let { id } = useParams();
  let { url } = useRouteMatch();

  const alertContext = useContext(PageAlertContext);
  const popUpContext = useContext(PopUpContext);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const userId = Cookies.get('userId');
  const role = Cookies.get('role');
  const scope = role.split(',');

  const [finalInfo, setFinalInfo] = useState({});
  const [startDatetime, setStartDatetime] = useState('');
  const [endDatetime, setEndDatetime] = useState('');
  const [venue, setVenue] = useState('');
  const [importantNotes, setImportantNotes] = useState('');

  const fetchApi = async () => {
    const interviewSchedule = await fetchWithGet('/dashboard/getFinalInterviewSchedules');
    if (interviewSchedule) {
      const keyby = _.keyBy(interviewSchedule, 'id');
      if (keyby[id]) {
        setFinalInfo(keyby[id]);
        setStartDatetime(keyby[id].startDatetime ? new Date(keyby[id].startDatetime) : '');
        setEndDatetime(keyby[id].endDatetime ? new Date(keyby[id].endDatetime) : '');
        setVenue(keyby[id].venue);
        setImportantNotes(keyby[id].importantNotes);
      }
    }
  };
  useEffect(() => {
    fetchApi();
  }, []);

  const _scheduleAction = async (sendNotification) => {
    if ((finalInfo && startDatetime && endDatetime && venue) || scope.includes('admin')) {
      if (startDatetime >= endDatetime) {
        alertContext.setAlert('Start date is greater than end date', 'danger');
      } else if ((startDatetime <= today || endDatetime <= today) && !scope.includes('admin')) {
        // skip this date check if role is ongrad
        alertContext.setAlert('Start date or end date cannot be less than today', 'danger');
      } else {
        const result = await fetchWithPost('/dashboard/scheduleFinalInterview', {
          employer: finalInfo.employer.id,
          startDatetime,
          endDatetime,
          venue,
          finalInterviewId: finalInfo.id,
          importantNotes,
        });
        if (result && (result.status === 400 || result.status === 409)) {
          alertContext.setAlert(result.data.message, 'danger');
        } else {
          fetchApi();
          if (sendNotification) {
            _sendNotification();
          } else {
            alertContext.setAlert('Successfully updated', 'success');
          }
        }
      }
    } else {
      alertContext.setAlert('Please fill in all the blank', 'danger');
    }
  };
  const _sendNotification = async () => {
    const confirm = await popUpContext.onOpen('Are you confirm to send notification?');
    if (confirm) {
      if (finalInfo) {
        const result = await fetchWithPost('/dashboard/sendNotification', {
          id: finalInfo.id,
          stage: 'final',
        });
        if (result && result.status === 400) {
          alertContext.setAlert(result.data.message, 'danger');
          fetchApi();
        } else {
          alertContext.setAlert('Successfully updated', 'success');
          fetchApi();
        }
      }
    }
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Final Interview</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`${url}/${id}`}>
            {!_.isEmpty(finalInfo) ? `${finalInfo.candidate.givenName.en} ${finalInfo.candidate.surname.en}` : id}
          </Link>
        </BreadcrumbItem>
      </Breadcrumb>
      {!_.isEmpty(finalInfo) ? (
        <div>
          {(scope.includes('employer') && finalInfo.employer.id === userId) || scope.includes('admin') ? (
            <Card>
              <CardHeader>
                <b>Final Interview Information</b>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label className="mb-2">Start Datetime</Label>
                  <Datetime value={startDatetime} onChange={(date) => setStartDatetime(date)} />
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">End Datetime</Label>
                  <Datetime value={endDatetime} onChange={(date) => setEndDatetime(date)} />
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">Venue</Label>
                  <Input placeholder="Link or text" value={venue} onChange={(v) => setVenue(v.target.value)} />
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">Important Notes</Label>
                  <Input value={importantNotes} onChange={(v) => setImportantNotes(v.target.value)} />
                </FormGroup>
              </CardBody>
              <CardFooter>
                <div className="mb-2 d-flex justify-content-between">
                  <Button color="primary" outline onClick={() => _scheduleAction(true)}>
                    Send Notification
                  </Button>
                  <Button color="primary" outline onClick={() => _scheduleAction(false)}>
                    Edit Schedule
                  </Button>
                </div>
              </CardFooter>
            </Card>
          ) : (
            <Card>
              <CardHeader>
                <b>Final Interview Information</b>
              </CardHeader>
              <CardBody>
                <CardText>Employer: {`${finalInfo.employer.givenName.en} - ${finalInfo.employer.email}`}</CardText>
                <CardText>Position: {`${finalInfo.position.stream.en} - ${finalInfo.position.role.en}`}</CardText>
                <CardText>
                  Interview Time:{' '}
                  {startDatetime
                    ? `${moment(startDatetime).format('YYYY-MM-DD')}  ${moment(startDatetime).format('HH:mm')}-${moment(
                        endDatetime
                      ).format('HH:mm')}`
                    : ''}
                </CardText>
                <CardText>
                  Venue:{' '}
                  {/^(http|https):\/\/[^ "]+$/.test(venue) ? (
                    <a href={venue} target="_blank" rel="noopener noreferrer">
                      {venue}
                    </a>
                  ) : (
                    venue
                  )}
                </CardText>
                <CardText>Important Notes: {importantNotes}</CardText>
              </CardBody>
            </Card>
          )}
          <InterviewCandidate
            rootPath={rootPath}
            candidate={finalInfo.candidate}
            interview={finalInfo}
            refresh={fetchApi}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

function InterviewCandidate({ rootPath, candidate, interview, refresh }) {
  const history = useHistory();
  const role = Cookies.get('role');
  const scope = role.split(',');
  const alertContext = useContext(PageAlertContext);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const userId = Cookies.get('userId');
  const [interviewNotes, setInterviewNotes] = useState(
    (scope.includes('employer') && interview.employer.id === userId) || scope.includes('admin')
      ? interview.interviewer.interviewNotes || ''
      : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).interviewNotes || ''
  );
  const [isOpen, setIsOpen] = useState(true);
  const ExpandIcon = isOpen ? Feather.ChevronDown : Feather.ChevronRight;

  const _save = async (status) => {
    const result = await fetchWithPost('/dashboard/updateInterviewStatus', {
      stage: 'final',
      interviewId: interview.id,
      interviewNotes: interviewNotes,
      status:
        status ||
        ((scope.includes('employer') && interview.employer.id === userId) || scope.includes('admin')
          ? interview.interviewer.status
          : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status || ''),
    });
    if (result && (result.status === 400 || result.status === 404)) {
      alertContext.setAlert(result.data.message, 'danger');
    } else {
      alertContext.setAlert('Successfully updated', 'success');
    }
    refresh();
  };
  const _getColor = (status) => {
    switch (status) {
      case 'recommended':
        return 'text-success';
      case 'waitinglist':
        return 'text-warning';
      case 'notrecommended':
        return 'text-danger';
      case 'absent':
        return 'text-info';
      default:
        return '';
    }
  };

  return (
    <Card>
      <CardHeader>
        <Row onClick={() => setIsOpen(!isOpen)}>
          <Col xs={11}>{`${candidate.givenName.en} ${candidate.surname.en} | ${interview.application.appliedRoles
            .map((v) => v.en)
            .toString()}`}</Col>
          <Col xs={1} className="text-right">
            <ExpandIcon className="menu-expand-icon" />
          </Col>
        </Row>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          <Button color="primary" block onClick={() => history.push(`${rootPath}/info/${candidate.id}`)}>
            Full Profile
          </Button>
          <Row>
            <Col xs={12} md={6}>
              <Card>
                <CardBody>
                  {!_.isEmpty(candidate) ? (
                    <div>
                      <Label>Personal Information</Label>
                      <Table>
                        <tbody>
                          <tr>
                            <td>English Name</td>
                            <td>{`${candidate.givenName.en} ${candidate.surname.en}`}</td>
                          </tr>
                          <tr>
                            <td>Chinese Name</td>
                            <td>{`${candidate.surname.zh || ''}${candidate.givenName.zh || ''}`}</td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td>{candidate.email}</td>
                          </tr>
                          <tr>
                            <td>Phone No</td>
                            <td>{candidate.phoneNo}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card>
                <CardBody>
                  {!_.isEmpty(candidate) ? (
                    <div>
                      <Label>Education Background</Label>
                      <Table>
                        <tbody>
                          <tr>
                            <td>Institution</td>
                            <td>
                              {candidate.education.institution && candidate.education.institution.en}
                              <br />
                              {candidate.education.otherInstitution || ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Programme Name</td>
                            <td>{candidate.education.programmeName}</td>
                          </tr>
                          <tr>
                            <td>Area of Study</td>
                            <td>
                              {candidate.education.areaOfStudy && candidate.education.areaOfStudy.en}
                              <br />
                              {candidate.education.other_areaOfStudy || ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Location</td>
                            <td>
                              {candidate.education.location && candidate.education.location.en}
                              <br />
                              {candidate.education.other_location || ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Start Date</td>
                            <td>{`${candidate.education.startYear}/${candidate.education.startMonth}`}</td>
                          </tr>
                          <tr>
                            <td>Grad Date</td>
                            <td>
                              {_.isInteger(candidate.education.gradYear)
                                ? `${candidate.education.gradYear}/${candidate.education.gradMonth}`
                                : `${candidate.education.gradYear}`}
                            </td>
                          </tr>
                          <tr>
                            <td>Resume</td>
                            <td>
                              <Button href={candidate.resume || ''} color="link" target="_blank">
                                Resume
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Collapse>
      {interview.startDatetime && new Date(interview.startDatetime).setHours(0, 0, 0, 0) <= today ? (
        <CardBody>
          <Row>
            <Col sm={12} md={6}>
              <Label>Special comments on this candidate</Label>
              <FormGroup>
                <Input
                  type="textarea"
                  name="notes"
                  id="notes"
                  value={interviewNotes}
                  placeholder={'Interview Note'}
                  className="mb-2"
                  onChange={(e) => setInterviewNotes(e.target.value)}
                />
                <Button onClick={() => _save('')} block outline>
                  Save
                </Button>
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              {scope.includes('employer') && !interview.surveyFinishDatetime ? (
                <div>
                  <Label>
                    Please complete the survey after conducting interview with the candidate. This assessment is
                    important for us to observe how the candidate has developed over the course of the traineeship
                    journey.
                  </Label>
                  <div className="d-flex justify-content-around">
                    <Button
                      color="primary"
                      outline={true}
                      onClick={() =>
                        window.open(
                          `https://esurvey.psy.cuhk.edu.hk/jfe/form/SV_bJyhSMHQMZGudJc?id=${interview.id}-${userId}`,
                          '_blank'
                        )
                      }
                    >
                      Survey
                    </Button>
                    <Button color="warning" outline={true} onClick={refresh}>
                      Refresh
                    </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <Label>Will you recommend this candidate.</Label>
                  <ButtonGroup style={{ width: '100%' }}>
                    <Button
                      color="success"
                      outline={
                        !(
                          ((scope.includes('employer') && interview.employer.id === userId) || scope.includes('admin')
                            ? interview.interviewer.status
                            : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status) ===
                          'recommended'
                        )
                      }
                      onClick={() => _save('recommended')}
                    >
                      Recommended
                    </Button>
                    <Button
                      color="warning"
                      outline={
                        !(
                          ((scope.includes('employer') && interview.employer.id === userId) || scope.includes('admin')
                            ? interview.interviewer.status
                            : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status) ===
                          'waitinglist'
                        )
                      }
                      onClick={() => _save('waitinglist')}
                    >
                      Waiting List
                    </Button>
                    <Button
                      color="danger"
                      outline={
                        !(
                          ((scope.includes('employer') && interview.employer.id === userId) || scope.includes('admin')
                            ? interview.interviewer.status
                            : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status) ===
                          'notrecommended'
                        )
                      }
                      onClick={() => _save('notrecommended')}
                    >
                      Not Recommended
                    </Button>
                    <Button
                      color="info"
                      outline={
                        !(
                          ((scope.includes('employer') && interview.employer.id === userId) || scope.includes('admin')
                            ? interview.interviewer.status
                            : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status) ===
                          'absent'
                        )
                      }
                      onClick={() => _save('absent')}
                    >
                      Absent
                    </Button>
                  </ButtonGroup>
                  <div>
                    Status:{' '}
                    <b>
                      <span
                        className={_getColor(
                          (scope.includes('employer') && interview.employer.id === userId) || scope.includes('admin')
                            ? interview.interviewer.status
                            : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status
                        )}
                      >
                        {_.startCase(
                          (scope.includes('employer') && interview.employer.id === userId) || scope.includes('admin')
                            ? interview.interviewer.status
                            : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status
                        ) || 'Not Selected'}
                      </span>
                    </b>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      ) : (
        <CardBody>
          {interview.startDatetime ? 'Waiting for interview' : 'Waiting for the employer to arrange an interview'}
        </CardBody>
      )}
    </Card>
  );
}
