import Cookies from 'js-cookie';

const GetNav = () => {
  const role = Cookies.get('role');
  const scope = role.split(',');

  const top = [
    {
      name: 'All Candidate',
      url: '/candidate/allCandidate',
      icon: 'Users',
      scope: ['admin', 'convenor', 'researcher'],
    },
    {
      name: 'Video Interview',
      url: '/candidate/videoInterview',
      icon: 'Video',
      scope: ['admin'],
    },
    {
      name: 'Group Interview',
      url: '/candidate/groupInterview',
      icon: 'Calendar',
      scope: ['admin', 'convenor'],
    },
    {
      name: 'Final Interview',
      url: '/candidate/finalInterview',
      icon: 'UserCheck',
      scope: ['admin', 'convenor', 'employer'],
    },
    {
      name: 'Release Offer',
      url: '/candidate/offer',
      icon: 'Check',
      scope: ['admin', 'convenor', 'employer'],
    },
    {
      name: 'Event',
      url: '/event',
      icon: 'FileText',
      scope: ['admin', 'convenor', 'researcher'],
    },
    {
      name: 'Attendance',
      url: '/attendance',
      icon: 'Clock',
      scope: ['admin', 'convenor', 'researcher'],
    },
    {
      name: 'CMS',
      icon: 'Settings',
      scope: ['admin'],
      children: [
        {
          name: 'User Management',
          url: '/cms/usermanagement',
        },
        {
          name: 'Cohort',
          url: '/cms/cohort',
        },
        {
          name: 'Stream',
          url: '/cms/stream',
        },
        {
          name: 'VASK',
          url: '/cms/vask',
        },
        {
          name: 'FAQ',
          url: '/cms/faq',
        },
        {
          name: 'Blog',
          url: '/cms/blog',
        },
        {
          name: 'Position',
          url: '/cms/position',
        },
        {
          name: 'PopUp',
          url: '/cms/popup',
        },
        {
          name: 'Event',
          url: '/cms/event',
        },
        // {
        //   name: 'Quiz',
        //   url: '/cms/quiz',
        // },
        // {
        //   name: 'Survey',
        //   url: '/cms/survey',
        // },
      ],
    },
    {
      name: 'Contact Us',
      url: '/contactus',
      icon: 'Phone',
      scope: ['admin'],
    },
    {
      name: 'RSVP',
      url: '/rsvp',
      icon: 'File',
      scope: ['admin'],
    },
    {
      name: 'Report',
      url: '/report',
      icon: 'FileText',
      scope: ['admin', 'researcher'],
    },
    {
      name: 'Calendar',
      url: '/calendar',
      icon: 'Calendar',
      scope: ['admin', 'researcher', 'convenor'],
    },
    // {
    //   name: 'Home',
    //   url: '/home',
    //   icon: 'Home',
    // },
    // {
    //   name: 'UI Elements',
    //   icon: 'Layers',
    //   children: [
    //     {
    //       name: 'Buttons',
    //       url: '/elements/buttons',
    //     },
    //     {
    //       name: 'Grid',
    //       url: '/elements/grid',
    //     },
    //     {
    //       name: 'Alerts',
    //       url: '/elements/alerts',
    //     },
    //     {
    //       name: 'Typography',
    //       url: '/elements/typography',
    //     },
    //     {
    //       name: 'Cards',
    //       url: '/elements/cards',
    //     },
    //     {
    //       name: 'Tabs',
    //       url: '/elements/tabs',
    //     },
    //     {
    //       name: 'Tables',
    //       url: '/elements/tables',
    //     },
    //     {
    //       name: 'Breadcrumbs',
    //       url: '/elements/breadcrumbs',
    //     },
    //     {
    //       name: 'Forms',
    //       url: '/elements/forms',
    //     },
    //     {
    //       name: 'Modals',
    //       url: '/elements/modals',
    //     },
    //     {
    //       name: 'Loaders',
    //       url: '/elements/loaders',
    //     },
    //     {
    //       name: 'Avatars',
    //       url: '/elements/avatars',
    //     },
    //     {
    //       name: 'Progress Bars',
    //       url: '/elements/progressbars',
    //     },
    //     {
    //       name: 'Pagination',
    //       url: '/elements/pagination',
    //     },
    //   ],
    // },
    // {
    //   name: 'Pages',
    //   icon: 'File',
    //   children: [
    //     {
    //       name: 'Blank',
    //       url: '/pages/blank',
    //     },
    //     {
    //       name: 'Sub Navigation',
    //       url: '/pages/subnav',
    //     },
    //     {
    //       name: '404',
    //       url: '/pages/404',
    //     },
    //   ],
    // },
    // {
    //   name: 'Apps',
    //   icon: 'Cloud',
    //   children: [
    //     {
    //       name: 'Analytics',
    //       url: '/apps/analytics',
    //     },
    //     {
    //       name: 'Invoice',
    //       url: '/apps/invoice',
    //     },
    //     {
    //       name: 'Activity Feed',
    //       url: '/apps/feed',
    //     },
    //     {
    //       name: 'CMS',
    //       url: '/apps/cms',
    //     },
    //   ],
    // },
    // {
    //   divider: true,
    // },
    // {
    //   name: 'Widgets',
    //   url: '/widgets',
    //   icon: 'Package',
    //   badge: {
    //     text: 'NEW',
    //   },
    // },
  ];
  const bottom = [];

  return {
    top: top.reduce((p, v) => {
      if (v.scope) {
        if (v.scope.some((item) => scope.includes(item))) {
          p.push(v);
        }
      } else {
        p.push(v);
      }
      return p;
    }, []),
    bottom,
  };
};

export default { GetNav };
