import React, { useState, useEffect, useContext } from 'react';
import { Card, CardBody, Table, Breadcrumb, BreadcrumbItem, Button, ButtonGroup } from 'reactstrap';
import { fetchWithGet, fetchWithPost } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';
import CandidateInfo from './CandidateInfo';
import MUIDataTable from 'mui-datatables';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import PopUpContext from '../../components/components/PopUp/PopUpContext';
import { Loader } from '../../components';
import { levelOptions } from '../../components/helpers/reportHelper';
import qs from 'qs';

export default function VideoInterview() {
  let { path, url } = useRouteMatch();
  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <AllInterview rootPath={url} />
        </Route>
        <Route path={`${path}/:id`}>
          <CandidateInfo rootPath={url} rootName={'Video Interview'} from={'video'} />
        </Route>
      </Switch>
    </Router>
  );
}

function AllInterview({ rootPath }) {
  const alertContext = useContext(PageAlertContext);
  const popUpContext = useContext(PopUpContext);

  const videoInterviewSetting = window.sessionStorage.getItem('videoInterviewSetting');
  const query = qs.parse(videoInterviewSetting, { ignoreQueryPrefix: true });

  const [candidates, setCandidates] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const columns = [
    '#',
    'Candidate Id',
    {
      name: 'Candidate',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
          if (candidates[rowIndex]) {
            return <a href={`${rootPath}/${candidates[rowIndex].candidate.id}`}>{value}</a>;
          }
          return '';
        },
        filter: false,
      },
    },
    'Email/Phone',
    'Education Level',
    'Cohort',
    'Roles',
    'Status',
    {
      name: 'Actions',
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
          if (candidates[rowIndex]) {
            if ((candidates[rowIndex].videoInterview || {}).status === 'finished') {
              return (
                <ButtonGroup>
                  <Button color="warning" outline onClick={() => _createGroupInterview(candidates[rowIndex])}>
                    Create Group Interview
                  </Button>
                </ButtonGroup>
              );
            }
            if ((candidates[rowIndex].videoInterview || {}).status === 'pending') {
              return (
                <ButtonGroup>
                  <Button color="warning" outline onClick={() => _getNeufastLink(candidates[rowIndex])}>
                    Create Neufast Link
                  </Button>
                </ButtonGroup>
              );
            }
            return (
              <ButtonGroup>
                <Button color="danger" outline onClick={() => _resetNeufast(candidates[rowIndex])}>
                  Reset Neufast
                </Button>
                <Button color="warning" outline onClick={() => _neufast(candidates[rowIndex])}>
                  Finish Neufast
                </Button>
              </ButtonGroup>
            );
          }
          return '';
        },
      },
    },
  ];
  const options = {
    selectableRows: 'none',
    print: false,
    download: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
    onChangePage: (number) => {
      window.sessionStorage.setItem('videoInterviewSetting', qs.stringify({ ...query, page: number }));
    },
    onChangeRowsPerPage: (number) => {
      window.sessionStorage.setItem('videoInterviewSetting', qs.stringify({ ...query, rowsPerPage: number }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      window.sessionStorage.setItem(
        'videoInterviewSetting',
        qs.stringify({ ...query, sortOrder: { name: changedColumn, direction: direction } })
      );
    },
    page: query?.page,
    rowsPerPage: query?.rowsPerPage,
    sortOrder: query?.sortOrder,
  };

  const fetchApi = async () => {
    setCandidates([]);
    setIsLoading(true);
    const result = await fetchWithGet('/dashboard/getStageCandidate', { stage: 'video' });
    if (result) {
      setCandidates(result);
      setData(
        result.map((v, i) => [
          i + 1,
          v.candidate.userId,
          `${v.candidate && v.candidate.surname.en} ${v.candidate && v.candidate.givenName.en}\n${
            (v.candidate && v.candidate.surname.zh) || ''
          } ${(v.candidate && v.candidate.givenName.zh) || ''}`,
          `${v.candidate && v.candidate.email}/${v.candidate && v.candidate.phoneNo}`,
          v.candidate && v.candidate.education
            ? v.candidate.education.level === 'Others'
              ? v.candidate.education.otherLevel || '-'
              : levelOptions[v.candidate.education.level] || '-'
            : '-',
          v.cohort.en,
          (v.appliedRoles || []).map((v) => v.en).toString(),
          _.startCase((v.videoInterview || {}).status),
        ])
      );
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchApi();
  }, []);

  const _getNeufastLink = async (v) => {
    const confirm = await popUpContext.onOpen('Are you confirm to create neufast link for this candidate');
    if (confirm) {
      const result = await fetchWithPost('/neufast/getNeufastLink', { applicationId: v.id });
      if (result && result.status === 400) {
        alertContext.setAlert(result.data.message, 'danger');
      } else {
        alertContext.setAlert('Successfully updated', 'success');
        fetchApi();
      }
    }
  };
  const _neufast = async (v) => {
    const confirm = await popUpContext.onOpen('Are you confirm to finish neufast for this candidate');
    if (confirm) {
      const result = await fetchWithPost('/neufast', { code: v.videoInterview.link.code });
      if (result && result.status === 400) {
        alertContext.setAlert(result.data.message, 'danger');
      } else {
        alertContext.setAlert('Successfully updated', 'success');
        fetchApi();
      }
    }
  };
  const _resetNeufast = async (v) => {
    const confirm = await popUpContext.onOpen('Are you confirm to reset neufast link for this candidate');
    if (confirm) {
      const result = await fetchWithPost('/neufast/resetVideoLink', { applicationId: v.id });
      if (result && result.status === 400) {
        alertContext.setAlert(result.data.message, 'danger');
      } else {
        alertContext.setAlert('Successfully updated', 'success');
        fetchApi();
      }
    }
  };
  const _createGroupInterview = async (v) => {
    const confirm = await popUpContext.onOpen('Are you confirm to add this candidate to group interview stage?');
    if (confirm) {
      const result = await fetchWithPost('/dashboard/addGroupInterview', { applicationId: v.id });
      if (result && result.status === 400) {
        alertContext.setAlert(result.data.message, 'danger');
      } else {
        alertContext.setAlert('Successfully updated', 'success');
        fetchApi();
      }
    }
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Video Interview</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}
