import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  FormGroup,
  Label,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
} from 'reactstrap';
import { fetchWithGet, fetchWithPost, fetchWithDelete } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import moment from 'moment';
import Datetime from 'react-datetime';
import _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import PopUpContext from '../../components/components/PopUp/PopUpContext';
import Cookies from 'js-cookie';
import { Loader } from '../../components';
import Select from 'react-select';
import { levelOptions } from '../../components/helpers/reportHelper';
import { CSVLink, CSVDownload } from 'react-csv';

export default function SurveyEvent(props) {
  let { path, url } = useRouteMatch();

  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <SurveyEventList rootPath={url} />
        </Route>
        <Route path={`${path}/create`}>
          <CreateEventDetails rootPath={url} />
        </Route>
        <Route path={`${path}/:id`}>
          <CreateEventDetails rootPath={url} />
        </Route>
      </Switch>
    </Router>
  );
}

function SurveyEventList({ rootPath }) {
  const history = useHistory();
  const role = Cookies.get('role');
  const scope = role.split(',');

  const alertContext = useContext(PageAlertContext);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingTrainee, setLoadingTrainee] = useState(false);

  const [surveyList, setSurveyList] = useState([]);
  const [employers, setEmployers] = useState([]);
  const [trainees, setTrainees] = useState([]);

  const [cohorts, setCohorts] = useState([]);
  const [selectedCohort, setSelectedCohort] = useState();

  const tabs = ['Employers', 'Trainees'];
  if (scope.includes('admin') || scope.includes('researcher')) {
    tabs.push('Event');
  }
  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);

    // const _trainees = await fetchWithGet('/research/getTrainees');
    // if (_trainees) {
    //   setTrainees(_trainees);
    // }

    const surveyEventList = await fetchWithGet('/research/getSurveyEvent');
    if (surveyEventList) {
      setSurveyList(surveyEventList);
    }

    const cohortList = await fetchWithGet('/content/cohort');
    if (cohortList) {
      const convertedCohort = cohortList
        .filter((v) => !v.disabled)
        .map((v) => {
          return { value: v.id, label: v.en };
        });
      setCohorts(convertedCohort);
      setSelectedCohort(convertedCohort[0]);
    }

    const employerList = await fetchWithGet(`/dashboard/getPositionEmployer`);
    if (employerList) {
      setEmployers(employerList);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadTrainee();
  }, [selectedCohort]);
  const loadTrainee = async () => {
    setLoadingTrainee(true);
    if (selectedCohort.value) {
      const _trainees = await fetchWithGet(`/research/getTrainees?cohortId=${selectedCohort.value}`);

      if (_trainees) {
        setTrainees(_trainees);
      }
    } else {
      setTrainees([]);
    }
    setLoadingTrainee(false);
  };
  console.log({ employers });
  const renderTable = () => {
    switch (activeTab) {
      case tabs[2]:
        const eventList = surveyList.filter((v) => v.cohort.id === selectedCohort?.value);
        return (
          <MUIDataTable
            data={eventList.map((v, i) => [
              i + 1,
              `${(v.name && v.name.en) || ''} ${(v.name && v.name.zh) || ''}`,
              _.startCase(v.target),
              _.startCase(v.actionType),
              moment(v.createdAt).format('MM/DD/YYYY HH:mm'),
              moment(v.updatedAt).format('MM/DD/YYYY HH:mm'),
              moment(v.startDatetime).format('MM/DD/YYYY'),
              moment(v.endDatetime).format('MM/DD/YYYY'),
            ])}
            columns={[
              '#',
              'Event Name',
              'Target',
              'Action Type',
              'Create Date Time',
              'Last Updated Date Time',
              'Start Date',
              'End Date',
            ]}
            options={{
              print: false,
              download: false,
              selectableRows: 'none',
              textLabels: {
                body: {
                  noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
                },
              },
              onRowClick: (rowData, rowMeta) => {
                history.push(`${rootPath}/${eventList[rowMeta.dataIndex].id}`);
              },
            }}
          />
        );
      case tabs[1]:
        const candidateEventList = surveyList.filter(
          (v) => v.cohort.id === selectedCohort.value && v.target === 'candidate'
        );

        return (
          <MUIDataTable
            data={trainees.map((v, i) => [
              i + 1,
              `${v.candidate && v.candidate.surname.en} ${v.candidate && v.candidate.givenName.en}\n${
                (v.candidate && v.candidate.surname.zh) || ''
              } ${(v.candidate && v.candidate.givenName.zh) || ''}`,
              ...candidateEventList.map((f) => {
                const obj = _.find(f.targetList, (a) => a.target.id === v.candidate.id);
                if (obj) {
                  return obj.finishDatetime ? 'Finished' : 'In progress';
                }
                return '-';
              }),
            ])}
            columns={['#', 'Candidate Name', ...candidateEventList.map((v) => v.name.en)]}
            options={{
              print: false,
              download: false,
              selectableRows: 'none',
              textLabels: {
                body: {
                  noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
                },
              },
            }}
          />
        );
      default:
        const employerEventList = surveyList.filter(
          (v) => v.cohort?.id === selectedCohort?.value && v.target === 'employer'
        );
        return (
          <MUIDataTable
            data={employers.map((v, i) => [
              i + 1,
              `${(v.givenName && v.givenName.en) || ''}`,
              `${(v.parentId.givenName && v.parentId.givenName.en) || ''}`,
              ...employerEventList.map((f) => {
                const obj = _.find(f.targetList, (a) => a.target.id === v.id);
                if (obj) {
                  return obj.finishDatetime ? 'Finished' : 'In progress';
                }
                return '-';
              }),
            ])}
            columns={['#', 'Employer Name', 'Convenor', ...employerEventList.map((v) => v.name.en)]}
            options={{
              print: false,
              download: false,
              selectableRows: 'none',
              textLabels: {
                body: {
                  noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
                },
              },
            }}
          />
        );
    }
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Event</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      {scope.includes('admin') || scope.includes('researcher') ? (
        <div className="text-right mb-2">
          <Button color="primary" onClick={() => history.push(`${rootPath}/create`)}>
            Create new event
          </Button>
        </div>
      ) : (
        <></>
      )}
      <Nav tabs>
        {cohorts?.map((v, i) => (
          <NavItem key={i}>
            <NavLink
              href="#"
              className={`${selectedCohort?.value === v.value ? 'active' : ''}`}
              onClick={() => setSelectedCohort(v)}
            >
              {v.label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <Card>
        <CardBody>
          <Nav tabs>
            {tabs.map((v, i) => (
              <NavItem key={i}>
                <NavLink href="#" className={`${activeTab === v ? 'active' : ''}`} onClick={() => setActiveTab(v)}>
                  {v}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          {loadingTrainee ? (
            <div className="mt-4">
              <Loader type={'spin'} />
            </div>
          ) : (
            renderTable()
          )}
        </CardBody>
      </Card>
    </div>
  );
}

function CreateEventDetails({ rootPath }) {
  let { id } = useParams();
  let { url } = useRouteMatch();
  const role = Cookies.get('role');
  const scope = role.split(',');
  const history = useHistory();

  const alertContext = useContext(PageAlertContext);
  const popUpContext = useContext(PopUpContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isQualtricLoading, setIsQualtricLoading] = useState(false);

  const [qualtric, setQualtric] = useState([]);

  const [trainees, setTrainees] = useState([]);
  const [employers, setEmployers] = useState([]);
  const [selectedTrainees, setSelectedTrainees] = useState([]);
  const [selectedEmployers, setSelectedEmployers] = useState([]);
  const [cohorts, setCohorts] = useState([]);
  const [fullSurveyLink, setFullSurveyLink] = useState([]);

  const targetValue = [
    { value: 'employer', label: 'Employer' },
    { value: 'candidate', label: 'Candidate' },
  ];
  const actionValue = [
    { value: 'survey', label: 'Survey' },
    { value: 'input', label: 'Input' },
  ];
  const actionValueForCandidate = [{ value: 'survey', label: 'Survey' }];
  const withCandidateValue = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];

  const [event, setEvent] = useState({
    name: { en: '', zh: '' },
    cohort: {},
    target: {},
    startDatetime: moment(),
    endDatetime: moment(),
    actionType: {},
    withCandidate: {},
    survey: {},
  });

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);

    // const _trainees = await fetchWithGet('/research/getTrainees');
    // let tempTraineeList = [];
    // if (_trainees) {
    //   tempTraineeList = _trainees;
    //   setTrainees(_trainees);
    // }

    const employerList = await fetchWithGet(`/dashboard/getPositionEmployer`);
    let tempEmployerList = [];
    if (employerList) {
      tempEmployerList = employerList;
      setEmployers(employerList);
    }

    const cohortList = await fetchWithGet('/content/cohort');
    let convertedCohortList = [];
    if (cohortList) {
      convertedCohortList = cohortList
        .filter((v) => !v.disabled)
        .map((v) => {
          return { value: v.id, label: v.en };
        });
      setCohorts(convertedCohortList);
    }

    if (id) {
      const eventObj = await fetchWithGet(`/research/getSurveyEvent?id=${id}`);

      if (eventObj) {
        const _trainees = await fetchWithGet(`/research/getTrainees?cohortId=${eventObj.cohort.id}`);

        if (eventObj.target === 'employer') {
          setSelectedEmployers(eventObj.targetList.map((a) => tempEmployerList.map((v) => v.id).indexOf(a.target.id)));
        }
        // else {
        //   setSelectedTrainees(eventObj.targetList.map((a) => tempTraineeList.map((v) => v.candidate.id).indexOf(a.id)));
        // }

        if (eventObj.actionType === 'survey') {
          fetchQualtric(eventObj.survey?.surveyId);
        }

        setEvent({
          ...eventObj,
          cohort: convertedCohortList.find((v) => v.value === eventObj.cohort.id),
          target: targetValue.find((v) => v.value === eventObj.target),
          actionType: (eventObj.target === 'employer' ? actionValue : actionValueForCandidate).find(
            (v) => v.value === eventObj.actionType
          ),
          withCandidate: withCandidateValue.find((v) => v.value === eventObj.withCandidate),
          startDatetime: moment(eventObj.startDatetime).format('MM/DD/YYYY'),
          endDatetime: moment(eventObj.endDatetime).format('MM/DD/YYYY'),
        });
        if (eventObj.withCandidate) {
          setFullSurveyLink(
            _trainees.map((v) => ({
              name: `${v.candidate?.surname?.en || ''} ${v.candidate?.givenName?.en || ''}`,
              email: v.candidate.email,
              link: `${eventObj.survey.surveyLink}?id=${eventObj.id}-${v.finalInterview.employer.id}-${v.id}`,
            }))
          );
        } else {
          setFullSurveyLink(
            eventObj.targetList.map((v) => ({
              name: v.target.givenName?.en,
              email: v.target.email,
              link: `${eventObj.survey.surveyLink}?id=${eventObj.id}-${v.target.id}`,
            }))
          );
        }
      }
    }

    setIsLoading(false);
  };

  const fetchQualtric = async (surveyId) => {
    setIsQualtricLoading(true);
    const result = await fetchWithGet(`/research/getQualtricsSurvey/${surveyId}`);
    if (result) {
      setQualtric(result);
    }
    setIsQualtricLoading(false);
  };

  const _create = async () => {
    const { startDatetime, endDatetime, name, cohort, target, actionType, survey, withCandidate } = event;

    if (!name.en || !name.zh) {
      alertContext.setAlert('Please fill in all the blank', 'danger');
      return;
    }

    if (_.isEmpty(actionType)) {
      alertContext.setAlert('Please select action type', 'danger');
      return;
    }

    if (target.value === 'employer' && selectedEmployers.length <= 0) {
      alertContext.setAlert('Please select at least one employer', 'danger');
      return;
    }

    if (target.value === 'candidate' && selectedTrainees.length <= 0) {
      alertContext.setAlert('Please select at least one trainee', 'danger');
      return;
    }

    if (target.value === 'employer' && _.isEmpty(withCandidate)) {
      alertContext.setAlert('Please fill in all the blank', 'danger');
      return;
    }

    if (startDatetime >= endDatetime) {
      alertContext.setAlert('Start date is greater than end date', 'danger');
    } else {
      const dataObj = {
        startDatetime,
        endDatetime,
        name,
        cohort: cohort.value,
        target: target.value,
        actionType: actionType.value,
        survey,
        withCandidate: withCandidate.value,
        targetList:
          target.value === 'employer'
            ? selectedEmployers.map((v) => {
                const inList = _.find(event.targetList, (a) => a.target?.id === employers[v].id);
                if (inList) {
                  return { target: employers[v].id, finishDatetime: inList.finishDatetime };
                }
                return { target: employers[v].id };
              })
            : selectedTrainees.map((v) => {
                const inList = _.find(event.targetList, (a) => a.target?.id === trainees[v].candidate.id);
                if (inList) {
                  return { target: trainees[v].candidate.id, finishDatetime: inList.finishDatetime };
                }
                return { target: trainees[v].candidate.id };
              }),
      };

      if (id) {
        dataObj.id = id;
      }
      if (actionType.value === 'input') {
        dataObj.survey = {};
      }

      if (target.value === 'candidate') {
        dataObj.withCandidate = false;
      }

      const result = await fetchWithPost('/research/updateSurveyEvent', dataObj);

      if (result) {
        if (result.status === 400) {
          alertContext.setAlert(result.data.message, 'danger');
        } else {
          alertContext.setAlert('Successfully updated', 'success');
          if (!id) {
            history.push(rootPath);
          }
        }
      }
    }
  };

  const _remove = async () => {
    const confirm = await popUpContext.onOpen('Are you confirm to remove this event? All records will be removed.');
    if (confirm) {
      const result = await fetchWithDelete(`/research/deleteSurveyEvent/${id}`);
      if (result) {
        if (result.status === 400) {
          alertContext.setAlert(result.data.message, 'danger');
        } else {
          alertContext.setAlert('Successfully deleted', 'success');
          history.push(rootPath);
        }
      }
    }
  };

  const _sendNotification = async () => {
    const confirm = await popUpContext.onOpen(
      `Are you confirm to send notification to all selected ${
        event.target.value == 'employer' ? 'employers' : 'trainees'
      }`
    );
    if (confirm) {
      const result = await fetchWithPost(`/research/sendNotification`, { id: event.id });
      if (result) {
        if (result.status === 400) {
          alertContext.setAlert(result.data.message, 'danger');
        } else {
          alertContext.setAlert('Successfully sent', 'success');
        }
      }
    }
  };

  useEffect(() => {
    loadTrainee();
  }, [event.cohort.value]);
  const loadTrainee = async () => {
    if (event.cohort.value) {
      const _trainees = await fetchWithGet(`/research/getTrainees?cohortId=${event.cohort.value}`);

      if (_trainees) {
        setTrainees(_trainees);
        setSelectedTrainees(
          event.targetList.map((a) => _trainees.map((v) => v.candidate.id).indexOf(a.target.id)).filter((v) => v !== -1)
        );
      }
    } else {
      setTrainees([]);
    }
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Event</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`${url}/${id}`}>{id ? 'Edit' : 'Create'}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <Card>
        <CardBody>
          {id && event.actionType.value === 'survey' ? (
            <CardHeader className="d-flex justify-content-end">
              {isQualtricLoading ? (
                <div>
                  <Loader small={true} />
                </div>
              ) : (
                <CSVLink filename={`${event.name.en}.csv`} data={qualtric}>
                  {'Download Survey Data'}
                </CSVLink>
              )}
            </CardHeader>
          ) : (
            <></>
          )}

          <FormGroup>
            <Label>Cohort</Label>
            <Select
              options={cohorts}
              value={event.cohort}
              onChange={(v) =>
                setEvent((event) => {
                  return {
                    ...event,
                    cohort: v,
                  };
                })
              }
            />
          </FormGroup>
          {!_.isEmpty(event.cohort) ? (
            <>
              <FormGroup>
                <Label>Target</Label>
                <Select
                  options={targetValue}
                  value={event.target}
                  onChange={(v) => {
                    setEvent((event) => {
                      return {
                        ...event,
                        target: v,
                        actionType: {},
                      };
                    });
                  }}
                />
              </FormGroup>
              {id && event.actionType.value === 'survey' ? (
                <CardHeader className="d-flex justify-content-end mb-2">
                  {isLoading ? (
                    <div>
                      <Loader small={true} />
                    </div>
                  ) : (
                    <CSVLink filename={`${event.name.en}_link.csv`} data={fullSurveyLink}>
                      {'Download Full Survey Link'}
                    </CSVLink>
                  )}
                </CardHeader>
              ) : (
                <></>
              )}
              {!_.isEmpty(event.target) ? (
                <>
                  <MUIDataTable
                    data={
                      event.target.value === 'employer'
                        ? employers.map((v, i) => [
                            i + 1,
                            v.givenName?.en,
                            v.email,
                            `${v.parentId.givenName.en}`,
                            trainees.findIndex((e) => e.finalInterview.employer.id == v.id && !e.isLeft) >= 0
                              ? 'True'
                              : 'False',
                          ])
                        : trainees.map((v, i) => [
                            i + 1,
                            `${v.candidate && v.candidate.surname.en} ${v.candidate && v.candidate.givenName.en}\n${
                              (v.candidate && v.candidate.surname.zh) || ''
                            } ${(v.candidate && v.candidate.givenName.zh) || ''}`,
                            `${v.candidate && v.candidate.email}/${v.candidate && v.candidate.phoneNo}`,
                            v.candidate && v.candidate.education
                              ? v.candidate.education.level === 'Others'
                                ? v.candidate.education.otherLevel || '-'
                                : levelOptions[v.candidate.education.level] || '-'
                              : '-',
                            v.finalInterview &&
                              v.finalInterview?.application?.cohort &&
                              v.finalInterview?.application?.cohort.en,
                            v.finalInterview && v.finalInterview?.position && v.finalInterview?.position?.role.en,
                            v.isLeft ? 'True' : 'False',
                          ])
                    }
                    columns={
                      event.target.value === 'employer'
                        ? ['#', 'Employer', 'Email', 'Convenor', 'Is Entitled']
                        : ['#', 'Candidate', 'Email/Phone', 'Education Level', 'Cohort', 'Accepted Role', 'Is quit']
                    }
                    options={{
                      print: false,
                      download: false,
                      selectableRows: 'multiple',
                      selectToolbarPlacement: 'above',
                      textLabels: {
                        body: {
                          noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
                        },
                      },
                      onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
                        event.target.value === 'employer'
                          ? setSelectedEmployers(rowsSelected)
                          : setSelectedTrainees(rowsSelected);
                      },
                      rowsSelected: event.target.value === 'employer' ? selectedEmployers : selectedTrainees,
                      customToolbarSelect: () => {},
                    }}
                  />
                  <FormGroup className="mt-4">
                    <Label>Event Name</Label>
                    <Row>
                      <Col sm={12} md={6}>
                        <Input
                          placeholder="Event Name (English)"
                          value={event.name.en}
                          onChange={(v) =>
                            setEvent((event) => {
                              return {
                                ...event,
                                name: {
                                  ...event.name,
                                  en: v.target.value,
                                },
                              };
                            })
                          }
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <Input
                          placeholder="Event Name (Chinese)"
                          value={event.name.zh}
                          onChange={(v) =>
                            setEvent((event) => {
                              return {
                                ...event,
                                name: {
                                  ...event.name,
                                  zh: v.target.value,
                                },
                              };
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label className="mb-2">Start time</Label>
                    <Datetime
                      value={event.startDatetime}
                      timeFormat={false}
                      onChange={(date) =>
                        setEvent((event) => {
                          return {
                            ...event,
                            startDatetime: date,
                          };
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="mb-2">End time</Label>
                    <Datetime
                      value={event.endDatetime}
                      timeFormat={false}
                      onChange={(date) =>
                        setEvent((event) => {
                          return {
                            ...event,
                            endDatetime: date,
                          };
                        })
                      }
                    />
                  </FormGroup>
                  {event.actionType && event.target.value === 'employer' ? (
                    <FormGroup>
                      <Label>With Candidate</Label>
                      <Select
                        options={withCandidateValue}
                        value={event.withCandidate}
                        onChange={(v) =>
                          setEvent((event) => {
                            return {
                              ...event,
                              withCandidate: v,
                            };
                          })
                        }
                      />
                    </FormGroup>
                  ) : (
                    <></>
                  )}
                  <FormGroup>
                    <Label>Action Type</Label>
                    <Select
                      options={event.target.value === 'employer' ? actionValue : actionValueForCandidate}
                      value={event.actionType}
                      onChange={(v) =>
                        setEvent((event) => {
                          return {
                            ...event,
                            actionType: v,
                          };
                        })
                      }
                    />
                  </FormGroup>
                  {event.actionType && event.actionType.value === 'survey' ? (
                    <>
                      <FormGroup>
                        <Label>Survey Id</Label>
                        <Input
                          placeholder="Survey Id"
                          value={event?.survey?.surveyId}
                          onChange={(v) =>
                            setEvent((event) => {
                              return {
                                ...event,
                                survey: {
                                  ...event.survey,
                                  surveyId: v.target.value,
                                },
                              };
                            })
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Survey Link</Label>
                        <Input
                          placeholder="Survey Link"
                          value={event?.survey?.surveyLink}
                          onChange={(v) =>
                            setEvent((event) => {
                              return {
                                ...event,
                                survey: {
                                  ...event.survey,
                                  surveyLink: v.target.value,
                                },
                              };
                            })
                          }
                        />
                      </FormGroup>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </CardBody>
        {!_.isEmpty(event.cohort) && !_.isEmpty(event.target) ? (
          <CardFooter className="d-flex justify-content-between">
            <div>
              <Button color="primary" className="mr-2" outline onClick={_create}>
                {id ? 'Save' : 'Create'}
              </Button>
              {id && event.actionType && event.actionType.value === 'survey' ? (
                <Button color="primary" outline onClick={_sendNotification}>
                  Send Notification
                </Button>
              ) : (
                <></>
              )}
            </div>
            {id ? (
              <Button color="danger" outline onClick={_remove}>
                Remove
              </Button>
            ) : (
              <></>
            )}
          </CardFooter>
        ) : (
          <></>
        )}
      </Card>
    </div>
  );
}
