import React, { useState, useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import { fetchWithGet } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';
import CandidateInfo from './CandidateInfo';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import { Loader } from '../../components';
import { levelOptions, profileHeader } from '../../components/helpers/reportHelper';
import { CSVLink, CSVDownload } from 'react-csv';
import qs from 'qs';

export default function AllCandidate() {
  let { path, url } = useRouteMatch();

  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <AllInterview rootPath={url} />
        </Route>
        <Route path={`${path}/:id`}>
          <CandidateInfo rootPath={url} rootName={'All Candidate'} />
        </Route>
      </Switch>
    </Router>
  );
}

function AllInterview({ rootPath }) {
  const history = useHistory();
  const [candidates, setCandidates] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const allCandidateSetting = window.sessionStorage.getItem('allCandidateSetting');
  const query = qs.parse(allCandidateSetting, { ignoreQueryPrefix: true });

  const profileConvert = (v) => {
    return {
      id: v.userId,
      cohort: v.cohort && v.cohort.en,
      givenNameEn: v.givenName.en,
      surnameEn: v.surname.en,
      givenNameZh: v.givenName.zh || '',
      surnameZh: v.surname.zh || '',
      preferredName: v.preferredName,
      gender: v.gender,
      phoneNo: v.phoneNo,
      email: v.email,
      resume: v.resume,
      remark:
        v.remark
          ?.replace(',', '')
          ?.replace('"', '')

          ?.replace(/(?:\\[rn])+/g, '') || '',
      portfolio: v.portfolio,
      createdAt: moment(v.createdAt).format('YYYY-MM-DD'),
      reviewed: v?.reviewed,
      toCohort3: v?.toCohort3,
      cohort3Group: v?.cohort3Group,
      cohort3GroupFinal: v?.cohort3GroupFinal,
      withdrawn: v?.withdrawn,
      jobOffer1:
        v.offer &&
        v.offer[0] &&
        `${v.offer[0].finalInterview.position.role.parentId.en}/${v.offer[0].finalInterview.position.role.en}`,
      jobEmployer1: v.offer && v.offer[0] && `${v.offer[0].finalInterview.employer.givenName.en}`,
      jobStatus1: v.offer && v.offer[0] && `${_.startCase(v.offer[0].status)}ed`,
      jobOffer2:
        v.offer &&
        v.offer[1] &&
        `${v.offer[1].finalInterview.position.role.parentId.en}/${v.offer[1].finalInterview.position.role.en}`,
      jobEmployer2: v.offer && v.offer[1] && `${v.offer[1].finalInterview.employer.givenName.en}`,
      jobStatus2: v.offer && v.offer[1] && `${_.startCase(v.offer[1].status)}ed`,
      jobOffer3:
        v.offer &&
        v.offer[2] &&
        `${v.offer[2].finalInterview.position.role.parentId.en}/${v.offer[2].finalInterview.position.role.en}`,
      jobEmployer3: v.offer && v.offer[2] && `${v.offer[2].finalInterview.employer.givenName.en}`,
      jobStatus3: v.offer && v.offer[2] && `${_.startCase(v.offer[2].status)}ed`,
      jobOffer4:
        v.offer &&
        v.offer[3] &&
        `${v.offer[3].finalInterview.position.role.parentId.en}/${v.offer[3].finalInterview.position.role.en}`,
      jobEmployer4: v.offer && v.offer[3] && `${v.offer[3].finalInterview.employer.givenName.en}`,
      jobStatus4: v.offer && v.offer[3] && `${_.startCase(v.offer[3].status)}ed`,
      roleConvenor1: v.appliedRoles && v.appliedRoles[0] && v.appliedRoles[0].convenor.givenName.en,
      roleCohort1: v.appliedRoles && v.appliedRoles[0] && v.appliedRoles[0].cohort.toString(),
      role1: v.appliedRoles && v.appliedRoles[0] && `${v.appliedRoles[0].parentId.en} - ${v.appliedRoles[0].en}`,
      roleConvenor2: v.appliedRoles && v.appliedRoles[1] && v.appliedRoles[1].convenor.givenName.en,
      roleCohort2: v.appliedRoles && v.appliedRoles[1] && v.appliedRoles[1].cohort.toString(),
      role2: v.appliedRoles && v.appliedRoles[1] && `${v.appliedRoles[1].parentId.en} - ${v.appliedRoles[1].en}`,
      level: v.education
        ? v.education.level === 'Others'
          ? v.education.otherLevel || ''
          : levelOptions[v.education.level] || ''
        : '',
      location: v.education ? v.education.otherLocation || (v.education.location && v.education.location.en) : '',
      institution: v.education
        ? v.education.otherInstitution || (v.education.institution && v.education.institution.en)
        : '',
      programme: v.education && v.education.programmeName,
      areaOfStudy: v.education
        ? v.education.otherAreaOfStudy || (v.education.areaOfStudy && v.education.areaOfStudy.en)
        : '',
      studyDuration:
        v.education &&
        `${v.education.startYear}/${v.education.startMonth} - ${
          _.isInteger(v.education.gradYear)
            ? `${v.education.gradYear}/${v.education.gradMonth}`
            : `${v.education.gradYear}`
        }`,
      paidExpCount: v.vask && v.vask.paidExp && v.vask.paidExp.length,
      employmentCount:
        v.vask && v.vask.paidExp && v.vask.paidExp.filter((a) => a.type && a.type.en === 'Employment').length,
      entrepreneurCount:
        v.vask && v.vask.paidExp && v.vask.paidExp.filter((a) => a.type && a.type.en === 'Entrepreneur').length,
      otherCount:
        v.vask &&
        v.vask.paidExp &&
        v.vask.paidExp.filter((a) => a.type && a.type.en !== 'Employment' && a.type.en !== 'Entrepreneur').length,
      pe1Type: v.vask && v.vask.paidExp[0] && v.vask.paidExp[0].type && v.vask.paidExp[0].type.en,
      pe1Organization: v.vask && v.vask.paidExp[0] && v.vask.paidExp[0].organization,
      pe1Role: v.vask && v.vask.paidExp[0] && v.vask.paidExp[0].role,
      pe1FromTo:
        v.vask &&
        v.vask.paidExp[0] &&
        `${moment(v.vask.paidExp[0].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[0].to).format('YYYY-MM-DD')}`,
      pe2Type: v.vask && v.vask.paidExp[1] && v.vask.paidExp[1].type && v.vask.paidExp[1].type.en,
      pe2Organization: v.vask && v.vask.paidExp[1] && v.vask.paidExp[1].organization,
      pe2Role: v.vask && v.vask.paidExp[1] && v.vask.paidExp[1].role,
      pe2FromTo:
        v.vask &&
        v.vask.paidExp[1] &&
        `${moment(v.vask.paidExp[1].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[1].to).format('YYYY-MM-DD')}`,
      pe3Type: v.vask && v.vask.paidExp[2] && v.vask.paidExp[2].type && v.vask.paidExp[2].type.en,
      pe3Organization: v.vask && v.vask.paidExp[2] && v.vask.paidExp[2].organization,
      pe3Role: v.vask && v.vask.paidExp[2] && v.vask.paidExp[2].role,
      pe3FromTo:
        v.vask &&
        v.vask.paidExp[2] &&
        `${moment(v.vask.paidExp[2].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[2].to).format('YYYY-MM-DD')}`,
      pe4Type: v.vask && v.vask.paidExp[3] && v.vask.paidExp[3].type && v.vask.paidExp[3].type.en,
      pe4Organization: v.vask && v.vask.paidExp[3] && v.vask.paidExp[3].organization,
      pe4Role: v.vask && v.vask.paidExp[3] && v.vask.paidExp[3].role,
      pe4FromTo:
        v.vask &&
        v.vask.paidExp[3] &&
        `${moment(v.vask.paidExp[3].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[3].to).format('YYYY-MM-DD')}`,
      pe5Type: v.vask && v.vask.paidExp[4] && v.vask.paidExp[4].type && v.vask.paidExp[4].type.en,
      pe5Organization: v.vask && v.vask.paidExp[4] && v.vask.paidExp[4].organization,
      pe5Role: v.vask && v.vask.paidExp[4] && v.vask.paidExp[4].role,
      pe5FromTo:
        v.vask &&
        v.vask.paidExp[4] &&
        `${moment(v.vask.paidExp[4].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[4].to).format('YYYY-MM-DD')}`,
      nonPaidExpCount: v.vask && v.vask.unpaidExp && v.vask.unpaidExp.length,
      voluntaryCount:
        v.vask && v.vask.unpaidExp && v.vask.unpaidExp.filter((a) => a.type && a.type.en === 'Voluntary').length,
      vocationalCount:
        v.vask &&
        v.vask.unpaidExp &&
        v.vask.unpaidExp.filter((a) => a.type && a.type.en === 'Vocational and Educational Training / Work Exposure')
          .length,
      seriousCount:
        v.vask &&
        v.vask.unpaidExp &&
        v.vask.unpaidExp.filter((a) => a.type && a.type.en === 'Serious Leisure or Interest').length,
      domesticCount:
        v.vask &&
        v.vask.unpaidExp &&
        v.vask.unpaidExp.filter((a) => a.type && a.type.en === 'Domestic and/or Neighborhood Provisioning').length,
      npe1Type: v.vask && v.vask.unpaidExp[0] && v.vask.unpaidExp[0].type && v.vask.unpaidExp[0].type.en,
      npe1Organization: v.vask && v.vask.unpaidExp[0] && v.vask.unpaidExp[0].organization,
      npe1Role: v.vask && v.vask.unpaidExp[0] && v.vask.unpaidExp[0].role,
      npe1FromTo:
        v.vask &&
        v.vask.unpaidExp[0] &&
        `${moment(v.vask.unpaidExp[0].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[0].to).format(
          'YYYY-MM-DD'
        )}`,
      npe2Type: v.vask && v.vask.unpaidExp[1] && v.vask.unpaidExp[1].type && v.vask.unpaidExp[1].type.en,
      npe2Organization: v.vask && v.vask.unpaidExp[1] && v.vask.unpaidExp[1].organization,
      npe2Role: v.vask && v.vask.unpaidExp[1] && v.vask.unpaidExp[1].role,
      npe2FromTo:
        v.vask &&
        v.vask.unpaidExp[1] &&
        `${moment(v.vask.unpaidExp[1].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[1].to).format(
          'YYYY-MM-DD'
        )}`,
      npe3Type: v.vask && v.vask.unpaidExp[2] && v.vask.unpaidExp[2].type && v.vask.unpaidExp[2].type.en,
      npe3Organization: v.vask && v.vask.unpaidExp[2] && v.vask.unpaidExp[2].organization,
      npe3Role: v.vask && v.vask.unpaidExp[2] && v.vask.unpaidExp[2].role,
      npe3FromTo:
        v.vask &&
        v.vask.unpaidExp[2] &&
        `${moment(v.vask.unpaidExp[2].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[2].to).format(
          'YYYY-MM-DD'
        )}`,
      npe4Type: v.vask && v.vask.unpaidExp[3] && v.vask.unpaidExp[3].type && v.vask.unpaidExp[3].type.en,
      npe4Organization: v.vask && v.vask.unpaidExp[3] && v.vask.unpaidExp[3].organization,
      npe4Role: v.vask && v.vask.unpaidExp[3] && v.vask.unpaidExp[3].role,
      npe4FromTo:
        v.vask &&
        v.vask.unpaidExp[3] &&
        `${moment(v.vask.unpaidExp[3].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[3].to).format(
          'YYYY-MM-DD'
        )}`,
      npe5Type: v.vask && v.vask.unpaidExp[4] && v.vask.unpaidExp[4].type && v.vask.unpaidExp[4].type.en,
      npe5Organization: v.vask && v.vask.unpaidExp[4] && v.vask.unpaidExp[4].organization,
      npe5Role: v.vask && v.vask.unpaidExp[4] && v.vask.unpaidExp[4].role,
      npe5FromTo:
        v.vask &&
        v.vask.unpaidExp[4] &&
        `${moment(v.vask.unpaidExp[4].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[4].to).format(
          'YYYY-MM-DD'
        )}`,
      V1: v.vask && v.vask.values[0] && v.vask.values[0].en,
      V2: v.vask && v.vask.values[1] && v.vask.values[1].en,
      V3: v.vask && v.vask.values[2] && v.vask.values[2].en,
      A1: v.vask && v.vask.attitudes[0] && v.vask.attitudes[0].en,
      A2: v.vask && v.vask.attitudes[1] && v.vask.attitudes[1].en,
      A3: v.vask && v.vask.attitudes[2] && v.vask.attitudes[2].en,
      S1: v.vask && v.vask.skills[0] && v.vask.skills[0].en,
      S2: v.vask && v.vask.skills[1] && v.vask.skills[1].en,
      S3: v.vask && v.vask.skills[2] && v.vask.skills[2].en,
      K1: v.vask && v.vask.knowledge[0] && v.vask.knowledge[0].en,
      K2: v.vask && v.vask.knowledge[1] && v.vask.knowledge[1].en,
      K3: v.vask && v.vask.knowledge[2] && v.vask.knowledge[2].en,
    };
  };

  const columns = [
    '#',
    'Candidate Id',
    // 'Candidate',
    {
      name: 'Candidate',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
          if (candidates[rowIndex]) {
            return <a href={`${rootPath}/${candidates[rowIndex].id}`}>{value}</a>;
          }
          return '';
        },
        filter: false,
      },
    },
    'Email/Phone',
    'Education Level',
    {
      name: 'Submitted CV',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
          if (candidates[rowIndex] && value === 'Yes') {
            return (
              <a href={candidates[rowIndex].resume} color="link" target="_blank">
                {value}
              </a>
            );
          }
          return 'No';
        },
      },
    },
    'Reviewed by GenHK',
    'Shift to Cohort 3',
    'Cohort 3 Group',
    'Cohort 3 Group Final',
    'Channel',
    'Created at',
    'Last Updated Date Time',
    'Special Case',
    'Cohort 1',
    'Selected job role 1',
    'Name of convenor',
    'Selected job role 2',
    'Name of convenor',
    'Cohort 2',
    'Selected job role 1',
    'Name of convenor',
    'Selected job role 2',
    'Name of convenor',
    'Cohort 3',
    'Selected job role 1',
    'Name of convenor',
    'Selected job role 2',
    'Name of convenor',
    // 'Cohort 3 Grp B',
    // 'Selected job role 1',
    // 'Name of convenor',
    // 'Selected job role 2',
    // 'Name of convenor',
  ];
  const options = {
    selectableRows: 'none',
    // onRowClick: (rowData, rowMeta) => {
    //   history.push(`${rootPath}/${candidates[rowMeta.dataIndex].id}`);
    // },
    print: false,
    download: false,
    selectableRows: 'multiple',
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <div className="mr-2">
        <CSVLink
          filename={`Full-Profile-Export`}
          data={selectedRows.data
            .map((v) =>
              candidates[v.dataIndex].applications.length > 0
                ? candidates[v.dataIndex].applications.map((a) => {
                    const obj = { ...a, ...candidates[v.dataIndex] };
                    delete obj.applications;
                    return obj;
                  })
                : [candidates[v.dataIndex]]
            )
            .flat(1)
            .map(profileConvert)}
          headers={profileHeader}
        >
          Export Selected Candidate
        </CSVLink>
      </div>
    ),
    onChangePage: (number) => {
      window.sessionStorage.setItem('allCandidateSetting', qs.stringify({ ...query, page: number }));
    },
    onChangeRowsPerPage: (number) => {
      window.sessionStorage.setItem('allCandidateSetting', qs.stringify({ ...query, rowsPerPage: number }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      window.sessionStorage.setItem(
        'allCandidateSetting',
        qs.stringify({ ...query, sortOrder: { name: changedColumn, direction: direction } })
      );
    },
    page: query?.page,
    rowsPerPage: query?.rowsPerPage,
    sortOrder: query?.sortOrder,
  };
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/dashboard/getStageCandidate', { stage: 'all' });
    // const positions = await fetchWithGet('/content/position');
    const convenors = await fetchWithGet('/dashboard/getPositionConvenor');

    if (result) {
      setCandidates(result);

      setData(
        result.map((v, i) => {
          const keyBy = _.keyBy(v.applications, 'cohort.en');

          // ${
          //   (keyBy['Cohort 1'] &&
          //     keyBy['Cohort 1'].appliedRoles[0] &&
          //     `(${positions
          //       .find((c) => c.role.id === keyBy['Cohort 1'].appliedRoles[0].id)
          //       ?.cohort.map((v) => v.en)
          //       .join(',')})`) ||
          //   ''
          // }

          return [
            i + 1,
            v.userId,
            `${v.surname.en} ${v.givenName.en}\n${v.surname.zh || ''}${v.givenName.zh || ''}`,
            `${v.email}/${v.phoneNo}`,
            v.education
              ? v.education.level === 'Others'
                ? v.education.otherLevel || '-'
                : levelOptions[v.education.level] || '-'
              : '-',
            v.resume ? 'Yes' : 'No',
            v.reviewed || '-',
            v.toCohort3 || '-',
            v.cohort3Group || '-',
            v.cohort3GroupFinal || '-',
            v.channel || '-',
            moment(v.createdAt).format('YYYY-MM-DD HH:mm'),
            moment(v.updatedAt).format('YYYY-MM-DD HH:mm'),
            v.specialCase || '-',
            `${(keyBy['Cohort 1'] && keyBy['Cohort 1'].stage) || '-'}`,
            `${
              (keyBy['Cohort 1'] && keyBy['Cohort 1'].appliedRoles[0] && keyBy['Cohort 1'].appliedRoles[0].en) || '-'
            }`,
            `${
              (keyBy['Cohort 1'] &&
                keyBy['Cohort 1'].appliedRoles[0] &&
                convenors.find((c) => c.id === keyBy['Cohort 1'].appliedRoles[0].convenor)?.givenName.en) ||
              '-'
            }`,
            `${
              (keyBy['Cohort 1'] && keyBy['Cohort 1'].appliedRoles[1] && keyBy['Cohort 1'].appliedRoles[1].en) || '-'
            }`,
            `${
              (keyBy['Cohort 1'] &&
                keyBy['Cohort 1'].appliedRoles[1] &&
                convenors.find((c) => c.id === keyBy['Cohort 1'].appliedRoles[1].convenor)?.givenName.en) ||
              '-'
            }`,
            `${(keyBy['Cohort 2'] && keyBy['Cohort 2'].stage) || '-'}`,
            `${
              (keyBy['Cohort 2'] && keyBy['Cohort 2'].appliedRoles[0] && keyBy['Cohort 2'].appliedRoles[0].en) || '-'
            }`,
            `${
              (keyBy['Cohort 2'] &&
                keyBy['Cohort 2'].appliedRoles[0] &&
                convenors.find((c) => c.id === keyBy['Cohort 2'].appliedRoles[0].convenor)?.givenName.en) ||
              '-'
            }`,
            `${
              (keyBy['Cohort 2'] && keyBy['Cohort 2'].appliedRoles[1] && keyBy['Cohort 2'].appliedRoles[1].en) || '-'
            }`,
            `${
              (keyBy['Cohort 2'] &&
                keyBy['Cohort 2'].appliedRoles[1] &&
                convenors.find((c) => c.id === keyBy['Cohort 2'].appliedRoles[1].convenor)?.givenName.en) ||
              '-'
            }`,
            `${(keyBy['Cohort 3'] && keyBy['Cohort 3'].stage) || '-'}`,
            `${
              (keyBy['Cohort 3'] && keyBy['Cohort 3'].appliedRoles[0] && keyBy['Cohort 3'].appliedRoles[0].en) || '-'
            }`,
            `${
              (keyBy['Cohort 3'] &&
                keyBy['Cohort 3'].appliedRoles[0] &&
                convenors.find((c) => c.id === keyBy['Cohort 3'].appliedRoles[0].convenor)?.givenName.en) ||
              '-'
            }`,
            `${
              (keyBy['Cohort 3'] && keyBy['Cohort 3'].appliedRoles[1] && keyBy['Cohort 3'].appliedRoles[1].en) || '-'
            }`,
            `${
              (keyBy['Cohort 3'] &&
                keyBy['Cohort 3'].appliedRoles[1] &&
                convenors.find((c) => c.id === keyBy['Cohort 3'].appliedRoles[1].convenor)?.givenName.en) ||
              '-'
            }`,
            // `${(keyBy['Cohort 3 Group B'] && keyBy['Cohort 3 Group B'].stage) || '-'}`,
            // `${
            //   (keyBy['Cohort 3 Group B'] &&
            //     keyBy['Cohort 3 Group B'].appliedRoles[0] &&
            //     keyBy['Cohort 3 Group B'].appliedRoles[0].en) ||
            //   '-'
            // }`,
            // `${
            //   (keyBy['Cohort 3 Group B'] &&
            //     keyBy['Cohort 3 Group B'].appliedRoles[0] &&
            //     convenors.find((c) => c.id === keyBy['Cohort 3 Group B'].appliedRoles[0].convenor)?.givenName.en) ||
            //   '-'
            // }`,
            // `${
            //   (keyBy['Cohort 3 Group B'] &&
            //     keyBy['Cohort 3 Group B'].appliedRoles[1] &&
            //     keyBy['Cohort 3 Group B'].appliedRoles[1].en) ||
            //   '-'
            // }`,
            // `${
            //   (keyBy['Cohort 3 Group B'] &&
            //     keyBy['Cohort 3 Group B'].appliedRoles[1] &&
            //     convenors.find((c) => c.id === keyBy['Cohort 3 Group B'].appliedRoles[1].convenor)?.givenName.en) ||
            //   '-'
            // }`,
          ];
        })
      );
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>All Candidates</Link>
        </BreadcrumbItem>
      </Breadcrumb>

      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}
